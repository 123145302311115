.popup{
    > div {
        padding: 0 !important;
    }        
}

.fullscreen{
    padding:0 !important;
    margin:0 !important;
    width: 100vh;
    height: 100vh;
    min-width: 100vw;
    min-height: 100vh;
    max-width: 100vw !important;
    max-height: 100vh !important;
}