@import '../../../../assets/css/scss/variables';
@import '../../../../assets/css/scss/themes.scss';

.family-prof {
    object-fit: scale-down !important;
}

/* .family-prof.fam {
    cursor: pointer;
} */

.fam-group-tab-wrapper{
    display: flex;
    justify-content: center;
    flex: wrap;
    width: 100%;
}

.family-mem {
    max-height: 80px;
    max-width: 80px;
    display: flex;
    flex-direction: column;
}

.family-mem span {
    font-size: smaller;
    position: relative;
    top: -10px;
    line-height: 15px;
    cursor: default;
    margin-bottom: 5px;
}


.home-family-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;
}

img.family-prof {
    width: $profile-image-size;
    height: $profile-image-size;
    border-radius: $profile-image-border-radius;
    padding: $profile-image-padding;
    object-fit: cover;
    @media (max-width: 800px){
        width: 50px;
        height: 50px;
    }
}