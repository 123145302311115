.wallet-modal .modal-body {
    padding: 1rem 2rem;
}

.unclickable {
    max-width: 75%;
}

.token-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.token-qty {
    margin: 0 0 1.5rem 1rem;
    align-self: flex-end;
}

.userprofile h5 {
    margin-top: 0;
    font-size: 1.2rem;
}

.home .prof-card-title {
    margin-top: 1rem;
}