@import '../../../../assets/css/scss/variables';
@import '../../../../assets/css/scss/themes';

.category-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 0 0.3rem 0;
    gap: 0.4rem !important;

    :global(a.btn-sm) {
        padding: 0.7rem 1rem !important;
        font-size: 0.85rem;
        font-weight: 500;
        margin: 0 !important;
        // margin: 0 0.2rem 0.5rem 0 !important;
    }
}