.card {
    overflow: visible !important;
}

.pos-container .react-datepicker-wrapper {
    width: calc(100% - 39.15px) !important;
}

/* Located at POS>Event>Event and wraps POS>Event>Form>Children component */
.membership-wrap {
    overflow: visible !important;
    width: 100%;
}

.alert {
    margin-bottom: 0px !important;
    .close{
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        border: none;
    }
}

.form-select.participant {
    width: auto;
    min-width: 200px;
}

.events-container {
    width: 100%;
    height: 100%;
}

.event-custom{
    .form-label{
        width: auto;
        min-width: 200px;
        text-align: center;
    }
    .form-control{
        min-width: 200px;
    }
}