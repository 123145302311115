@import '../../../../../../../assets/css/scss/variables';
@import '../../../../../../../assets/css/scss/themes';

.wrapper{
    z-index: 1050;
    width: 100%;
    top:0;
    right:0;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    max-height: calc(100vh - 0px);

    &.preview{
        position: relative !important;
    }
    
    .container{
        font-family: $secondary-font-family;
        font-size: $primary-font-size;

        hr:last-child{
            display:none;
        }
    
        div{
            display:flex;
            justify-content: space-between;
            margin-bottom: 0.25rem;
    
            span:first-child{
                margin-right: 0.5rem;
            }
        }
        
        .subtotal{
            font-weight: $bold-font-weight;
        }
    
        .total{
            font-size: $big-font-size;
            font-weight: $bold-font-weight;
            text-transform: uppercase;
        }
    }
}

.ccform-wrapper{
    padding: $small-main-padding;
    padding-left:0;
    padding-right: 0;
}

@media screen and (max-width: 992px) {
    .wrapper{
        position: relative !important;
        width:100% !important;
        max-width: 100% !important;
        max-height: 100% !important;
    }
}