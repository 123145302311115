
.tab-group-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    
    .group-name{
        text-align: center;
    }
}
.group-tab-group-type-header{
    font-weight: 700;
    margin: 3px;
    padding: 0;
}