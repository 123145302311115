@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';
@import '../../../assets/css//scss/mixins';

.pos-container{
.user-container {
    padding: 0.75rem 0.5rem 0.75rem 0.5rem;

    .pos-waiver-home-card {
        border: 0;
    }

    .btn-category {
        padding: .7rem 1rem !important;
        margin-bottom:.45rem;
        font-size: .85rem;
        font-weight: 500;
        align-items: center;
        justify-content: center;
    }

    .collapse-btn{
        @include collapse-btn;
        z-index: 4;
        top: 65px;
        transition: all 500ms;
        &.open{
            left: 230px;
        }
        &.closed{
            left: 5px;
        }
        // @media screen and (max-width: 1469px){
        //     top: 60px;
        //     &.open{
        //         left: 210px;
        //     }
        // }
    }

    overflow: hidden;
    &.closed{
        max-width: 0 !important;
        transition: all 500ms;
    }
    transition: all 500ms;
}

.user-info{
    $width:300px;
    padding:0;
    width: $width;

    .user-pic {
        width: $width;
        height: $width;
        background-size:cover;
        background-position: center;
        margin:0 auto;
    }    

    .btn-light{
        background:transparent;
        border-color:transparent;
        font-weight: 400;
        font-size:.8rem;
        margin:0;
    }
    
    .user-buttons-wrapper{
        display: flex;
        flex-direction: column;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.25rem;
        padding: 0.25rem;

        .btn{
            margin:0;
            width:100%;
        }
    }
    
    > .card {
        border: 0;
        box-shadow: none;
    }
    
    .detail-card {
        border: $divider-border !important;
        box-shadow: $shadow-elevation-0 !important;
    }
    
}


.current-user{
    padding: 0 0.5rem;
    margin-bottom: 0;
    margin-top: $main-padding;
    font-family: $secondary-font-family;
    color: $primary-font-color;
}

.user-container h5 {
    font-family: $secondary-font-family;
    font-weight: $bold-font-weight;
    font-size: $card-font-size;
}
.center-btn{
    display: block;
    text-align: center;
}

#pos-event-sche-btn{
    display: block;
    margin: .25rem auto .5rem auto
}
#modal-pos{
    display: block;
    margin: 5rem 50% 0px auto;
}

.pos-user-modal.modal-dialog{
    max-width: 1200px;
}
@media only screen and (min-width: 800px) and (max-width: 1200px){
    .pos-user-modal.modal-dialog{
        max-width: 1000px;
    }
}
@media only screen and (min-width: 621px) and (max-width: 799px){
    .pos-user-modal.modal-dialog, 
    .pos-user-modal.modal-content{
        max-width: 600px;
    }

    .pos-user-modal .modal-body{
        max-width: 550px
    }
}
@media only screen and (max-width: 620px){
    .pos-user-modal .modal-body{
        width: 90%;
    }
    .pos-user-modal.modal-content{
        overflow-y: scroll;
        overflow-x: scroll;
    }
}
}