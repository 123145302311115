@use '../../../assets/css/scss/pages';
@import '../../../assets/css/scss/mixins';
@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';

.preview-container {
    padding: 0;
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: $card-font-size;

    .receipt-container {
        height: 100%;
        max-height: 100%;
    }

    .preview-qty{
        border:0;
        background:transparent;
        padding:.1rem .5rem;
        margin-right:.2rem;
        text-align:right;

    }

    .order-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.25rem 0.25rem 0 0.2rem;
        width: 100%;

        h4 {
            margin-bottom: 0;
        }

        button.btn{
            margin: 0 !important;
        }
    }

    .order-title h5 {
        margin-bottom: 0;
        line-height: 1.6;
        margin-right: 0.8rem;
    }

    .order-status {
        text-transform: uppercase;
        text-align: center;
    }

    .no-items {
        text-align: center;
        margin-top: 1rem;
    }
    
    .customer-wrapper b{
        font-weight: 500;
    }
    
    .customer-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.6rem .25rem 0.6rem .2rem;
        margin-bottom:0;
        width:100%;

        >span{
            font-weight: $bold-font-weight;
        }

        button.btn{
            margin: 0 !important;
        }
    }

    .discount-wrapper {
        flex-wrap: wrap;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }

    .no-discounts-found {
        margin: 4px 0;
    }
    
    .current-customer span{
        color: $primary-color;
    }
    
    .clear-customer {
        padding: .2rem .5rem;
    }

    .row-wrapper,
    .group-wrapper {
        display: contents;
    }

    div.table-header,
    table thead {
        font-size: $pos-table-header-font-size !important;
        font-weight: $pos-table-header-font-weight !important;
        color: $pos-table-header-color;
        border: $pos-table-header-border !important;
        background-color: $pos-table-header-background-color !important;
    }

    .preview-items-grid-wrapper {
        flex: 1;
        overflow-y: scroll;
        padding-left: 10px;
        padding-right: 10px;
    }

    .preview-headers,
    .footer-grid {
        // flex: none;
        padding-left: 10px;
        padding-right: 10px;
    }

    .preview-headers {
        padding-bottom: 10px;
    }

    .preview-items-grid,
    .preview-addons-grid {
        width: 100%;
        margin-bottom:0;
        display: grid;
        grid-template-columns: auto 80px 80px;
        border-bottom: 1px solid rgba(0,0,0, 0.1);
        
        .grid-header {
            @extend .table-header;
            position: sticky;
            top:0%;
            z-index: 20;
        }

        .row-wrapper > * {  // every "cell" in the grid
            padding: 6px 10px;
            border-top: 1px solid rgba(0,0,0, 0.1);
            display: flex;
        }

        .row-wrapper:first-of-type > * {  // padding at the top of the group
            padding-top: 6px;
        }

        .row-wrapper:last-of-type > * {  // padding at the bottom of a group
            padding-bottom: 6px;
        }

        .row-wrapper.no-top-border > * {
            border-top: none;
        }

        .item-name {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .item-price {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .group-wrapper:hover > * > * {
            background-color: rgba(0,0,0,.075);
        }

        .addon-dot {
            margin: 0 0.5rem 0 0.5rem;

            i {
                color: $grey-5;
                transform: scale(0.5);
            }
        }
       
        .item-price {
            text-align: right;
        }
        
        .product-buttons {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 3px 0px;
            
            .btn {
                margin: 0 5px 0 0;
                padding: 5px 0 !important;
                width: 30px;
                height: 30px;
                align-items: center;
                justify-content: center;
                border-radius: $button-border-radius;
                color: $primary-font-color;

                i {
                    margin-right: 0;
                }
            }

            .btn-light:hover {
                background-color: $primary-color;
                color : $button-hover-color;

                i{
                    color: $button-hover-color;
                }
            }

            & > * {
                width: 50px;
            }
        }
    }

    .footer-grid {
        position: relative;
        width: 100%;
        display: grid;
        grid-template-columns: auto auto;
        padding-top: 0.3rem;
        border-top: 1px solid $divider-color;

        .row-wrapper > * {
            // font-weight: bold;
            padding: 3px 10px;
        }

        .row-wrapper > *:not(:first-of-type) {
            text-align: right;
            padding-right: 1.3rem;
        }

        .row-wrapper.totals > * {
            font-size: $big-font-size;
            font-weight: $bold-font-weight;
        }
    }

    .input-qty{
        display:inline-block;
        width:4rem;
        padding:0;
        padding-left:.4rem;
        height:1.55rem;
        text-align:right;
        border:0;
        border-bottom:1px solid #fff;
        border-radius:0;
    }
    .input-qty:focus{
        border-bottom:1px solid #ced4da;
    }

    .receipt-text{
        border: 0;
    }

    .multiple-lines {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
    }

    .multiple-lines div {
        width: 100%;
    }

    .original-price {
        color: $error-color;
        text-decoration: line-through;
    }

    .total-discount {
        cursor: pointer;
    }

    .total-discount .highlight {
        color: $error-color;
        font-weight: 500;
    }

    .total-discount .underline {
        text-decoration: underline;
    }

    .coupon-list {
        padding-top: 0 !important;
    }

    .patron-cart-preview .table-header {
        border-bottom: 1px solid #999999;
    }

    .collapse-btn{
        @include collapse-btn;
        z-index: 4;
        top: 150px;
        transition: all 500ms;
        &.open{
            right: 450px;
        }
        &.closed{
            right: 5px;
        }
    }
    &.collapse-closed{
        transition: all 500ms;
        max-width: 0 !important;
    }
    transition: all 500ms;
    
}

// Print

.receipt-container-print * {
    font-size:12px;
    font-family: Calibri, Arial;
}

.receipt-container-print table tr th:last-child,
.receipt-container-print table tr td:last-child
{
    display:none;
}

.kitchen-receipt-button{
    color: #ffffff;
    background-color: #F67730;
    border: 1px solid #F67730;
    width: 120px;
    padding: .2rem .5rem;
    border-radius: 5px;
    text-align: center;
    margin: 0 auto 5px auto;
    cursor: pointer;
}


div.fade.modal.show {
    z-index: 8060;
}