@import '../../../assets/css/scss/variables.scss';
@import '../../../assets/css/scss/themes.scss';

.modal-dialog.waiver-modal{
    max-width: 1200px;
}

/* .body.waiver-modal{
    height: 600px;
} */

.waiver-home-card.card{
    position:sticky;
    transform: translateX(calc($main-padding * -1));
    top:$header-height;
    width:calc(100% + $main-padding) !important;
    z-index:999;
    background-color: $alert-background-color;
    color: $alert-font-color;
    border-radius: $card-border-radius;
    border: $card-border;
    border-left: 2rem solid $tertiary-color;
    border-right: 2rem solid transparent;
    padding: $card-padding;
    box-shadow: $shadow-elevation-0;
    margin-bottom: $main-padding;
    margin-top:0;
}

.waiver-home-card {
    align-items: center;
    text-transform: uppercase;
}

.waiver-home-card p {
    font-family: $card-title-font-family;
    font-weight: $bold-font-weight;
    padding: $main-padding;
    @media (max-width: 500px){
        padding: 1px;   
    }
} 

.pos-waiver-home-card{
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
    background: yellow;
}

.pos-waiver-home-card .btn, .pos-waiver-home-card p, .user-container .pos-waiver-home-card.card {
    margin: 0;
}

.pos-waiver-home-card.card{
    box-shadow: none;
}

.btn.btn-primary.sign-waiver-btn{
    background-color: $tertiary-color;
    font-weight: $bold-font-weight;

    &:hover{
        background-color: $tertiary-hover-color;
    }
}

.waiver-iframe{
    border:0;
    height: 800px;
    width: 100%;
}

.wav-card{
    max-width: 50rem;
    display: flex;
}
.wav-card span{
    width: 10rem;
}
.wav-card 
.btn.wav-dl-btn{
    width: 10rem;
}
.wav-a-tag, 
.wav-a-tag:active,
.wav-a-tag:focus,
.wav-a-tag:hover{
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    background: none;
    border: none;
}

@media only screen and (min-width: 901px) and (max-width: 1300px){
    .modal-dialog.waiver-modal{
        max-width: 900px;
    }
    .waiver-iframe{
        height: 900px;
    }
}

@media only screen and (min-width: 701px) and (max-width: 900px){
    .modal-dialog.waiver-modal{
        max-width: 600px;
    }
    .waiver-iframe{
        height: 700px;
    }
}

@media only screen and (min-width: 651px) and (max-width: 700px){
    .modal-dialog.waiver-modal{
        max-width: 400px;
    } 
    .waiver-iframe{
        height: 600px;
    }
}
@media only screen and (max-width: 650px){
    .modal-dialog.waiver-modal{
        max-width: 800px;
    } 
    .waiver-iframe{
        height: 500px;
    }
}