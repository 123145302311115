@import "../../../../assets/css/scss/variables";
@import "../../../../assets/css/scss/themes";

.register-cards {

    .register-cards-row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .event-list-card{
        height: 100%;
        &.card{
            padding: 16px;
        }
        div.card-body{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .container-fluid#reg-event-cont {
        margin: 0;
        padding: 0;
        max-width: 95%;
        position: absolute;
        top: 25px;
        left: 25px;
    }

    .card.reg-ev-card {
        height: 20rem;
        border-radius: 8px;
        box-shadow: 2px 2px 4px $grey-4;
        padding: 0px;
        border: 1px solid $grey-7;
    }

    .card.reg-ev-card:before {
        content: "";
        position: absolute;
        background-image:var(--logoNoText-url);
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.05;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .card.reg-ev-main-txt {
        height: 15rem;
    }

    .reg-ev-name-txt {
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .reg-ev-main-txt {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .card-footer.reg-ev-card-footer {
        color: white;
        border-top: solid 1px black;
        background-color: #f67730;
        border-radius: 3px;
        padding: 0.75rem 0.25rem;
        z-index: 2;
        text-align: center;
        font-size: 1rem;
        cursor: pointer;
    }

    .reg-ev-card-footer a,
    .reg-ev-card-footer a:hover,
    .reg-ev-card-footer a:active,
    .reg-ev-card-footer a:focus {
        color: white;
        text-decoration: none;
    }

    .reg-ev-sub-txt {
        margin-left: 12px;
        margin-right: 12px;
    }

    .reg-ev-card-date {
        font-size: .95rem;
    }

    .reg-ev-card-date i {
        margin-right: 4px;
    }

    @media only screen and (max-width: 290px) {
        .container-fluid#reg-event-cont {
            margin-top: 2rem;
            max-width: 80%;
        }
    }

    @media only screen and (min-width: 291px) and (max-width: 575px) {
        .container-fluid#reg-event-cont {
            max-width: 90%;
        }
    }

    @media only screen and (min-width: 1000px) and (max-width: 1500px) {

        .card#reg-ev-first-card,
        .card.reg-ev-card {
            height: 15rem;
        }

        .reg-ev-sub-txt span {
            font-size: .8rem;
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1250px) {
        .reg-ev-sub-txt span {
            font-size: .7rem;
        }
    }

    @media only screen and (min-width: 1000px) and (max-width: 1450px) {
        .reg-ev-main-txt {
            -webkit-line-clamp: 2;
        }
    }

    @media only screen and (min-width: 1451px) and (max-width: 1499px) {
        .reg-ev-main-txt {
            -webkit-line-clamp: 3;
        }
    }

}