@import "../../../assets/css/scss/variables";
@import "../../../assets/css/scss/themes";

footer {
    display: none;
}
//on the login screen, remove the border from the background caused by padding and margin on the left and right
.main-content:has(.login-register){
    padding: 0;
    margin: 0;
}

.login-register {

    // transform: translateX(calc($main-padding * -1));
    // width: calc(100% + $main-padding * 2);
    max-width: 100vw;

    .container{
        z-index: 99;
    }

    #bgVideo{
        display:block;
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100vw;
        // width: 100vw;
        min-height: 100vh;
        // height: auto;
        filter: $background-image-filter;

        /*
        @if str-slice($background-image, -3)== "mp4" {
            display:block;
        }
        */
    }

    a,
    a:visited,
    a:active{
        color: $link-color;
        font-size: $link-font-size;
        font-weight: $link-font-weight;
        text-decoration: $link-text-decoration;
        
        &:hover{
            color: $link-hover-color;
            text-decoration: $link-hover-text-decoration;
        }
    }

    .login-form h1 {
        font-weight: 500;
        margin-top: 0;
        font-size: 1.5rem;
        padding: 1rem 0;
    }

    .forget-text-center {
        text-align: center;
        padding-top: 15px;
    }

    .login-form>button {
        padding: .8rem 2.5rem;
    }

    .login-container-fluid {
        height: 100vh;
    }

    .login-container {
        padding: 10rem !important;
        padding-top: 4rem !important;
    }

    .login-form {
        padding: 1rem 0;
    }

    .text-align-sign {
        text-align: center;
        padding: $main-padding;
    }

    .text-cent-forget {
        text-align: center;
        width: 100%;
        padding-top: 30px;
    }

    .banner-text h5 {
        font-size: 18px;
        color: #8AB7E9;
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 1));
        font-family: Roboto-Bold;
        letter-spacing: 6px;
        text-transform: uppercase;
        position: relative;
        flex-grow: 1;
    }

    .portal-he {
        color: #fff;
    }

    .register-title {
        margin-top: 1rem;
    }

    h4.dash-title-login {
        font-size: 18px;
        color: $primary-light-color;
        letter-spacing: 6px;
        text-transform: uppercase;
        position: relative;
        display: flex;
        /* margin: 0px; */
        align-items: center;
    }

    h4.dash-title-login::before {
        content: "";
        background: $primary-light-color;
        width: 72px;
        height: 2px;
        display: block;
        margin-right: 35px;
    }

    .logo {
        background-image: $logo-url;
        background-position: $logo-background-position;
        background-repeat: $logo-background-repeat;
        background-size:$logo-background-size;
        height: 110px;
        width: 200px;
        margin: 0.3rem auto 1rem 0;
        background-position: center;
        margin-left: auto;
    }

    .login-banner {
        background-size: cover;
        padding: 55px 0px 0px;
        box-sizing: border-box;
        margin-bottom: -4rem !important;
    }

    .banner-wrapp {
        background-color:$primary-color;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 100vh;
        top: 0;
        right: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /*
        @if str-slice($background-image, -3) != "mp4"{
            background-image: url($background-image)
        }
        */
    }

    .auth-form-wrap {
        width: 100%;
        background: $card-background-color;
        box-shadow: $shadow-elevation-3;
        padding: 40px 50px;
        border-radius: $card-border-radius;
        margin: 40px 0px;
    }

    .ani-input {
        width: 100%;
        overflow: hidden;
    }

    .ani-input .form-control {
        height: 33px !important;
        border: 0px;
        border-radius: 0px;
        border-bottom: $form-control-border;
        font-size: $form-control-font-size;
        color: $form-control-color;
    }

    .ani-input label {
        font-size: $small-font-size;
        color: $primary-font-color;
        font-family: $primary-font-family;
        margin: 0px;
        white-space: nowrap;
    }

    .input-form input::placeholder {
        color: $form-control-placeholder-color !important;
        font-weight: $form-control-font-weight;
        font-size: $form-control-font-size;
    }

    .m-t-15 {
        margin-top: 25px;
    }

    @media (max-width: 991px) {
        #bgVideo{
            display:none;
        }
        
        .login-container {
            padding: 1.5rem !important;
            padding-top: 3rem !important;
        }

        .text-align-sign {
            text-align: center;
        }

        .hid-mob {
            display: none;
        }

        .mob-log-login {
            background-repeat: no-repeat;
            background-size: contain;
            height: 82px;
            width: 200px;
            margin: 0.3rem auto 1rem 0;
            background-position: center;
            margin-left: auto;
        }

        h4.dash-title-login {
            font-size: 15px;
            letter-spacing: 1px;
        }

        h4.dash-title-login::before {
            width: 50px;
        }

        .auth-form-wrap {
            margin: 30px 0px;
        }
    }

}