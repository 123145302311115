@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';
.totals{
    padding: $main-padding calc($main-padding / 2) !important;
    background-color: $background-color;
}

.options{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: $main-padding !important;

    button:global(.btn){
        padding: 1rem 2rem !important;
        margin: 10px;
    }
}