@import '../../assets/css/scss/variables';
@import '../../assets/css/scss/themes';

.tutorial-wrapper{
    text-align: justify;
    table{
        width: 100%;
        
    }
    ol{
        margin-left: .25rem;
    }
    ul{
        margin-left: .5rem;
        li{
            margin-bottom: .5rem;
            &::before{
                content: "- ";
            }
        }
    }
    div.card-header{
        margin-bottom: .5rem;
    }
    .card{
        padding: 10px 0 0 0;
        p{
            padding: 5px 15px 5px 15px;
        }
    }
}