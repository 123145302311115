@import '../../../../../assets/css/scss/variables';
@import '../../../../../assets/css/scss/themes';

.slider{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position:relative;
    z-index: 4060;

    .arrow-left,
    .arrow-right {
        mask: url(../../../../../assets/images/chevron.svg) no-repeat center center;
        background-color: $primary-inverse-color;
        width: 1.5rem;
        height: 1.5rem;
        position:absolute;
        top:50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 0.25s ease-in-out;
        z-index: 4061; 
        left: 0;
    }

    .arrow-right {
        transform: translateY(-50%) rotate(180deg);
        right: 0;
        left: auto;
    }
}

.slider-wrapper{
    height:100%;
    overflow:hidden;
    display:flex;
    transition: all 1s ease-in-out;

    &.slide{
        flex-direction: row;    
    }

    /*
    div:not(.active){
        filter: grayscale(1);
        background-blend-mode: saturation;
    }

    div.active {
        opacity:1;
        filter: grayscale(0);
        background-blend-mode: normal;
    }
    */

    &.fade{
        flex-direction: column;
        position: relative;

        >div {
            z-index: -1;
            opacity:0;
    
            &:not(.active){
                animation-name: fade-out;
                animation-fill-mode: forwards;
                animation-duration: 1s;
                animation-timing-function: ease-in-out;
            }
            
            &.active {
                opacity:1;
                z-index: 4060;
            }
        }    
    }
}

.animate {
    transition: all 1s ease-in-out;

    &.fade {
        > div.active {
            animation-name: fade-in;
            animation-fill-mode: forwards;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
        }
    }
}

.pagination-wrapper{
    position:absolute;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem;
    width:100%;
    transition: all 0.25s ease-in-out;
    left: 0;
    z-index: 4061;

    &.top{
        top:0.5rem;
    }
    &.bottom{
        bottom:0.5rem;
    }

    &.center{
        justify-content: center;
    }
    &.end{
        justify-content: flex-end;
    }

    .numbers {
        font-size: 0.65rem;
        font-weight: 500;
        padding: 0.125rem 0.35rem;
        margin: 0.125rem;
        border: 1px solid $divider-color;
        color: $primary-font-color;
        background-color: $primary-inverse-color;
        text-decoration: none;
        
        &.active,
        &:hover{
            color: $primary-color;
            border-color: $primary-color;
            text-decoration: none;
        }
    }

    .bullets {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-clip: content-box;
        background-color: $primary-color;
        border: 2px solid $primary-inverse-color;
        padding: 0.125rem;
        margin: 0.25rem;

        &.active, 
        &:hover{
            background: $primary-light-color;
        }
    }
}

@keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

@keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
}