@import '../../../assets/css/scss/mixins';
@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes.scss';

.notification-preview-wrapper{
    .active-icon{
        cursor:pointer;
        border-radius: 50%;

        -webkit-animation: glow 1s ease-out infinite alternate;
        -moz-animation: glow 1s ease-out infinite alternate;
        -o-animation: glow 1s ease-out infinite alternate;
        -ms-animation: glow 1s ease-out infinite alternate;
        animation: glow 1s ease-out infinite alternate;

        @keyframes glow{
            0%{
                border-color: $primary-color;
                -webkit-box-shadow:0px 0px 5px 3px $primary-color;
                -moz-box-shadow: 0px 0px 5px 3px $primary-color;
                box-shadow: 0px 0px 5px 3px $primary-color;
                background-color: $primary-color;
            }

            50%{
                border-color: $secondary-color;
                -webkit-box-shadow:0px 0px 5px 10px $secondary-color;
                -moz-box-shadow: 0px 0px 5px 10px $secondary-color;
                box-shadow: 0px 0px 5px 10px $secondary-color;
                background-color: $secondary-color;
            }

            100%{
                border-color: $primary-color;
                -webkit-box-shadow:0px 0px 5px 3px $primary-color;
                -moz-box-shadow: 0px 0px 5px 3px $primary-color;
                box-shadow: 0px 0px 5px 3px $primary-color;
                background-color: $primary-color;
            }
        }
    }

}
.unread-dot{
    height: 8px;
    width: 8px;
    background-color: $error-color;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: -8px;
    left: -5px;
}

.orders-wrapper{
    max-width: 450px;
    .notification-subject{
        font-weight: 800;
        text-decoration: underline;
    }
    .notification-message{
    }
    .each-notification{
        border: solid $primary-color 1px;
        border-radius: 5px;
        padding: 3px;
        margin: 5px;
    }
}