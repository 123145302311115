@import '../../../../../../assets/css/scss/variables';
@import '../../../../../../assets/css/scss/themes';

.container{
    display: flex;
    position: relative;
    width: fit-content;
    height: fit-content;
    width:2rem;
    height:2rem;
    font-size: 1rem;
    margin: 0;

    .icon{
        display:flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 1rem;
        font-size: inherit;
        color: inherit;

        >i{
            font-size: inherit;
            color: inherit;
        }
    }
    .cart-num-items {
        position: absolute;
        right: 0;
        top: 0;
        font-family: $badge-font-family;
        font-size: $badge-font-size;
        font-weight: $badge-font-weight;
        width: $badge-size;
        height: $badge-size;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $badge-border-radius;
        border: $badge-border;
        background-color: $badge-background-color;
        color: $badge-color;
    }

    &:hover{
        .icon{
            background-color: $button-hover-background-color;
            color: $button-hover-color;
            text-decoration: none;
        }
        .cart-num-items{
            background-color: $badge-hover-background-color;
            color: $badge-hover-color;
        }
    }
}
