@import '../../../../assets/css/scss/mixins';
@import '../../../../assets/css/scss/variables';
@import '../../../../assets/css/scss/themes';

.upcoming-event-detail-wrapper{
    .top-row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include subtle-break-divider;
        padding: 10px;
    }
    .event-description{
        min-width: 300px;
        max-width: 700px;
        margin-right: 2.5rem;
        color:red;
    }
}