@import './variables';
@import './themes.scss';


@mixin basic-input-select{
    // padding: 5px;
    //they're split up so they can be overridden individually when needed
    margin-top: 0;
    margin-right: 2rem;
    margin-bottom: 8px;
    margin-left: 8px;
    padding: $form-control-padding;
    font-family: $form-control-font-family;
    font-size: $form-control-font-size;
    font-weight: $form-control-font-weight;
    line-height: $form-control-line-height;
    color: $form-control-color;
    background-color: $form-control-background-color;
    border: $form-control-border;
    border-radius: $form-control-border-radius;
    &::placeholder{
        color: $form-control-placeholder-color;
        font-weight: $form-control-placeholder-font-weight;
        font-size: $form-control-placeholder-font-size;
        line-height: $form-control-placeholder-line-height;
    }
    &:disabled{
        background-color: $disabled-color;
    }
    &:focus-visible{
        outline: none;
    }
    input[type='checkbox']{
        @include checkbox;
    }
}
@mixin basic-flex-column{
    display: flex;
    flex-direction: column;
}
@mixin basic-flex-row{
    display: flex;
    flex-direction: row;
}
@mixin flex-row-center{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
@mixin flex-column-center{
    display: flex;
    flex-direction: column;
    align-items: center;
}
@mixin flex-row-space-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
@mixin flex-row-space-around{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
@mixin product-flex-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
@mixin product-flex-col{
    display: flex;
    flex-direction: column;
}
@mixin basic-label{
    font-family: $form-control-label-font-family;
    color: $form-control-label-color;
    font-size: $form-control-label-font-size;
    font-weight: $form-control-label-font-weight;
}
@mixin focus-glow-mix{
    border-color: $primary-color;
    outline: 0;
    box-shadow: 0 0 4px 4px $primary-color;
}
@mixin checkbox{
    &:checked{
        background-color: $primary-color;
        border-color: $primary-color;
    }
    &:focus{
        border-color: $primary-color;
        box-shadow: 0 0 0 0.2rem rgba($primary-color, 0.25)
    }
}
@mixin basic-button{
    font-family: $button-font-family;
    font-size: $button-font-size;
    line-height: $button-line-height;
    font-weight: $button-font-weight;
    border: $button-border;
    border-width: $button-border-width;
    border-radius: $button-border-radius;
    background-color: $button-background-color;
    color: $button-color;
    box-shadow: $button-shadow;
    padding: $button-padding;
    margin: $button-margin;
    &:hover{
        background-color: $button-hover-background-color;
        color: $button-hover-color;
    }
    &:active{
        background-color: $button-active-background-color;
        color: $button-active-color;
    }
    &:disabled{
        background-color: $button-disabled-background-color;
        color: $button-disabled-color;
    }
}

@mixin outline-button{
    background-color: transparent !important;
    border: 1px solid $primary-color !important;
    color: $primary-color !important;
    &:hover{
        background-color: $primary-color !important;
        color: $button-hover-color !important;
    }
    &:active{
        background-color: $button-active-background-color;
        color: $button-active-color;
    }
    &:disabled{
        background-color: $button-disabled-background-color;
        color: $button-disabled-color;
    }
}

@mixin pos-spaced-button{
    margin: 10px !important;
    padding: 1rem 2rem !important;
}

@mixin secondary-modal-shadow{
    -webkit-box-shadow: 10px 10px 108px 13px $shadow-color;
    -moz-box-shadow: 10px 10px 108px 13px $shadow-color;
    box-shadow: 10px 10px 108px 13px $shadow-color;
}

@mixin icon-only-title-replacement{
    font-size: 1.15rem;
}

@mixin subtle-break-divider{
    border: 1px solid $divider-color;
    border-radius: 5px;
}

//have to include the z-index, top, and left (or right) positions 
@mixin collapse-btn{
    border-radius: 50%;
    padding: 3px;
    margin: 0;
    position: fixed;
    width: 18px;
    height: 18px;
    display: inline-block;
    background-color: $button-background-color;
    line-height: $primary-line-height;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @media screen and (max-width: 1469px){
        font-size: 22px;
        width: 30px;
        height: 30px;
    }
}