@import '../../../../assets/css/scss/variables';
@import '../../../../assets/css/scss/themes';
@import '../../../../assets/css/scss/mixins';

//#region

.i-frame-height{
    iframe{
        min-height: 40px; 
        //without this, the iframe can and will disappear,
        //leading to the bug where the fields were "missing".  Whether it was taking away flex or adding bootstrap elements instead, 
        //the height of the iframe of collect.js is based on it's parent before loading.  Sometimes the parent height is 0 when it loads, hence iframe height 0.  
        //All this to say, NO TOUCHIE.  - set to 40 because the fields themselves come in at 36px but setting it to match cuts off the bottom. 
    }
}

.no-events .events-container {
    display: none;
}

.pos-patron-container {

    .preview-container .preview-items-grid-wrapper {
        overflow-y: auto;
    }

    .item-container {
        padding-top: 0;
    }
}

.patron-cart-main-wrapper {
    display: flex;
    flex-direction: row;
    
    .patron-cart-left {
        > div{
            padding-right: $main-padding;
        }
    }

    .patron-cart-right {
        border-left: $divider-border;
        > div{
            padding-left: $main-padding;
        }
    }

    .mobile-header {
        display: none;
    }

    .patron-cart-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-top: 1rem;
    }

    .patron-cart-buttons.default-right {
        justify-content: flex-end;
    }

    .patron-cart-buttons.default-left {
        justify-content: flex-start;
    }

    .display-small-screens {
        display: none;
    }

    p.error-text {
        margin-top: 1rem;
    }

}

@media screen and (max-width: 1400px) {
    .patron-cart-main-wrapper {
        .patron-cart-buttons {
            justify-content: space-between !important;
        }

        .display-small-screens {
            display: block;
        }

        .patron-cart-left,
        .patron-cart-right {
            display: none;
            border-left: none;
            > div{
                padding-right: 0;
                padding-left: 0;
            }
        }

        .patron-cart-left.current-step,
        .patron-cart-right.current-step {
            display: block;
            width:100%;
        }
    }
}

@media screen and (min-width: 800px) and (max-width:1400px) {

    .patron-cart-main-wrapper {
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
    }
}

.payment-fields {

    input {
        border: none;
        padding: 5px;
        border-bottom: 1px solid $company-neutral-dark;
        margin: 10px 0 10px 0;

        &:focus-visible {
            outline: 1px auto $company-primary;
        }
    }

    .cc {
        display: flex;
        width: 100%;
    }

    .cc-dets {
        display: flex;

    }

    span {
        color: $company-bright-error
    }

    .collapse-arrow-button.btn:focus {
        box-shadow: none !important;
    }
}

@media screen and (max-width: 600px) {
    .payment-fields {
        .cc {
            #ccnumber {
                width: 90%;
            }
        }
    }
}


.popover-body {

    .empty-cart {
        margin-top: 6px !important;
    }

    .empty-cart-icon {
        display: none;
    }

}

.popover-body {

    .empty-cart {
        margin-top: 6px !important;
    }

    .empty-cart-icon {
        display: none;
    }

}

//#endregion

//#region PatronCart CC Entry


//#endregion

//#region PatronCartAddress

.pc-cc-wrapper {

    .payment-fields {
        background-color: $card-background-color;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        input,
        select {
            @include basic-input-select;
            border: none;
            padding: 5px;
            margin: 10px 0 10px 0;
            border-bottom: 1px solid $company-neutral-dark;
            &:focus-visible {
                outline: 1px auto $company-primary;
            }
        }

        // the * for required fields
        span {
            color: $company-bright-error;
            font-size: 1rem;
            vertical-align: top;
            width: 25px;
            padding-left: 3px;
        }

        & > * {     // every row in the payment fields form
            flex: 1 1 100%;
            display: flex;
            flex-direction: row;
            align-items: center;

            input,
            & > div {   // for the divs that wrap the cc inputs
                flex: 1 1 auto;
            }
        }

        .ad-csz-row,
        .name-row,
        .cc-dets {

            & > div {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }

        .cc-dets {
            & > div {
                // max-width: 120px;

                & > div { // the two wrappers for the cc inputs
                    flex: 1 1 auto;
                }
            }
        }


        .state,
        .postal-code {
            flex: 0 1 auto;
        }

        .postal-code {
            input {
                width: 15%;
                min-width: 100px;
            }
        }

    }
}

@media screen and (max-width: 670px) {

    .pc-cc-wrapper {
        .payment-fields {

            .ad-csz-row,
            .name-row,
            .cc-fields {
                flex-wrap: wrap;

                & > div {
                    flex: 1 1 100%;
                }
            }

            .postal-code {
                input {
                    width: auto;
                    // min-width: 100px;
                }
            }
        }
    }
}

//#endregion


//#region PatronCartItemSummary

.pc-item-sum-wrapper {
    display: flex;
    flex-direction: column;

    table {
        th,
        td {
            white-space: normal !important;
            overflow-wrap: break-all !important;
            max-width: 80px;
        }
    }

    .pc-item-sum-heading {
        display: flex;
        justify-content: space-evenly;
        background-color: $primary-color;
        font-size: 1rem;
        font-weight: 500;
        border-radius: 10px 10px 0 0;
    }

    .pc-item-sum-details,
    .ps-os-row {
        display: flex;
        justify-content: space-evenly;
    }

    .pc-item-sum-details {
        background-color: $company-neutral-light;
    }

    .pc-item-sum-heading,
    .pc-item-sum-details {
        span {
            width: 15%;
            padding: 8px 0 8px 0;
        }

        span:nth-of-type(3n) {
            width: 50%;
        }
    }

    .ps-os-row {
        margin-left: 15%;
        span {
            width: 40%;
            padding: 2px 0 2px 0;
        }

        span:nth-of-type(3n) {
            width: 50%;
        }
    }

    .pc-item-sum-details div:nth-of-type(1n) {
        border-radius: 0 0 10px 10px;
    }

    .pc-item-sum-details div {
        border-radius: 10px;
    }

    .pc-item-sum-event {
        padding-left: 20px;
        margin-bottom: 10px;
        margin-top: 5px;

        .event-sum-desc {
            display: flex;
            margin: 0;
            padding: 0;
            justify-content: space-evenly;
        }

        strong {
            padding: 0 10px 5px 10px;
        }
    }
}

@media screen and (max-width: 620px) {
}

// used on additional inline carts such as online ordering for restaurant
.simplified-summary {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 1rem;

    .pc-item-sum-wrapper {

        .pc-item-sum-heading {
            border-radius: 10px 10px 10px 10px;
            margin-bottom: 2px;
            background-color: $company-neutral-light;
        }

        .pc-item-sum-details {
            background-color: transparent;
            border-bottom: 1px solid #c5c5c5;

            & > * {
                padding: 8px 0 8px 0;
            }
        }

        .pc-item-sum-heading,
        .pc-item-sum-details {
            & > * {
                width: 50%;
            }
            & > *:nth-of-type(2) {
                width: 25%;
            }
        }

    }
}


//#endregion



//#region PatronCartOrderTotals

.patron-cart-order-totals {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ps-os-totals-wrapper {
    width: 100%;
    max-width: 100%;

    .ps-os-totals {
        background-color: $grey-1;
        border: 1px solid $grey-5;
        border-radius: 10px;
        padding: 0.5rem 0;
        border-radius: 

        p {
            display: flex;
            justify-content: space-between;
            padding: 0;
            margin: 0;
            width: 175px;
        }

        .pc-item-sum-details {
            border-bottom: 0;

            & > * {
                padding: 0;
            }
        }
    }
}

//#endregion


