@import '../../../../../../../../assets/css/scss/themes';
.wrapper{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: flex-end !important;
    font-family: $secondary-font-family !important;
    font-size: $small-font-size !important;
    padding:0 !important;

    > div {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .toolbar{
        display:flex;
        flex-direction: row;
        justify-content: flex-end;

        a {                    
            font-family: $secondary-font-family;
            font-size: $small-font-size;
            font-weight: 400;
        }
    }
}