@import '../../../../../assets/css/scss/variables';
@import '../../../../../assets/css/scss/themes';

.wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    position: relative;
    background-color: $modal-body-background-color;

    >div:first-child{
        min-height: calc(100vh - 65px);
        max-height: calc(100vh - 65px);
        
        >div:first-child{
            height: 100%;
        }
    }

    .details{
        max-height: calc(100vh - 65px);
        overflow: hidden;
        overflow-y: auto;
    }
    
    .toolbar{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        background-color: $modal-body-background-color;
        bottom:0;
        left:0;
        width: 100%;
        padding: 1rem;
        padding-bottom:0.5rem;
        margin-top: calc($modal-border-radius * -1);
    }
}

.divider{
    border-right: 1px solid $divider-color;
    margin-right: $main-padding;
}

@media screen and (max-width: 991px) {
    .divider{
        border-right: none;
        border-bottom: 1px solid $divider-color;
        margin-right: 0;
        margin-bottom: $main-padding;
    }    
}