@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';

footer {
    background-color: $background-color;
    width: 100%;

    .copyright {
        text-align: left;
        font-size: .75rem;
        color: $primary-font-color;
        padding: 0.5rem;
    }

}