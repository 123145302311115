@import '../../../../../assets/css/scss/variables';
@import '../../../../../assets/css/scss/themes';

.grid{
    column-gap: 10px;

    @media (min-width: 1201px) {
        &.columns-xl-1{
            column-count: 1;
        }
        &.columns-xl-2{
            column-count: 2;
        }
        &.columns-xl-3{
            column-count: 3;
        }
        &.columns-xl-4{
            column-count: 4;
        }
        &.columns-xl-5{
            column-count: 5;
        }
        &.columns-xl-6{
            column-count: 6;
        }
    }

    @media (max-width: 1200px) {
        &.columns-lg-1{
            column-count: 1;
        }
        &.columns-lg-2{
            column-count: 2;
        }
        &.columns-lg-3{
            column-count: 3;
        }
        &.columns-lg-4{
            column-count: 4;
        }
        &.columns-lg-5{
            column-count: 5;
        }
        &.columns-lg-6{
            column-count: 6;
        }
    }
        
    @media (max-width: 992px) {
        &.columns-md-1{
            column-count: 1;
        }
        &.columns-md-2{
            column-count: 2;
        }
        &.columns-md-3{
            column-count: 3;
        }
        &.columns-md-4{
            column-count: 4;
        }
        &.columns-md-5{
            column-count: 5;
        }
        &.columns-md-6{
            column-count: 6;
        }
    }

    @media (max-width: 768px) {
        &.columns-sm-1{
            column-count: 1;
        }
        &.columns-sm-2{
            column-count: 2;
        }
        &.columns-sm-3{
            column-count: 3;
        }
        &.columns-sm-4{
            column-count: 4;
        }
        &.columns-sm-5{
            column-count: 5;
        }
        &.columns-sm-6{
            column-count: 6;
        }
    }

    img {
        width: 100%;
        height:auto;
        display: block;
        object-fit: contain;
        object-position: center;
        
        &.grid{
            object-fit: cover;
        }
    }
    
    figure {
        margin: 0;
        display: grid;
        grid-template-rows: 1fr auto;
        break-inside: avoid;
    }
    
    figure > img {
        grid-row: 1 / -1;
        grid-column: 1;
    }
    
    figure a {
        color: black;
        text-decoration: none;
    }
    
    figcaption {
        grid-row: 2;
        grid-column: 1;
        background-color: rgba(0,0,0,.5);
        color: #eee;
        font-size: 0.75rem;
        font-family: $secondary-font-family;
        padding: .2em .5em;
        width:100%;
        justify-self: start;
    }
}

.modal-body{
    padding: 0 !important;

    img{
        width: 100%;
        height: auto;
    }
}

.darker{
    opacity: 0.9 !important;
}