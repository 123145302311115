@import "../../../assets/css/scss/variables";
@import "../../../assets/css/scss/themes";

.error {
    position:relative;
    color:#fff !important;
    padding:1rem 1.5rem;
    font-size: .85rem;
    line-height: 1rem;
    border-radius: 5px;
    margin:.55rem 0;
    max-width: 500px;
    word-wrap: break-all;
    z-index: 99999999;
}
  
.error h1{
    font-size:1.2rem !important;
    font-weight: 500;

}

.error ul li{
    line-height:1.5rem;
    color: #fff !important;
}

.error-modal, 
.warning-modal {
    z-index: 9062 !important;
}

.error-modal .modal-content {
    background-color: $error-color !important;
    border: none !important;
    box-shadow: $shadow-elevation-3;
}

.warning-modal .modal-content {
    background-color: $secondary-color !important;
    border: none !important;
    box-shadow: $shadow-elevation-3;
}