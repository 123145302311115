@import './themes';

/* WIZARD GENERAL STYLES */

.wizard .form-label.question {
    display: block;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    line-height: 1rem;
}
.wizard .form-label.question.smaller {
    font-size: 1.0rem;
    font-weight: 500;
}
.wizard .form-label.question.larger {
    font-size: 1.4rem;
    font-weight: 500;
}
.wizard .form-label.table-mid-header {
    display: block;
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 0.5rem;
}
.wizard .form-row  {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-bottom: 1rem;
    justify-content: left;
    margin-left: 0;
    margin-right: 0;
}
.wizard .form-row.small-margin {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}
.wizard .form-row.full-width {
    width: 100%;
}
.wizard .form-row.stacked  {
    flex-direction: column;
    align-items: center;
}
.wizard .form-row.stacked.left  {
    align-items: flex-start;
}
.wizard .form-row.stacked.float-left  {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.wizard .form-row.centered  {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.wizard .title{
    display: block;
    font-family: $secondary-font-family;
    font-weight: $bold-font-weight;
    font-size: 1.4rem;
    margin:0;
    margin-bottom: 0.75rem;
    text-align: center;
}
.wizard .title.left{
    text-align: left;
}

.wizard .title.smaller{
    font-size: 1.1rem;
}

.wizard .subtitle{
    display: block;
    font-family: $secondary-font-family;
    font-size: 0.8rem;
    margin:0;
    margin-bottom: 1rem;
    transform: translateY(-0.5rem);
}

.wizard .btn {
    margin: 0;
}
.wizard .button-row .btn {
    width: 200px;
}

.wizard .sm-input {
    width: 100px;
}
.wizard .md-input {
    width: 250px;
}
.wizard .lg-input {
    width: 100%;
}

@media(max-width: 700px) {
    .wizard .button-row div {
        flex-grow: 1;
        padding: 0 10px;
    }
    .wizard .button-row .btn {
        width: 100%;
    }
    .wizard .lg-input {
        width: 95%;
    }
}

.wizard .react-numeric-input input {
    padding: 8px;
    width: 100px;
}
.wizard .invalid {
    border: 1px solid $error-color !important;
}
.wizard .disabled {
    color:rgb(158, 158, 158);
}
.wizard .err {
    color: $error-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.wizard .info {
    margin-top: 0.5rem;
    color: $primary-color;
    flex-basis: 100%;
}

.wizard .corner-icon {
    position: absolute;
    z-index: 100;
    right: 10px;
    top: 10px;
}
.wizard .corner-icon i, .wizard .corner-icon {
    color: $primary-color;
}
.wizard .corner-icon:hover i, .wizard corner-icon:hover {
    color: black;
    cursor: pointer;
}
.wizard .tooltip > .tooltip-inner {
    background-color: #2e2e2e;
    border: 1px solid #2e2e2e;
;
}
.wizard .tooltip > .arrow::before {
    border-left: 5px solid #2e2e2e !important;
}



/* RADIO BUTTONS */

.wizard .form-check.form-radio {
    margin: 0;
    float:left;
    width:200px;
    height:60px;
    position:relative;
    font-size: 0.75rem;
    &:is(.sm.wide, .sm){
        &:not(:checked){
            border: 1px solid $form-control-border-color;
            border-radius: $button-border-radius;
        }
    }
}

.wizard .form-check.form-radio.sm {
    width:100px;
    height:40px;
}

.wizard .form-check.form-radio.sm.wide {
    width: 220px;
}

.wizard .form-check.form-radio.tiny {
    width:30px;
    height:30px;
    margin: 5px 0.3rem;
}

.wizard .form-check.form-radio.grid {
    width: 100%;
    height:25px;
    margin: 0;
}

.wizard .form-check.form-radio label, .form-check.form-radio input {
    display:block;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}

.wizard .week-display {
    column-gap: 10px;
}

.wizard .week-display .table-header {
    border: 0 !important;
    background-color: $card-background-color !important;
}

.wizard .week-display div.table-header {
    padding-left: 0 !important;
}

.wizard .form-check.form-radio input[type="radio"],
.wizard .form-check.form-radio input[type="checkbox"] {
    opacity:0.011;
    z-index:100;
}

.wizard .form-check.form-radio input[type="radio"]:checked + label,
.wizard .form-check.form-radio input[type="checkbox"]:checked + label {
    border: $button-border-width solid $primary-color;
    background-color: $primary-color;
    color: $primary-inverse-color;
}

// small round buttons - checked and unchecked styles

.wizard .form-check.form-radio.tiny input[type="checkbox"]:not(:checked) + label {
    background-color: $card-background-color !important;
    border: $neutral-border;
}

.wizard .form-check.form-radio.tiny input[type="checkbox"]:checked + label {
    background-color: $primary-color !important;
    color: $card-background-color;
}

.wizard .week-display-container {
    margin: 0.5rem 0;
    padding: 0.7rem 1rem 0rem 1rem;
}

.wizard .week-display-container .form-check.form-radio input[type="checkbox"]:not(:checked)+ label {
    background-color: $neutral-background-color;
    border: $neutral-border;
}


.wizard .form-check.form-radio label {
    padding:5px;
    border: $neutral-border; 
    cursor:pointer;
    z-index:90;
    border-radius: $button-border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $button-font-family;
    font-size: $button-font-size;
    line-height: $button-line-height;
    font-weight: $button-font-weight;
    text-transform: $button-text-transform;
    box-shadow: $button-shadow;
}

.wizard .form-check.form-radio.tiny label,
.wizard .form-check.form-radio.grid label {
    background-color: $card-background-color;
    margin: 0;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.2rem;
    border-radius: 50%;
    border: $primary-color;
}

.wizard .form-check.form-radio.grid label {
    border-radius: $button-border-radius;
}

.wizard .card {
    /* makes sure react-datepicker-popper is visible */
    overflow: visible; 
}
.wizard .btn-light {
    border:1px solid #CCC; 
}
.wizard .btn-light:hover {
    background-color: #fafafa;
    border-color: #eeeeee;
    color: $primary-color;
}
.wizard .btn-light:disabled:hover {
    color: #212529;
}

.wizard .btn-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    max-width: 300px;
}
.wizard .btn-column.grid {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: none;
    gap: 3px;
}
.wizard .btn-column.sm {
    max-width: 150px;
}
.wizard .btn-column.md {
    max-width: 200px;
}
.wizard .btn-column .btn {
    margin: 4px;
}

/* END STANDARD WIZARD STYLES */



/* AVAILABILITY */

.wizard-availability .plus-line {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
}
.wizard-availability .plus-line label {
    width: 100px;
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 0;
}
.wizard-availability .btn.highlight-btn {
    padding: 5px 0 !important;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    font-weight: 500;
    color: $primary-color;
    border: none;
    background-color: none;
}
.wizard-availability .btn.highlight-btn i, .btn.highlight-btn:hover i {
    color: $primary-color;
}
.wizard-availability .btn.highlight-btn.close {
    margin-left: 2rem;
}
.form-grid-availability {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 0.9rem 0.2rem;
    margin: 0.5rem 0;
}
.form-grid-availability>div {
    display: flex;
    align-items: center;
}
.form-grid-availability .grid-day, .form-grid-availability .grid-times {
    margin-right: 40px;
}
.form-grid-availability .grid-day, .form-grid-availability .grid-times span {
    margin: 0 10px;
}
.form-grid-availability .button-row {
    margin: 0;
    margin-right: 20px;
    width: 60px;
}
.form-grid-availability .btn.round-button-tiny {
    padding: 0 !important;
    border-radius: 20px;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    font-weight: 300;
}
.form-grid-availability .btn.round-button-tiny i {
    color: black;
    margin-right: 0 !important;
}
.form-grid-availability .btn.round-button-tiny:hover:enabled i {
    color: $primary-color;
}
.wizard .btn.long-button-tiny {
    padding: 4px 8px 4px 4px !important;
    border-radius: 20px;
    height: 24px;
    justify-content: center;
    align-items: center;
    font-size: 1.1em;
    font-weight: 500;
    color: $primary-color;
    border: 0;
}
.wizard .btn.long-button-tiny i, .btn.long-button-tiny:hover i {
    color: $primary-color;
}
.wizard button > * {
    pointer-events: none;
}
.form-grid-availability .time-conflict {
    border: 1px solid #dc3545;
    background-color: #ffe9eb;
}
.form-grid-availability .time-conflict option {
    background-color: white;
}
.form-grid-availability .conflict-text {
    grid-column: 1 / -1;
    color: #dc3545;
}




/* INCREMENTS - AND PAYMENT */

.form-grid-increments {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 0.2rem 0.5rem;
    margin: 0;
}
.form-grid-increments .increments-fullrow {
    grid-column: 1 / -1;
    margin-top: 0.2rem;
}
.form-grid-increments.centered {
    margin-left: auto;
    margin-right: auto;
}
.form-grid-increments.centered .increments-fullrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.form-grid-increments .gap-above {
    margin-top: 1.7rem;
}
.form-grid-increments .small-gap-above {
    margin-top: 0.8rem;
}
.payment .form-grid-increments .gap-above {
    margin-top: 0.8rem;
}
.payment .form-group.associated-fee {
    margin-bottom: 0rem;
}
.increments .align-vertical-center {
    height: 100%;
    display: flex;
    align-items: center;
}
.increments .custom-select {
    width: auto;
}
.increments .padding-left {
    margin-left: 1rem;
}
.increments .padding-left-2 {
    margin-left: 2rem;
}
.increments label {
    margin-bottom: 0;
}
.increments .form-row {
    margin: 1.2rem 0 0.8rem 0;
}
.increments .form-row ~ .form-row {
    margin-top: 2rem;
}
.increments .left {
    align-items: center;
    margin-right: 0px;
    margin-left: 0px;
}
.increments .align-left  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.increments .align-left.inline-row {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.increments .form-row.booking-notice {
    margin-top: 0.4rem;
    flex-wrap: nowrap;
}
.increments .form-row .fullwidth {
    width: 100%;
    margin-bottom: 0.5rem;
}
.increments .nobold {
    font-size: 0.8rem;
    font-weight: 400;
}


/* SUMMARY */

.summary-card {
    border: 1px solid $tertiary-color !important;
    margin-bottom: 1rem;
}
.summary-row {
    width: 100%;
    margin-top: 1rem;
}
.summary-row:first-of-type {
    margin-top: 0;
}
.summary-name {
    font-size: 1rem;
    font-weight: 600;
    /* margin-bottom: 0.3rem; */
}
.summary-grid {
    display: inline-grid;
    column-gap: 10px;
}
.summary-grid.wide {
    grid-template-columns: 170px repeat(2, auto);
    column-gap: 2rem;
    row-gap: 0.5rem;
}
.summary-grid.dense {
    grid-auto-flow: dense;
}
.summary-grid.availability {
    grid-template-columns: 80px repeat(3, auto);
    grid-column-start: 2;
    grid-column-end: 4;
}
.summary-grid.second-column {
    grid-column-start: 2;
    grid-column-end: 4;
}
.summary-grid .service-start-dates {
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 1rem;
    row-gap: 0.6rem;
}
.summary-grid .service-date-title {
    font-weight: 600;
    grid-column-start: 1;
    grid-column-end: 2;
}
.summary-grid .summary-name {
    grid-column-start: 1;
    grid-column-end: 3;
}
.summary-grid .gap-above {
    margin-top: 0.5rem;
}

.wizard .no-wrap {
    flex-wrap: nowrap;
}

.wizard .input-group-text.transparent {
    border: 0;
    background-color: transparent;
}

.input-group-text {
    font-size: 0.85rem;
}

.invisible {
    visibility: 0;
}

.wizard .select-token-button {
    margin-left: 0.75rem;
}
.wizard .align-button-vertical-center {
    display: flex;
    align-items: center;
}

.wizard .tooltip-wrapper i {
    margin-left: 8px;
}
.wizard .form-grid-increments .form-label.nobold {
    display:block;
}

.wizard{
    .switch-row{
        justify-content: flex-start;
        input{
            margin-right: .5rem;
        }
    }
}