@import '../../../../../assets/css/scss/themes';
.wrapper{
    display: flex !important;
    justify-content: space-between !important;
    align-items: flex-start !important;
    font-size: $primary-font-size !important;
    line-height: $primary-line-height !important;
    padding:0 !important;

    > div {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .title{
        font-family: $secondary-font-family !important;
        font-weight: $bold-font-weight !important;
    }

    .toolbar{
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
    }
}