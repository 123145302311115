
$min-height: 80px;
$min-width: 80px;
.drop-zone {
    position:relative;
    top: 0;
    left: 0;
    flex: 0 0 auto;
    height: 5px;
    width: auto;
    transition: all 0.2s ease-in-out;
    padding: 0;
    margin: 0;
    z-index: 999;

    > div{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 10px;
        transition: height 0.2s ease-in-out;
        padding: 0;
        margin: 0;
        z-index: -1;
    }

    &.bottom{
        bottom: 10px;
        top: unset;
    }

    &:not(.bottom){
        > div {
            height: 15px;
        }
    }

    &:not(.vertical):last-child{
        top: unset;

        &.active{
            bottom: 0;
        }
    }    

    &:not(.col-sm-auto):not(.section){
        //height: 100%;
        min-height: 0;

        &.active{
            height: auto !important;
            min-height: $min-height;
        }
    }

    &.section:not(.vertical){

        &:last-child{
            bottom: 0;
            top:unset;
        }

        > div {
            height: 30px;
        }
        

        &.active{
            height: $min-height !important;
        }
    }

    &.vertical{
        width: 0;
        height: auto !important;

        &.active{
            width: $min-width !important;

            > div{
                &:hover{
                    position:relative;
                    width: $min-width;
                }    
            }
        }

        > div{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 10px;
            transition: all 0.2s ease-in-out;
            padding: 0;
            margin: 0;
            z-index: -1;
        }

        &:last-child{
            right: 10px;
            left: unset;

            &.active{
                right: 0;
            }
        }

    }

    &.active{
        background: #2196f3;
        position: relative;
        > div{
            &:hover{
                position:absolute;
                height: $min-height;
                z-index: 99999;
            }
        }        
    }

    &.over{
        position:relative;
        z-index:999999999 !important;
    }
    &.notover{
        z-index:997 !important;
    }
}