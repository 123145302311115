@import '../../../../assets/css/scss/variables';
@import '../../../../assets/css/scss/themes';
@import '../../../../assets/css/scss/mixins';

.modal-dialog.user-orders-modal {
    max-width: 800px;
    width: 60%;
}
.user-orders-modal .modal-body{
    overflow-x: scroll;
    min-height: 750px;
    height: 89vh;
}
.user-orders-modal .modal-title{
    text-align: center;
    padding: 5px;
}
.uo-order-det-table th{
    width: 215px;
}
.uo-order-table-wrapper table{
    margin-left: 1rem;
}
.uo-coupon-p{
    margin-left: 1rem;
}
.coupon-title{
    font-weight: 600;
}
.uo-det-ref-btn{
    width: 200px;
}

@media only screen and (min-width: 700px) and (max-width: 1000px){
    .modal-dialog.user-orders-modal {
        max-width: 600px;
        width: 100%;
    }
    
    .user-orders-modal .modal-body{
        overflow-y: scroll;
        overflow-y: scroll;
    }
}
@media only screen and (max-width: 699px){
    .modal-dialog.user-orders-modal {
        max-width: 600px;
        width: 100%;
    }
    
    .user-orders-modal .modal-body{
        overflow-x: scroll;
        overflow-y: scroll;
    }
}
/* @media only screen and (max-width: 399px){
    .modal-dialog.user-orders-modal {
        width: 90%;
    }
    .user-orders-modal .modal-body{
        overflow-y: scroll;
        overflow-y: scroll;
    }
} */
