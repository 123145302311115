@import '../../assets/css/scss/variables';
@import '../../assets/css/scss/themes';

.easy-crop-wrapper{
    position: fixed;
    z-index: 9000;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.24);
}

.cropper-container {
    position: fixed;
    z-index:9999;
    top: 15%;
    left: 25%;
    right: 25%;
    bottom: 15%;
    background-color: $modal-body-background-color;
    box-shadow: $shadow-elevation-3
}
.easy-crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 100px;
}

.control-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    padding:1rem;
    transform: translateX(-50%);
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    .form-range{
        width: 250px;
    }
}
.zoom-label{
    margin-bottom:0;
    margin-right: 3px;
}

.control-container:hover input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
}

.control-container:hover input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.btn-container{
    position: absolute;
    bottom: 10px;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: nowrap;
}
.btn-primary.ec-btn{
    width: 5rem;
}

.img-zoom-slider {
    -webkit-appearance: none;
    width: 50%;
    height: 3px;
    border-radius: 5px;  
    background: #c0c0c0;
    outline: none;
    border: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.img-zoom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border:none;
    border-radius: 50%; 
    background: #f67730;
    cursor: pointer;
}

.img-zoom-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: none;
    background: #f67730;
    cursor: pointer;
}

.pre-viewer {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.output {
    position: relative;
    width: 300px;
    overflow: hidden;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.3);
}

.output img {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
}

.grid-toggle-switch{
    vertical-align: bottom;
}
@media (max-width: 350px){
    .control-container{
        flex-direction: column;
        height: 130px !important;
        .save-cancel-btns{
            flex-direction: row !important;
            margin-top: 1rem;
        }
    }
}

@media (max-width: 500px){
    .cropper-container{
        top: 15%;
        bottom: 10%;
        left: 5%;
        right: 5%;
    }
    .easy-crop-container{
        bottom: 135px;
    }
    .control-container{
        height: 85px;
        .form-range{
            width: 125px;
        }
    }

}

@media (min-width: 501px) and (max-width: 700px){
    .cropper-container{
        top: 10%;
        bottom: 10%;
        left: 10%;
        right: 10%;
    }
    .control-container{
        .form-range{
            width: 150px;
        }
    }
    .btn-container{
        width: 60%;
    }

}

@media (min-width: 701px) and (max-width: 1200px){
    .cropper-container{
        top: 20%;
        bottom: 20%;
        left: 20%;
        right: 20%;
    }
    .btn-container{
        width: 48%;
    }
    .control-container{
        .form-range{
            width: 175px;
        }
    }
}

@media (max-width: 1200px){
    .control-container{
        justify-content: space-between;
        .save-cancel-btns{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }
}

