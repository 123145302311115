@use 'themes' as theme;

$yellow-dark: #eec25f;
$yellow-light: #fcf6ca;
$green-dark: #4eab5e;
$green-light: #c7e9cd;
$green-bright: #34eb34;
$blue-dark: #4e8bd1;
$blue-light: #86b6e7;
$blue-verylight: #CCDCFC;
$orange-dark: #f67730;
$purple-dark: #8d40a5;
$courtside-grille-blue: #092379;

// named as such so that we can switch the gradient around at will - even reverse for a dark theme
$grey-0: #ffffff;
$grey-1: #fafafa;
$grey-2: #eeeeee;
$grey-3: #e2e2e2;
$grey-4: #D6D6D6;
$grey-5: #afafaf;
$grey-6: #a5a5a5;
$grey-7: #9e9e9e;
$grey-8: #818181;
$grey-9: #6e6e6e;
$grey-10: #5c5c5c;
$grey-11: #4d4d4d;
$grey-12: #424242;


// these will eventually be overwritten with each company's color choices

$company-primary: $orange-dark;
$company-secondary: #536dfe;
$company-tertiary: $yellow-light;
$company-neutral-dark: #9e9e9e;
$company-neutral-light: #e5e5e5;
$company-logo-one: #86b6e7;
$company-logo-two: #86b6e7;
$company-link: $orange-dark;
$company-link-visited: $orange-dark;
$company-link-hover: #333333;
$company-dark-error: #cc0000; 
$company-bright-error:#dc3545;
$company-breadcrumb:#4e8bd1;
$company-breadcrumb-background: #f0f0f0;
$company-maincontent-background: #e5e5e5;
$company-dark-success: #4e8bd1;
$company-bright-success: #4e8bd1;
$company-white: #FFFFFF;
$company-black: #262D33;
$company-disabled-button: #bdbdbd;
$company-toast-background: rgba(83, 109, 254, .95);
$company-info: #4e8bd1;

$company-pos-top-bar: #212121;
$company-pos-checkins-bar: #083250;
$company-pos-items-bg: $company-neutral-light;
$company-pos-patron-background: $company-neutral-light;

$company-navbar-background: #ffffff;
$company-navbar-foreground: #222222;
$company-navbar-border-bottom: #e5e5e5;
$company-navbar-height-full: 75px;
$company-navbar-height-small: 50px;

$company-pos-header-height: 50px;
$company-pos-recent-checkins-height: 38px;
$company-kms-header-height: 60px;
$company-kms-column-title-height: 50px;

$company-menu-background: #ffffff;
$company-menu-foreground: #262D33;
$company-menu-section-background: #051C2C;
$company-menu-section-foreground: #ffffff;
$company-menu-section-items-background: #99bde5;
$company-menu-section-items-foreground: #262D33;
$company-menu-hover-background: #6c94c2;
$company-menu-hover-foreground: #ffffff;

$pos-theme-primary: #f67730;
$pos-theme-blue: #4e8bd1;
$pos-theme-green: #73C982;

$active-sub: rgb(0, 145, 0);
$suspended-sub: rgb(201, 138, 21);
$cancelled-sub: rgb(0, 171, 223);
$expired-sub: rgb(196, 23, 23);
