@import 'themes-light';

// main theme
/**/
//@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600&family=Roboto:wght@400;500;700&display=swap');

$company-name: var(--company-name);
$background-image: var(--background-image);
$background-image-filter: var(--background-image-filter);
$logoNoText: var(--logoNoText);
$logoHeader: var(--logoHeader);
$logo: var(--logo);
$backgroundEvents: var(--backgroundEvents);

:export {
    backgroundImage: $background-image;
    companyName: $company-name;
    logo: $logo;
    logoHeader: $logoHeader;
    logoNoText: $logoNoText;
    backgroundEvents: $backgroundEvents;
}

/**/
$primary-font-family: var(--primary-font-family, $primary-font-family);
$primary-font-size: var(--primary-font-size, $primary-font-size);
$primary-line-height: var(--primary-line-height, $primary-line-height);
$primary-font-color: var(--primary-font-color, $primary-font-color);
$primary-inverse-color: var(--primary-inverse-color, $primary-inverse-color);
$primary-font-weight: var(--primary-font-weight, $primary-font-weight);
$secondary-font-family: var(--secondary-font-family, $secondary-font-family);
$secondary-font-size: var(--secondary-font-size, $secondary-font-size);
$secondary-line-height: var(--secondary-line-height, $secondary-line-height);
$secondary-font-color: var(--secondary-font-color, $secondary-font-color);
$secondary-inverse-color: var(--secondary-inverse-color, $secondary-inverse-color);
$secondary-font-weight: var(--secondary-font-weight, $secondary-font-weight);

$bold-font-weight: var(--bold-font-weight, $bold-font-weight);
$light-font-weight: var(--light-font-weight, $light-font-weight);
$regular-font-weight: var(--regular-font-weight, $regular-font-weight);
$big-font-size: var(--big-font-size, $big-font-size);
$big-font-line-height: var(--big-font-line-height, $big-font-line-height);
$small-font-size: var(--small-font-size, $small-font-size);
$small-font-line-height: var(--small-font-line-height, $small-font-line-height);

$primary-color: var(--primary-color, $primary-color);
$primary-hover-color: var(--primary-hover-color, $primary-hover-color);
$primary-light-color: var(--primary-light-color, $primary-light-color);
$secondary-color: var(--secondary-color, $secondary-color);
$secondary-hover-color: var(--secondary-hover-color, $secondary-hover-color);
$secondary-light-color: var(--secondary-light-color, $secondary-light-color);
$tertiary-color: var(--tertiary-color, $tertiary-color);
$tertiary-hover-color: var(--tertiary-hover-color, $tertiary-hover-color);
$tertiary-light-color: var(--tertiary-light-color, $tertiary-light-color);
$neutral-background-color: var(--neutral-background-color, $neutral-background-color);
$neutral-color: var(--neutral-color, $neutral-color);
$neutral-hover-background-color: var(--neutral-hover-background-color, $neutral-hover-background-color);
$neutral-hover-color: var(--neutral-hover-color, $neutral-hover-color);
$neutral-border: var(--neutral-border, $neutral-border);
$neutral-hover-border: var(--neutral-hover-border, $neutral-hover-border);

$shadow-color: var(--shadow-color, $shadow-color);
$shadow-elevation-0: var(--shadow-elevation-0, $shadow-elevation-0);
$shadow-elevation-1: var(--shadow-elevation-1, $shadow-elevation-1);
$shadow-elevation-2: var(--shadow-elevation-2, $shadow-elevation-2);
$shadow-elevation-3: var(--shadow-elevation-3, $shadow-elevation-3);

$error-color: var(--error-color, $error-color);
$error-text-color: var(--error-text-color, $error-text-color);
$success-color: var(--success-color, $success-color);
$warning-color: var(--warning-color, $warning-color);
$disabled-color: var(--disabled-color, $disabled-color);

$background-color: var(--background-color, $background-color);

$divider-color: var(--divider-color, $divider-color);
$divider-border: var(--divider-border, $divider-border);
$divider-margin: var(--divider-margin, $divider-margin);

$scrollbar-color: var(--scrollbar-color, $scrollbar-color);
$scrollbar-background-color: var(--scrollbar-background-color, $scrollbar-background-color);
$scrollbar-width: var(--scrollbar-width, $scrollbar-width);
$scrollbar-border-radius: var(--scrollbar-border-radius, $scrollbar-border-radius);

$main-padding: var(--main-padding, $main-padding);
$content-font-size: var(--content-font-size, $content-font-size);
$content-padding: var(--content-padding, $content-padding);

$small-main-padding: var(--small-main-padding, $small-main-padding);
$small-content-padding: var(--small-content-padding, $small-content-padding);

$header-background-color: var(--header-background-color, $header-background-color);
$header-font-color: var(--header-font-color, $header-font-color);
$header-hover-color: var(--header-hover-color, $header-hover-color);
$header-font-size: var(--header-font-size, $header-font-size);
$header-font-weight: var(--header-font-weight, $header-font-weight);
$header-font-family: var(--header-font-family, $header-font-family);
$header-height: var(--header-height, $header-height);
$header-padding: var(--header-padding, $header-padding);
$header-text-decoration: var(--header-text-decoration, $header-text-decoration);

$logo-height: var(--logo-height, $logo-height);
$logo-width: var(--logo-width, $logo-width);
$logo-url: var(--logo-url, $logo-url);
$logo-background-position: var(--logo-background-position, $logo-background-position);
$logo-background-repeat: var(--logo-background-repeat, $logo-background-repeat);
$logo-background-size: var(--logo-background-size, $logo-background-size);
$logo-filter: var(--logo-filter, $logo-filter);

$link-color: var(--link-color, $link-color);
$link-visited-color: var(--link-visited-color, $link-visited-color);
$link-hover-color: var(--link-hover-color, $link-hover-color);
$link-active-color: var(--link-active-color, $link-active-color);
$link-font-size: var(--link-font-size, $link-font-size);
$link-font-weight: var(--link-font-weight, $link-font-weight);
$link-text-decoration: var(--link-text-decoration, $link-text-decoration);
$link-hover-text-decoration: var(--link-hover-text-decoration, $link-hover-text-decoration);
$link-padding: var(--link-padding, $link-padding);
$link-margin: var(--link-margin, $link-margin);

$button-font-family: var(--button-font-family, $button-font-family);
$button-font-size: var(--button-font-size, $button-font-size);
$button-line-height: var(--button-line-height, $button-line-height);
$button-font-weight: var(--button-font-weight, $button-font-weight);
$button-border-radius: var(--button-border-radius, $button-border-radius);
$button-border: var(--button-border, $button-border);
$button-border-color: var(--button-border-color, $button-border-color);
$button-padding: var(--button-padding, $button-padding);
$button-margin: var(--button-margin, $button-margin);
$button-background-color: var(--button-background-color, $button-background-color);
$button-color: var(--button-color, $button-color);
$button-shadow: var(--button-shadow, $button-shadow);
$button-hover-background-color: var(--button-hover-background-color, $button-hover-background-color);
$button-hover-color: var(--button-hover-color, $button-hover-color);
$button-active-background-color: var(--button-active-background-color, $button-active-background-color);
$button-active-color: var(--button-active-color, $button-active-color);
$button-active-filter:  var(--button-active-filter, $button-active-filter);
$button-disabled-background-color: var(--button-disabled-background-color, $button-disabled-background-color);
$button-disabled-color: var(--button-disabled-color, $button-disabled-color);
$button-border-width: var(--button-border-width, $button-border-width);
$button-text-transform: var(--button-text-transform, $button-text-transform);
$button-text-shadow: var(--button-text-shadow, $button-text-shadow);
$button-transparent: var(--button-transparent, $button-transparent);
$button-small-font-size: var(--button-small-font-size, $button-small-font-size);
$button-small-padding: var(--button-small-padding, $button-small-padding);
$button-large-font-size: var(--button-large-font-size, $button-large-font-size);
$button-large-padding: var(--button-large-padding, $button-large-padding);
$button-round-border-radius: var(--button-round-border-radius, $button-round-border-radius);
$button-round-padding: var(--button-round-padding, $button-round-padding);
$button-transparent-background-color: var(--button-transparent-background-color, $button-transparent-background-color);
$button-transparent-color: var(--button-transparent-color, $button-transparent-color);
$button-transparent-hover-color: var(--button-transparent-hover-color, $button-transparent-hover-color);
$button-icon-margin: var(--button-icon-margin, $button-icon-margin);

$highlight-color: var(--highlight-color, $highlight-color);
$highlight-hover-color: var(--highlight-hover-color, $highlight-hover-color);
$highlight-background-color: var(--highlight-background-color, $highlight-background-color);
$highlight-background-hover-color: var(--highlight-background-hover-color, $highlight-background-hover-color);
$highlight-font-weight: var(--highlight-font-weight, $highlight-font-weight);

$breadcrumb-background-color: var(--breadcrumb-background-color, $breadcrumb-background-color);
$breadcrumb-color: var(--breadcrumb-color, $breadcrumb-color);
$breadcrumb-default-color: var(--breadcrumb-default-color, $breadcrumb-default-color);
$breadcrumb-font-size: var(--breadcrumb-font-size, $breadcrumb-font-size);
$breadcrumb-text-decoration: var(--breadcrumb-text-decoration, $breadcrumb-text-decoration);
$breadcrumb-hover-color: var(--breadcrumb-hover-color, $breadcrumb-hover-color);
$breadcrumb-hover-background-color: var(--breadcrumb-hover-background-color, $breadcrumb-hover-background-color);
$breadcrumb-padding: var(--breadcrumb-padding, $breadcrumb-padding);
$breadcrumb-margin: var(--breadcrumb-margin, $breadcrumb-margin);
$breadcrumb-border-radius: var(--breadcrumb-border-radius, $breadcrumb-border-radius);

$chip-font-family: var(--chip-font-family, $chip-font-family);
$chip-background-color: var(--chip-background-color, $chip-background-color);
$chip-padding: var(--chip-padding, $chip-padding);
$chip-margin: var(--chip-margin, $chip-margin);
$chip-font-size: var(--chip-font-size, $chip-font-size);
$chip-font-weight: var(--chip-font-weight, $chip-font-weight);
$chip-line-height: var(--chip-line-height, $chip-line-height);
$chip-border-radius: var(--chip-border-radius, $chip-border-radius);
$chip-border: var(--chip-border, $chip-border);
$chip-color: var(--chip-color, $chip-color);
$chip-hover-background-color: var(--chip-hover-background-color, $chip-hover-background-color);
$chip-hover-color: var(--chip-hover-color, $chip-hover-color);

$badge-font-family: var(--badge-font-family, $badge-font-family);
$badge-background-color: var(--badge-background-color, $badge-background-color);
$badge-color: var(--badge-color, $badge-color);
$badge-padding: var(--badge-padding, $badge-padding);
$badge-margin: var(--badge-margin, $badge-margin);
$badge-font-size: var(--badge-font-size, $badge-font-size);
$badge-font-weight: var(--badge-font-weight, $badge-font-weight);
$badge-line-height: var(--badge-line-height, $badge-line-height);
$badge-border-radius: var(--badge-border-radius, $badge-border-radius);
$badge-border: var(--badge-border, $badge-border);
$badge-border-flash-color: --var($badge-border-flash-color, $badge-border-flash-color);
$badge-hover-background-color: var(--badge-hover-background-color, $badge-hover-background-color);
$badge-hover-color: var(--badge-hover-color, $badge-hover-color);
$badge-size: var(--badge-size, $badge-size);
$shopping-cart-icon-size: --var($shopping-cart-icon-size, $shopping-cart-icon-size);

$modal-border: var(--modal-border, $modal-border);
$modal-border-radius: var(--modal-border-radius, $modal-border-radius);
$modal-header-font-family: var(--modal-header-font-family, $modal-header-font-family);
$modal-header-font-size: var(--modal-header-font-size, $modal-header-font-size);
$modal-header-border: var(--modal-header-border, $modal-header-border);
$modal-header-background-color: var(--modal-header-background-color, $modal-header-background-color);
$modal-header-padding: var(--modal-header-padding, $modal-header-padding);
$modal-header-margin: var(--modal-header-margin, $modal-header-margin);
$modal-header-content: var(--modal-header-content, $modal-header-content);
$modal-body-font-family: var(--modal-body-font-family, $modal-body-font-family);
$modal-body-font-size: var(--modal-body-font-size, $modal-body-font-size);
$modal-body-line-height: var(--modal-body-line-height, $modal-body-line-height);
$modal-body-shadow: var(--modal-body-shadow, $modal-body-shadow);
$modal-body-border: var(--modal-body-border, $modal-body-border);
$modal-body-padding: var(--modal-body-padding, $modal-body-padding);
$modal-body-background-color: var(--modal-body-background-color, $modal-body-background-color);
$modal-body-border-radius: var(--modal-body-border-radius, $modal-body-border-radius);
$modal-background-color: var(--modal-background-color, $modal-background-color);
$modal-close-button-border: var(--modal-close-button-border, $modal-close-button-border);
$modal-close-button-border-radius: var(--modal-close-button-border-radius, $modal-close-button-border-radius);
$modal-close-button-background-color: var(--modal-close-button-background-color, $modal-close-button-background-color);
$modal-close-button-color: var(--modal-close-button-color, $modal-close-button-color);
$modal-close-button-padding: var(--modal-close-button-padding, $modal-close-button-padding);
$modal-close-button-margin: var(--modal-close-button-margin, $modal-close-button-margin);
$modal-close-button-opacity: var(--modal-close-button-opacity, $modal-close-button-opacity);
$modal-close-button-size: var(--modal-close-button-size, $modal-close-button-size);
$modal-close-button-font-size: var(--modal-close-button-font-size, $modal-close-button-font-size);
$modal-close-button-shadow: var(--modal-close-button-shadow, $modal-close-button-shadow);
$modal-close-button-hover-background-color: var(--modal-close-button-hover-background-color, $modal-close-button-hover-background-color);
$modal-close-button-hover-color: var(--modal-close-button-hover-color, $modal-close-button-hover-color);
$modal-close-button-hover-opacity: var(--modal-close-button-hover-opacity, $modal-close-button-hover-opacity);

$popover-border: 1px solid #e4e4e4;
$popover-border-radius: 8px;
$popover-background-color: #f5f5f5;
$popover-body-font-family: var(--modal-body-font-family, $modal-body-font-family);
$popover-body-font-size: var(--modal-body-font-size, $modal-body-font-size);
$popover-shadow: var(--modal-body-shadow, $modal-body-shadow);
$popover-close-button-border: var(--modal-close-button-border, $modal-close-button-border);
$popover-close-button-border-radius: var(--modal-close-button-border-radius, $modal-close-button-border-radius);
$popover-close-button-background-color: #f5f5f5;
$popover-close-button-color: var(--modal-close-button-color, $modal-close-button-color);
$popover-close-button-padding: var(--modal-close-button-padding, $modal-close-button-padding);
$popover-close-button-margin: var(--modal-close-button-margin, $modal-close-button-margin);
$popover-close-button-opacity: var(--modal-close-button-opacity, $modal-close-button-opacity);
$popover-close-button-size: 1.3rem;
$popover-close-button-font-size: 0.9rem;
$popover-close-button-shadow: none;
$popover-close-button-hover-background-color: var(--modal-close-button-hover-background-color, $modal-close-button-hover-background-color);
$popover-close-button-hover-color: var(--modal-close-button-hover-color, $modal-close-button-hover-color);
$popover-close-button-hover-opacity: var(--modal-close-button-hover-opacity, $modal-close-button-hover-opacity);

$profile-image-border-radius: var(--profile-image-border-radius, $profile-image-border-radius);
$profile-image-small-size: var(--profile-image-small-size, $profile-image-small-size);
$profile-image-size: var(--profile-image-size, $profile-image-size);
$profile-image-big-size: var(--profile-image-big-size, $profile-image-big-size);
$profile-image-huge-size: var(--profile-image-huge-size, $profile-image-huge-size);
$profile-image-padding: var(--profile-image-padding, $profile-image-padding);
$profile-image-margin: var(--profile-image-margin, $profile-image-margin);

$card-background-color: var(--card-background-color, $card-background-color);
$card-color: var(--card-color, $card-color);
$card-border-radius: var(--card-border-radius, $card-border-radius);
$card-border: var(--card-border, $card-border);
$card-standout-border: var(--card-standout-border, $card-standout-border);
$card-padding: var(--card-padding, $card-padding);
$card-margin: var(--card-margin, $card-margin);
$card-font-size: var(--card-font-size, $card-font-size);

$card-title-font-family: var(--card-title-font-family, $card-title-font-family);
$card-title-font-size: var(--card-title-font-size, $card-title-font-size);
$card-title-font-weight: var(--card-title-font-weight, $card-title-font-weight);
$card-title-line-height: var(--card-title-line-height, $card-title-line-height);
$card-title-margin: var(--card-title-margin, $card-title-margin);
$card-title-padding: var(--card-title-padding, $card-title-padding);
$card-title-color: var(--card-title-color, $card-title-color);

$card-subtitle-font-family: var(--card-subtitle-font-family, $card-subtitle-font-family);
$card-subtitle-font-size: var(--card-subtitle-font-size, $card-subtitle-font-size);
$card-subtitle-font-weight: var(--card-subtitle-font-weight, $card-subtitle-font-weight);
$card-subtitle-line-height: var(--card-subtitle-line-height, $card-subtitle-line-height);
$card-subtitle-margin: var(--card-subtitle-margin, $card-subtitle-margin);
$card-subtitle-padding: var(--card-subtitle-padding, $card-subtitle-padding);
$card-subtitle-color: var(--card-subtitle-color, $card-subtitle-color);

$card-footer-background-color: var(--card-footer-background-color, $card-footer-background-color);
$card-footer-border: var(--card-footer-border, $card-footer-border);
$card-footer-border-radius: var(--card-footer-border-radius, $card-footer-border-radius);
$card-footer-padding: var(--card-footer-padding, $card-footer-padding);
$card-footer-margin: var(--card-footer-margin, $card-footer-margin);

$profile-card-background-color: var(--profile-card-background-color, $profile-card-background-color);
$profile-card-padding: var(--profile-card-padding, $profile-card-padding);
$profile-card-margin: var(--profile-card-margin, $profile-card-margin);
$profile-card-nav-link-color: var(--profile-card-nav-link-color, $profile-card-nav-link-color);
$profile-card-image-width: var(--profile-card-image-width, $profile-card-image-width);
$profile-card-image-height: var(--profile-card-image-height, $profile-card-image-height);
$profile-card-image-border-radius: var(--profile-card-image-border-radius, $profile-card-image-border-radius);
$profile-card-image-border: var(--profile-card-image-border, $profile-card-image-border);
$profile-card-image-margin: var(--profile-card-image-margin, $profile-card-image-margin);

$form-control-label-font-family: var(--form-control-label-font-family, $form-control-label-font-family);
$form-control-label-font-size: var(--form-control-label-font-size, $form-control-label-font-size);
$form-control-label-font-weight: var(--form-control-label-font-weight, $form-control-label-font-weight);
$form-control-label-line-height: var(--form-control-label-line-height, $form-control-label-line-height);
$form-control-label-margin: var(--form-control-label-margin, $form-control-label-margin);
$form-control-label-color: var(--form-control-label-color, $form-control-label-color);
$form-control-font-family: var(--form-control-font-family, $form-control-font-family);
$form-control-font-size: var(--form-control-font-size, $form-control-font-size);
$form-control-font-weight: var(--form-control-font-weight, $form-control-font-weight);
$form-control-line-height: var(--form-control-line-height, $form-control-line-height);
$form-control-color: var(--form-control-color, $form-control-color);
$form-control-background-color: var(--form-control-background-color, $form-control-background-color);
$form-control-border-color: var(--form-control-border-color, $form-control-border-color);
$form-control-border: var(--form-control-border, $form-control-border);
$form-control-border-radius: var(--form-control-border-radius, $form-control-border-radius);
$form-control-padding: var(--form-control-padding, $form-control-padding);
$form-control-margin: var(--form-control-margin, $form-control-margin);
$form-control-placeholder-color: var(--form-control-placeholder-color, $form-control-placeholder-color);
$form-control-placeholder-font-weight: var(--form-control-placeholder-font-weight, $form-control-placeholder-font-weight);
$form-control-placeholder-font-size: var(--form-control-placeholder-font-size, $form-control-placeholder-font-size);
$form-control-placeholder-line-height: var(--form-control-placeholder-line-height, $form-control-placeholder-line-height);
$form-control-switch-border-radius: var(--form-control-switch-border-radius, $form-control-switch-border-radius);
$form-control-switch-padding: var(--form-control-switch-padding, $form-control-switch-padding);

$dropdown-font-family: var(--dropdown-font-family, $dropdown-font-family);
$dropdown-background-color: var(--dropdown-background-color, $dropdown-background-color);
$dropdown-border: var(--dropdown-border, $dropdown-border);
$dropdown-border-radius: var(--dropdown-border-radius, $dropdown-border-radius);
$dropdown-padding: var(--dropdown-padding, $dropdown-padding);
$dropdown-margin: var(--dropdown-margin, $dropdown-margin);
$dropdown-font-size: var(--dropdown-font-size, $dropdown-font-size);
$dropdown-font-weight: var(--dropdown-font-weight, $dropdown-font-weight);
$dropdown-line-height: var(--dropdown-line-height, $dropdown-line-height);
$dropdown-color: var(--dropdown-color, $dropdown-color);
$dropdown-shadow: var(--dropdown-shadow, $dropdown-shadow);
$dropdown-item-font-family: var(--dropdown-item-font-family, $dropdown-item-font-family);
$dropdown-item-background-color: var(--dropdown-item-background-color, $dropdown-item-background-color);
$dropdown-item-border: var(--dropdown-item-border, $dropdown-item-border);
$dropdown-item-border-radius: var(--dropdown-item-border-radius, $dropdown-item-border-radius);
$dropdown-item-padding: var(--dropdown-item-padding, $dropdown-item-padding);
$dropdown-item-margin: var(--dropdown-item-margin, $dropdown-item-margin);
$dropdown-item-font-size: var(--dropdown-item-font-size, $dropdown-item-font-size);
$dropdown-item-font-weight: var(--dropdown-item-font-weight, $dropdown-item-font-weight);
$dropdown-item-line-height: var(--dropdown-item-line-height, $dropdown-item-line-height);
$dropdown-item-color: var(--dropdown-item-color, $dropdown-item-color);
$dropdown-item-hover-background-color: var(--dropdown-item-hover-background-color, $dropdown-item-hover-background-color);
$dropdown-item-hover-color: var(--dropdown-item-hover-color, $dropdown-item-hover-color);
$dropdown-item-hover-font-weight: var(--dropdown-item-hover-font-weight, $dropdown-item-hover-font-weight);
$dropdown-item-hover-text-decoration: var(--dropdown-item-hover-text-decoration, $dropdown-item-hover-text-decoration);
$dropdown-item-active-background-color: var(--dropdown-item-active-background-color, $dropdown-item-active-background-color);
$dropdown-item-active-color: var(--dropdown-item-active-color, $dropdown-item-active-color);
$dropdown-item-active-font-weight: var(--dropdown-item-active-font-weight, $dropdown-item-active-font-weight);
$dropdown-item-disabled-background-color: var(--dropdown-item-disabled-background-color, $dropdown-item-disabled-background-color);
$dropdown-item-disabled-color: var(--dropdown-item-disabled-color, $dropdown-item-disabled-color);
$dropdown-item-disabled-font-weight: var(--dropdown-item-disabled-font-weight, $dropdown-item-disabled-font-weight);

$table-margin: var(--table-margin, $table-margin);
$table-header-font-family: var(--table-header-font-family, $table-header-font-family);
$table-header-font-size: var(--table-header-font-size, $table-header-font-size);
$table-header-font-weight: var(--table-header-font-weight, $table-header-font-weight);
$table-header-line-height: var(--table-header-line-height, $table-header-line-height);
$table-header-color: var(--table-header-color, $table-header-color);
$table-header-padding: var(--table-header-padding, $table-header-padding);
$table-header-margin: var(--table-header-margin, $table-header-margin);
$table-header-border: var(--table-header-border, $table-header-border);
$table-header-border-radius: var(--table-header-border-radius, $table-header-border-radius);
$table-header-background-color: var(--table-header-background-color, $table-header-background-color);
$table-header-text-align: var(--table-header-text-align, $table-header-text-align);
$table-header-shadow: var(--table-header-shadow, $table-header-shadow);
$table-header-text-transform: var(--table-header-text-transform, $table-header-text-transform);
$table-row-font-family: var(--table-row-font-family, $table-row-font-family);
$table-row-font-size: var(--table-row-font-size, $table-row-font-size);
$table-row-font-weight: var(--table-row-font-weight, $table-row-font-weight);
$table-row-line-height: var(--table-row-line-height, $table-row-line-height);
$table-row-color: var(--table-row-color, $table-row-color);
$table-row-padding: var(--table-row-padding, $table-row-padding);
$table-row-margin: var(--table-row-margin, $table-row-margin);
$table-row-border: var(--table-row-border, $table-row-border);
$table-row-border-bottom: var(--table-row-border-bottom, $table-row-border-bottom);
$table-row-border-radius: var(--table-row-border-radius, $table-row-border-radius);
$table-row-background-color: var(--table-row-background-color, $table-row-background-color);
$table-row-text-align: var(--table-row-text-align, $table-row-text-align);
$table-row-shadow: var(--table-row-shadow, $table-row-shadow);
$table-row-text-transform: var(--table-row-text-transform, $table-row-text-transform);
$table-row-background-color-odd: var(--table-row-background-color-odd, $table-row-background-color-odd);
$table-row-hover-background-color: var(--table-row-hover-background-color, $table-row-hover-background-color);
$table-row-hover-color: var(--table-row-hover-color, $table-row-hover-color);

$pagination-container-margin: var(--pagination-container-margin, $pagination-container-margin);
$pagination-container-justify: var(--pagination-container-justify, $pagination-container-justify);
$pagination-height: var(--pagination-height, $pagination-height);
$pagination-font-family: var(--pagination-font-family, $pagination-font-family);
$pagination-font-size: var(--pagination-font-size, $pagination-font-size);
$pagination-font-weight: var(--pagination-font-weight, $pagination-font-weight);
$pagination-line-height: var(--pagination-line-height, $pagination-line-height);
$pagination-color: var(--pagination-color, $pagination-color);
$pagination-padding: var(--pagination-padding, $pagination-padding);
$pagination-margin: var(--pagination-margin, $pagination-margin);
$pagination-border: var(--pagination-border, $pagination-border);
$pagination-border-radius: var(--pagination-border-radius, $pagination-border-radius);
$pagination-background-color: var(--pagination-background-color, $pagination-background-color);
$pagination-text-align: var(--pagination-text-align, $pagination-text-align);
$pagination-shadow: var(--pagination-shadow, $pagination-shadow);
$pagination-text-transform: var(--pagination-text-transform, $pagination-text-transform);
$pagination-hover-background-color: var(--pagination-hover-background-color, $pagination-hover-background-color);
$pagination-hover-color: var(--pagination-hover-color, $pagination-hover-color);
$pagination-hover-text-decoration: var(--pagination-hover-text-decoration, $pagination-hover-text-decoration);
$pagination-active-background-color: var(--pagination-active-background-color, $pagination-active-background-color);
$pagination-active-color: var(--pagination-active-color, $pagination-active-color);
$pagination-active-border: var(--pagination-active-border, $pagination-active-border);
$pagination-active-shadow: var(--pagination-active-shadow, $pagination-active-shadow);
$pagination-disabled-background-color: var(--pagination-disabled-background-color, $pagination-disabled-background-color);
$pagination-disabled-color: var(--pagination-disabled-color, $pagination-disabled-color);
$pagination-disabled-border: var(--pagination-disabled-border, $pagination-disabled-border);

$tabs-font-family: var(--tabs-font-family, $tabs-font-family);
$tabs-color: var(--tabs-color, $tabs-color);
$tabs-font-size: var(--tabs-font-size, $tabs-font-size);
$tabs-font-weight: var(--tabs-font-weight, $tabs-font-weight);
$tabs-line-height: var(--tabs-line-height, $tabs-line-height);
$tabs-background-color: var(--tabs-background-color, $tabs-background-color);
$tabs-border-top: var(--tabs-border-top, $tabs-border-top);
$tabs-border-bottom: var(--tabs-border-bottom, $tabs-border-bottom);
$tabs-border-left: var(--tabs-border-left, $tabs-border-left);
$tabs-border-right: var(--tabs-border-right, $tabs-border-right);
$tabs-padding: var(--tabs-padding, $tabs-padding);
$tabs-margin: var(--tabs-margin, $tabs-margin);

$tab-background-color: var(--tab-background-color, $tab-background-color);
$tab-border-top: var(--tab-border-top, $tab-border-top);
$tab-border-right: var(--tab-border-right, $tab-border-right);
$tab-border-bottom: var(--tab-border-bottom, $tab-border-bottom);
$tab-border-left: var(--tab-border-left, $tab-border-left);
$tab-padding: var(--tab-padding, $tab-padding);
$tab-margin: var(--tab-margin, $tab-margin);
$tab-border-color: var(--tab-border-color, $tab-border-color);
$tab-active-color: var(--tab-active-color, $tab-active-color);
$tab-active-background-color: var(--tab-active-background-color, $tab-active-background-color);
$tab-active-border-color: var(--tab-active-border-color, $tab-active-border-color);
$tab-active-border-top: var(--tab-active-border-top, $tab-active-border-top);
$tab-active-border-right: var(--tab-active-border-right, $tab-active-border-right);
$tab-active-border-bottom: var(--tab-active-border-bottom, $tab-active-border-bottom);
$tab-active-border-left: var(--tab-active-border-left, $tab-active-border-left);
$tab-active-font-weight: var(--tab-active-font-weight, $tab-active-font-weight);
$tab-hover-color: var(--tab-hover-color, $tab-hover-color);
$tab-hover-background-color: var(--tab-hover-background-color, $tab-hover-background-color);
$tab-hover-border-color: var(--tab-hover-border-color, $tab-hover-border-color);
$tab-hover-border-top: var(--tab-hover-border-top, $tab-hover-border-top);
$tab-hover-border-right: var(--tab-hover-border-right, $tab-hover-border-right);
$tab-hover-border-bottom: var(--tab-hover-border-bottom, $tab-hover-border-bottom);
$tab-hover-border-left: var(--tab-hover-border-left, $tab-hover-border-left);
$tab-hover-font-weight: var(--tab-hover-font-weight, $tab-hover-font-weight);

$menu-font-family: var(--menu-font-family, $menu-font-family);
$menu-background-color: var(--menu-background-color, $menu-background-color);
$menu-width: var(--menu-width, $menu-width);
$menu-box-shadow: var(--menu-box-shadow, $menu-box-shadow);
$menu-hover-background-color: var(--menu-hover-background-color, $menu-hover-background-color);
$menu-hover-color: var(--menu-hover-color, $menu-hover-color);
$menu-active-background-color: var(--menu-active-background-color, $menu-active-background-color);
$menu-active-color: var(--menu-active-color, $menu-active-color);
$menu-active-hover-background-color: var(--menu-active-hover-background-color, $menu-active-hover-background-color);
$menu-item-background-color: var(--menu-item-background-color, $menu-item-background-color);
$menu-item-color: var(--menu-item-color, $menu-item-color);
$menu-item-border-radius: var(--menu-item-border-radius, $menu-item-border-radius);
$menu-divider-color: var(--menu-divider-color, $menu-divider-color);
$menu-scrollbar-color: var(--menu-scrollbar-color, $menu-scrollbar-color);
$menu-scrollbar-background-color: var(--menu-scrollbar-background-color, $menu-scrollbar-background-color);
$menu-scrollbar-width: var(--menu-scrollbar-width, $menu-scrollbar-width);
$menu-scrollbar-border-radius: var(--menu-scrollbar-border-radius, $menu-scrollbar-border-radius);

$date-picker-day-border-radius: var(--date-picker-day-border-radius, $date-picker-day-border-radius);

$alert-background-color: var(--alert-background-color, $alert-background-color);
$alert-font-color: var(--alert-font-color, $alert-font-color);

$pos-menu-width: var(--pos-menu-width, $pos-menu-width);
$pos-secondary-color: var(--pos-secondary-color, $pos-secondary-color);
$pos-column-color: var(--pos-column-color, $pos-column-color);
$pos-header-background-color: var(--pos-header-background-color, $pos-header-background-color);
$pos-header-font-color: var(--pos-header-font-color, $pos-header-font-color);
$pos-header-button-margin: var(--pos-header-button-margin, $pos-header-button-margin);
$pos-header-button-border: var(--pos-header-button-border, $pos-header-button-border);
$pos-header-button-hover-border: var(--pos-header-button-hover-border, $pos-header-button-hover-border);
$pos-table-header-background-color: var(--pos-table-header-background-color, $pos-table-header-background-color);
$pos-table-header-border: var(--pos-table-header-border, $pos-table-header-border);
$pos-table-header-font-size: var(--pos-table-header-font-size, $pos-table-header-font-size);
$pos-table-header-font-weight: var(--pos-table-header-font-weight, $pos-table-header-font-weight);
$pos-table-header-color: var(--pos-table-header-color, $pos-table-header-color);

$cms-drop-ready-background-color: var(--cms-drop-ready-background-color, $cms-drop-ready-background-color);
$cms-drop-background-color: var(--cms-drop-background-color, $cms-drop-background-color);

$water-mark-filter: var(--water-mark-filter, $water-mark-filter);
/**/