@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';

.pos-open-order-wrapper{
    display: flex;

    .left-side{
        width:100%;

        .each-order{
            display: flex;
            margin: 1rem;
            padding: 0;

            &:first-child{
                margin-top: 5px;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .right-side{
        .item-grid {
            display: grid;
            grid-template-columns: auto 1fr;
            column-gap: 15px;
        }

        .tabbed-span {
            display: block;
            .footnote{
                font-size: $small-font-size;
                line-height: $small-font-line-height;
                display: flex;
                margin-top: .5rem;
            }
        }
    }

}
.select-checks{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 150px;
    margin-bottom: 5px;
    label{
        vertical-align: middle;
        margin-right: 1rem;
        margin-bottom: 0;
    }
    span{
        display: flex;
        flex-direction: row;
        margin-right: 1rem;
    }
}

.open-order-pagination {
    display: flex;
    justify-content: flex-start;
    margin-top: 1rem;
    margin-left: 3rem;
}