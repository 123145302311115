@import '../../../../assets/css/scss/mixins';
@import '../../../../assets/css/scss/variables';

.profile-event-wrapper{
    select{
        @include basic-input-select;
    }
    label{
        @include basic-label;
    
    }
}
.event-display-wrapper{
    h6{
        margin-top: 1rem;
        margin-bottom: 0;
    }
    hr{
        margin-top: .5rem;
    }
    .each-event{
        .main-details{
            display: flex;
            flex-direction: column;
            p{
                display: flex;
                flex-direction: row;
                cursor: pointer;
                margin-top: 1px;
                margin-bottom: 3px;
                span{
                    min-width: 200px;
                    margin-right: 1rem;
                }
            }
            .bold{
                font-weight: 700;
            }
        }
        margin-bottom: 8px;
    }
    .event-extra-details{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .head-labels{
        @include basic-label;
        margin-top: .5rem;
    }
}