@import '../../../../../assets/css/scss/variables';
@import '../../../../../assets/css/scss/themes';

.container{
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: flex-start;
    width: 100%;
    margin-top: 2rem !important;

    button.button{
        margin: 0 !important;
        padding: 1rem !important;
        display:flex;
        flex-direction:column;
        justify-content: flex-start;
        align-items: center;
        flex: 1 1 33.33%;
    }
}

.modal{
    z-index:8072 !important;
}
.backdrop{
    z-index:8071 !important;
}