@import '../../../assets/css/scss/themes.scss';

.table-sort-desc:after,
.table-sort-asc:after,
.table-sort-none:after{
    font-family: 'Font Awesome 5 Pro';
    font-weight:600;
    content: '\f0dd';
    margin-left:.5rem;
}
.table-sort-asc:after{
    content: '\f0de';
}
.table-sort-none:after{
    content: '\f0dc';
}

.material .btn{
    margin-left:$main-padding;
    white-space: nowrap;
    i.far{
        margin-right:0.5rem !important;
    }
}

.material form{
    width:100%;
}

.material .form-group{
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    width: 100%;

    label{
        width:fit-content;
        margin-right: 1rem;
    }
}

.material .form-control{
    width:100% !important;
}

.material + .table-responsive .table{
    margin-top: calc($main-padding * 0.5);
}