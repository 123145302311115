@import '../../../../assets/css/scss/variables';

.addons-modal {

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .modal-body {
        padding: 0;
    }

    .variant_container {
        box-shadow: none;
    }

    .site-row {
        width: 100%;
        height: 100%;
        flex-wrap: nowrap;
        align-items: stretch;

        & > *:first-child {
            flex: 1 1 70%;
            align-self: stretch;
        }

        & > *:nth-child(2) {
            flex: 1 1 30%;
            align-self: stretch;
        }

    }

    .no-addon-products{
        display: flex;
        justify-content: center;
        font-weight: 600;
    }

}