@import '../../../../../../../../assets/css/scss/variables';
@import '../../../../../../../../assets/css/scss/themes';

.container{
    display:flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    i.icon{
        font-size: $big-font-size;
        margin-right: 0.5rem;
    }

    .text{
        font-weight: $bold-font-weight;
    }
}