.info-container{
    display: flex !important;
    flex-direction: column !important;
    border: 1px solid rgba(0,0,0,.125) !important;
    margin-bottom: .5rem;
    padding: .5rem 1rem !important;
}
.info-container > div:nth-child(2){
    width: 100%;
    text-align: left;
}