@import '../../../../../../assets/css/scss/variables';
@import '../../../../../../assets/css/scss/themes';

.container{

    .item-container{
        display: flex;
        flex-direction: column;
        height:100%;
    }

    .totals-container{
        position: relative;
    }
}

.print-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height:150px;
}