@import '../../../../assets/css/scss/variables';

.simple-refund{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    button{
        max-width: 200px;
    }
}