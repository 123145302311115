@import '../../assets/css/scss/themes';

.home {

    .placeholder{
        background-size:contain;
        background-position: center;
        background-repeat:no-repeat;
        height:600px;
        width:100%;
    }

    .home-card {
        margin-bottom: 2rem;
    }

    .prof-bg {
        padding: 0.75rem 1.25rem;
        @media (min-width: 525px){
            margin-left: .5rem;
            margin-right: .5rem; 
        }
    }
}

.equ-height {
    float: none;
    display: grid;
    vertical-align: top;
    
    // padding:0 !important;
}
@media (max-width: 525px){
    .row>.equ-height{
        padding-left: 0;
        padding-right: 0;
    }   
}

footer{
  display:block;
}

.home{
    @media (max-width: 500px) {
        padding: 1px;
    }
}
    
@media (max-width: 600px) {
    .main-content {
        padding: 0;
    }

    a.card, div.card, .prof-bg{
        margin-right: 0 !important;
    }

    .waiver-home-card.card{
        margin-bottom:0;
    }
}