@import '../../assets/css/scss/variables';


.toast-wrapper {
    position: fixed;
    left: 0;
    z-index: 9999;
    width: 100vw;
    transition: top 1s cubic-bezier(0.17, 0.04, 0.03, 0.94);
    top: -250px;
}

.toast-wrapper.show {
    top: 0;
}

.toast {
    max-width: 100vw !important;
    text-align: center;
    width: 100%;
    color: $company-white;
    background-color: $company-toast-background !important;
}