@import '../../../../../../assets/css/scss/variables';
@import '../../../../../../assets/css/scss/themes';
.wrapper{
    display:flex;
    flex-direction:column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    margin: 0 !important;
    padding: 1rem !important; 
    
    button:global(.btn){
        flex: 1;
        margin: 0 !important;
        padding: 1rem !important;
        width: 100%;
    }

    hr{
        width:100%;
    }
}

.option-wrapper{
    background: $background-color !important;
    margin-bottom: 1rem;
    padding: 1rem !important;
    width: 100%;
}