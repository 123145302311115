@import '../../../assets/css/scss/variables';

.notification-settings-wrapper{
    .card{
        legend{
            width: auto;
        }
        .notification-type{
            width: 150px;
        }
        span{
            padding: 0 5px 0 5px;
        }
        p, form{
            display: flex;
        }
    }
}
.update-channel-form{
    display: flex;
    flex-direction: column;
    button{
        width:fit-content;
        margin-top: 10px;
    }
}