.btn-barcode{
    margin:0 !important;
}
.btn-barcode .far{
    color:inherit !important;
    margin:0 !important;
}

.barcode-scan video{
    border:3px solid;
    border-radius:20px;
    object-fit: fill;
}