@import "../../../../../assets/css/scss/themes.scss";

.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: $main-padding auto;
}

div.gc-wrapper{
    padding-left: 0;
    padding-right: 0;
    text-align: left;

    :global(.card-body){
        padding: 0;
        line-height: $primary-font-size;
    }

    .toolbar{
        display:flex;
        flex-direction: row;
        justify-content: flex-end;

        a {                    
            font-family: $secondary-font-family;
            font-size: $small-font-size;
            font-weight: 400;
        }
    }    
}