@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';
@import '../../../assets/css/scss/mixins';

.feature-wrapper{
    .feature-headings{
        @include flex-row-space-between;
        @media (max-width: 900px){
            @include flex-column-center;
        }
    }
    .each-feature{
        margin-left: 2rem;
        @media (max-width: 1200px ){
            margin-left: 1rem;
        }
        .btn-row{
            @include basic-flex-row;
            span{
                width: 200px;
            }
            @media(max-width: 650px){
                @include basic-flex-column;
            }
        }
        .edit-btn{
            width: 125px;
            margin-right: 1rem;
            max-height: 34px;
            @media (max-width: 572px){
                max-height: 68px;
            }
        }
    }
    .feature-modules{
        width: 275px;
        li{
            font-weight: 700;
            font-size: 1.1rem;
            padding: 2px;
        }
    }
    .feature-variants{
        width: 275px;
        li{
            font-size: 1.1rem;
        }
    }
    .feature-modules, .feature-variants{
        @media (max-width: 1200px){
            width: 200px;
        }
        @media (max-width: 650px){
            width: 300px;
        }
    }
    .module-feature-lists{
        margin-left: 350px;
        max-width: 500px;
    }
    .opacity{
        opacity: 0.5;
    }
}
.feature-create-edit-wrapper{
    .description{
        height: 40px;
        resize: none;
        height: 150px;
    }
    .input-col{
        @include basic-flex-column;
    }
    label{
        @include basic-label;
        margin-top: .5rem;
    }
    input, select, textarea{
        @include basic-input-select;
        width: 450px;
        @media (max-width: 550px){
            max-width: 300px;   
        }
    }
    .btn-row{
        @include flex-row-space-between;
        margin-top: 1rem;
    }
    .rbt.has-aux{
        width: 480px;
        @media (max-width: 550px){
            max-width: 320px;   
        }
    }
}
.product-modal{
    max-width: 90vw !important;
    .modal-body{
        overflow-x: scroll;
    }
    .new-product-wrapper{
        .card{
            padding: 0;
            margin: 0;
        }
    }
    @media (max-width: 575px){
        max-width: 99vw !important;
        .modal-body{
            padding: 0;
        }
    }
}
.sort-features-wrapper{
    .heading-row{
        @include flex-row-space-between;
        @media (max-width: 400px){
            @include flex-column-center;
        }
    }
    .company-select{
        margin-bottom: 2rem;
        width: 400px;
        @media (max-width: 400px){
            width: 250px;
        }
    }
}