$primary: #662d91;
$secondary: #f57f17;
$default: #f44336;

$color: var(--color);

.overlay{
    &.primary{
        --color: #{$primary};
    }
    &.secondary{
        --color: #{$secondary};
    }
    &.default{
        --color: #{$default};
    }
}

.overlay {
    position: relative;
    display: none;
    flex-direction: column;
    justify-content: center;
    z-index: 99997;

    ul {
        position: absolute;
        list-style: none !important;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        background-color: $color;
        //transform: translateY(-25%);

        li {
            color: #fff;
            padding: 0.25rem;
            margin: 0;
            cursor: pointer;

            i{
                display: block;
                width: 1rem;
                height: 1rem;
                font-style: normal;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 600;
                font-size: 0.85rem; 
            }

            &:first-child::before{
                content: "";
                display: block;
                position: absolute;
                width: 0;
                height: 0;
            }

            &:last-child::after{
                content: "";
                display: block;
                position: absolute;
                width: 0;
                height: 0;
            }
        }

        .delete:after{
            content: "\f00d";
            cursor: pointer;
        }

        .move:after{
            content: "\f58d";
            cursor: move;
        }

        .add::after{
            content: "\f067";
            cursor: pointer;
        }

        .duplicate::after{
            content: "\f24d";
            cursor: pointer;
        }

    }

    &.horizontal{
        position: relative;
        z-index: 999999;
        //display: none;
        flex-direction: row;
        justify-content: center;
        top:-1.25rem;

        &.center{
            left: calc(50% - 0.5rem);
        }

        &.left{
            left: 0.5rem;
        }

        &.right{
            left: calc(100% - 2.7rem);
        }

        ul {
            top: 0;
            left: 0;
            flex-direction: row;
    
            li {
                padding-bottom: 0;

                i:before,i:after{
                    position:absolute;
                    top: 0;
                }

                &:first-child::before{
                    top: 0;
                    left: -0.5rem;
                    border-top: 1.25rem solid transparent;
                    border-right: 0.5rem solid $color;
                }

                &:last-child::after{
                    top: 0;
                    right: -0.5rem;
                    border-top: 1.25rem solid transparent;
                    border-left: 0.5rem solid $color;
                }
            }
        }

        &.bottom:not(.inset){
            top: 100%; 

            ul {
                li {

                    &:first-child::before{
                        top: 0;
                        left: -0.5rem;
                        border-top: 1.25rem solid $color;
                        border-left: 0.5rem solid transparent;
                        border-right: 0;
                    }
    
                    &:last-child::after{
                        top: 0;
                        right: -0.5rem;
                        border-top: 1.25rem solid $color;
                        border-right: 0.5rem solid transparent;
                        border-left: 0;
                        
                    }
                }
            }            
        }

        &.inset:not(.bottom){
            top: 0;

            ul {
                li {

                    &:first-child::before{
                        top: 0;
                        left: -0.5rem;
                        border-top: 1.25rem solid $color;
                        border-left: 0.5rem solid transparent;
                        border-right: 0;
                    }
    
                    &:last-child::after{
                        top: 0;
                        right: -0.5rem;
                        border-top: 1.25rem solid $color;
                        border-right: 0.5rem solid transparent;
                        border-left: 0;
                        
                    }
                }
            }
        }
        
        &.inset.bottom{
            top: calc(100% - 1.25rem);
        }
    }

    &.vertical{
        position: relative;
        z-index: 999;
        display: none;
        flex-direction: column;
        justify-content: center;
        top:0.5rem;

        ul {
            top: 0;
            left: 0;
            flex-direction: column;
    
            li {
                padding-right: 0.1rem;
                padding-left: 0.1rem;
                text-align: center;

                &:first-child::before{
                    top: -0.5rem;
                    left: 0;
                    border-left: 1.2rem solid transparent;
                    border-bottom: 0.5rem solid $color;
                }
                &:last-child::after{
                    bottom: -0.5rem;
                    left: 0;
                    border-left: 1.2rem solid transparent;
                    border-top: 0.5rem solid $color;
                }
            }
        }
    }
}