@import '../../../assets/css/scss/mixins';
@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';

.order-page-wrapper{
    h5{
        i{
            margin-right: 8px;
        }
    }
    p{
        margin-bottom: 0;
    }
    div{
        margin-bottom: 0;
    }
    .order-hr{
        border-top-color: 1px solid $primary-color !important;
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    }
    .order-head{
        margin-bottom: $main-padding;
        .section-title{
            font-family: $secondary-font-family;
            margin-bottom: 0;
        }
        .btn .btn-primary button{
            margin-bottom: 0 !important;
        }
    }
    .split-order-link{
        color: $link-color;
        padding-left: 3px;
    }
}
.order-transactions-details{
    display: flex;
    flex-direction: row;
    div{
        min-width: 300px;
        margin-right: 10px;
    }
    @media (max-width: 900px){
        flex-direction: column;
    }
}
.order-other-details-wrapper{
    .detail-pair{
        display: flex;
        flex-direction: row;
        align-items: center;
        span:first-child{
            min-width: 110px;
            font-weight: $bold-font-weight;
            font-family: $secondary-font-family;
            line-height: $secondary-line-height;
        }
        span:last-child{
            max-width: 300px;
            width: 300px;
        }
    }
}
.order-status-wrapper{
    .outlined{
        display: flex;
        justify-content: center;
        width: 150px;
        border: 1px solid $tertiary-color;
        padding: 3px 5px;
        border-radius: 5px;
        margin-right: 5px;
        cursor: pointer;
    }
    .status-notes{
        margin-top: 5px;
    }
    .status-map{
        display: flex;
        flex-wrap: wrap;
    }
}
.order-coupons-wrapper{
    .coupon-pair{
        display: flex;
        flex-direction: row;
        align-items: center;
        span:first-child{
            min-width: 150px;
            font-weight: $bold-font-weight;
            font-family: $secondary-font-family;
            line-height: $secondary-line-height;
        }
    }
}
.order-price-details{
    .price-pair{
        display: flex;
        flex-direction: row;
        align-items: center;
        span:first-child{
            min-width: 130px;
            max-width: 200px;
            font-weight: $bold-font-weight;
            font-family: $secondary-font-family;
            line-height: $secondary-line-height;
        }
        span:last-child{
            min-width: 50px;
            max-width: 300px;
            font-family: $primary-font-family;
            line-height: $primary-line-height;
        }
    }    
}

.transaction-hist-wrapper{
    .transaction-pair{
        display: flex;
        flex-direction: row;
        align-items: center;
        span:first-child{
            min-width: 130px;
            font-weight: $bold-font-weight;
            font-family: $secondary-font-family;
            line-height: $secondary-line-height;
        }
        span:last-child{
            min-width: 50px;
            font-family: $primary-font-family;
            line-height: $primary-line-height;
        }
    }

    .transaction-pair-selectable{
        cursor: pointer;
        padding: 4px 0;
        
        &:hover,
        ~.show{
            background-color: $table-row-hover-background-color;
            color: $table-row-hover-color;
        }

        ~.show{
            .selected-transaction{
                padding: 0.5rem 1rem;
            }    
        }
    }
}