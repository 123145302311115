@import '../../../../assets/css/scss/themes.scss';

.modal{
    z-index:8062 !important;
}
.backdrop{
    z-index:8061 !important;
}

.title{
    display: block;
    font-family: $secondary-font-family;
    font-weight: $bold-font-weight;
    font-size: 1.4rem;
    margin:0;
    margin-bottom: 0.75rem;
    text-align: left;
}

.subtitle{
    display: block;
    font-family: $secondary-font-family;
    font-size: 0.8rem;
    margin:0;
    margin-bottom: 1rem;
    transform: translateY(-0.5rem);
}

.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0;
    margin-bottom: 1rem;

    hr {
        margin-bottom: 2rem;
    }
}

.item{
    cursor: pointer;
    background-color: $table-header-background-color;
    border-bottom: $table-header-border;

    &.active{
        border-bottom-color: transparent;
    }

    .item-check{
        cursor: pointer;
        position:absolute;
        top: 50%;
        left: 0.5rem;
        font-size: 2rem;
        font-weight: 300;
        width:3rem;
        transform: translateY(-50%);
    }
}

.info-pair{
    display: flex;
    flex-direction: row;
    align-items: center;
    
    > span:first-child{
        min-width: 110px;
        font-weight: $bold-font-weight;
        font-family: $secondary-font-family;
        line-height: $secondary-line-height;
        display: flex;
        align-items: center;

    }

    > div:first-child{
        min-width: 125px;
        font-weight: $regular-font-weight;
        font-family: $primary-font-family;
        line-height: $primary-line-height;
    }

    > span:last-child,
    > div:last-child{
        min-width: 50px;
        font-family: $primary-font-family;
        line-height: $primary-line-height;

        :global(.form-check){
            margin: 0;
            min-height: $primary-line-height;
        }
    }
}

.colspan{
    max-width:200px;
}