@import '../../../assets/css/scss/mixins';
@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes.scss';

.image-viewer-wrapper{
    .large-image{
        img{
            object-fit: contain;
        }
    }
    .img-thumbs{
        margin-top: 10px;
        img{
            padding: 3px;
            &.selected{
                padding: 0px;
                border: solid $primary-color 3px;
            }
        }
    }
}