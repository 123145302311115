@import '../../../../../../../assets/css/scss/variables';
@import '../../../../../../../assets/css/scss/themes';


.item{
    padding-bottom: $main-padding;
    padding-top: $main-padding;

    &:not(:last-child){
        border-bottom: $divider-border;
    }

    p {
        margin-bottom: 0.25rem;
    }

    .image{
        width: 100%;
        height: 100%;
        max-width: 100px;
        max-height: 100px;
        object-fit: contain;
    }
    
    .btn-qty{
        margin-right: auto;
        margin-top: 0.25rem;

        > button{
            font-size: $small-font-size;
            text-transform: none;
        }
    }

    .title{
        font-family: $secondary-font-family;
        font-weight: $bold-font-weight;
        font-size: $big-font-size;
    }

    .details{
        font-family: $secondary-font-family;
        font-size: $small-font-size;
        line-height: $small-font-line-height;

        .bold{
            font-weight: $bold-font-weight;
        }
    }

    .toolbar{
        display:flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        /*padding-top: calc($main-padding / 2);*/

        a {                    
            font-family: $secondary-font-family;
            font-size: $small-font-size;
            font-weight: 400;
        }
    }
}