@import '../../../../../../../assets/css/scss/variables';
@import '../../../../../../../assets/css/scss/themes';

.member-wrapper{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    border: $divider-border;
    background-color: $neutral-background-color !important;
    color: $primary-font-color;
    box-shadow: $button-shadow !important;
    margin: $button-margin;
    padding:0 !important;
    transition: all 0.2s ease-in-out;
    font-family: $button-font-family;
    font-size: $button-font-size;    

    .body{
        padding:0 $main-padding;

        >div{
            padding: $main-padding 0;
        }
    }

    .header{
        width:100%;
        padding: $main-padding;
        display:flex;
        align-items: center;
        justify-content: flex-start;
        transition: all 0.2s ease-in-out;

        .check{
            color: $primary-inverse-color;
            margin:0;
            padding:0 !important;
            box-shadow: none !important;
            margin-right: calc($main-padding / 2);
            display:none;

            &.selected{
                display:block;
            }

            i{
                font-size: 2rem;
            }
        }
    }

    &.selected{
        .header{
            background-color: $primary-color !important;
            color: $primary-inverse-color;
        }
    }

    &.disabled{
        color: $disabled-color;
        background-color: transparent !important;
        cursor: default;
        box-shadow: none !important;

        >.msg{
            color: $primary-font-color;
        }
    }
}