@import '../../assets/css/scss/themes';

.transparent-dropzone{
    position:relative;
    outline:none;
    cursor:pointer;
    width: 100%;
  }
  
  .transparent-dropzone .progress{
    height:2px;
    background-color:transparent;
    cursor:pointer;
  }
  
  .transparent-dropzone .progress{
    height:2px;
    background-color:transparent;
    cursor:pointer;
  }
  
  .transparent-dropzone{
    outline:none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .transparent-dropzone.dnd-active{
    opacity:.7;
  }  

  .transparent-dropzone.dnd-reject:after{
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background-color:rgba(229, 57, 53,.3);
      content:'';
  }  

  .transparent-dropzone.dnd-accept:after{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(67, 160, 71,.3);
    content:'';
}  
