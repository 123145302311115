@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes.scss';
@import '../../../assets/css/scss/mixins';

.pos-reports{
    @include flex-column-center;
    .today-yes-btns{
        @include flex-column-center;
        .form-radio.form-check{
            width: 150px;
            height: 40px;
            &:first-child{
                margin-right: 1.5rem;
            }
        }
    }
    .select-type{
        @include flex-column-center;
    }
    div{
        margin-bottom: .5rem;
    }
}