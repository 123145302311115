@import '../../../assets/css/scss/themes';



ul.pagination {
    margin: $pagination-container-margin;
    justify-content: $pagination-container-justify;
    max-height: $pagination-height;

    li.page-item{

        .page-link {
            font-family: $pagination-font-family;
            font-size: $pagination-font-size;
            font-weight: $pagination-font-weight;
            line-height: $pagination-line-height;
            color: $pagination-color;
            padding: $pagination-padding;
            margin: $pagination-margin;
            border: $pagination-border;
            border-radius: $pagination-border-radius;
            background-color: $pagination-background-color;
            text-align: $pagination-text-align;
            box-shadow: $pagination-shadow;
            text-transform: $pagination-text-transform;

            &:hover {
                background-color: $pagination-hover-background-color;
                color: $pagination-hover-color;
                text-decoration: $pagination-hover-text-decoration;
            }

            &.disabled {
                background-color: $pagination-disabled-background-color;
                color: $pagination-disabled-color;
                border: $pagination-disabled-border;
            }
        }

        &.active {
            .page-link{
                background-color: $pagination-active-background-color;
                color: $pagination-active-color;
                border: $pagination-active-border;
                box-shadow: $pagination-active-shadow;
            }
        }

        &:first-of-type,
        &:last-of-type{
            .page-link{
                
                span:first-child{
                    position:relative;
                    color:transparent !important;

                    &:after{
                        font-family: 'Font Awesome 5 Pro';
                        color: $pagination-color;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

            }
        }
        
        &:first-of-type{
            .page-link{
                span:first-child{
                    &:after{
                        content: "\f053";
                    }
                }
            }
        }

        &:last-of-type{
            .page-link{
                span:first-child{
                    &:after{
                        content: "\f054";
                    }
                }
            }
        }

    }
}
