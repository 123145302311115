@import '../../../../assets/css/scss/variables';
@import '../../../../assets/css/scss/themes';
@import '../../../../assets/css/scss/mixins';

.split-payment-modal {
    .modal-content {
        overflow-y: auto;
        height: 90%;
    }
}

.split-payment-modal.modal-dialog {
    height: 98%;
}

.sp-error {
    font-size: $primary-font-size;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $error-color;
    margin-top: 5px;
    p {
        padding: 0;
        margin: 0;
    }
}

.sp-success {
    font-size: $primary-font-size;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $success-color;
    margin-top: 5px;
    p {
        padding: 0;
        margin: 0;
    }
}

.sp-cash-fs {
    justify-content: center;

    legend {
        width: auto;
    }

    button.btn {
        margin-bottom: 1rem;
    }
}

.split-payment-order-summary-page {

    div {
        margin-left: 2rem;
    }

    p {
        font-size: $primary-font-size;

        &.sp-os-p1 {
            margin: 0 0 0 1rem;
            padding: 0;
        }

        &.sp-os-p2 {
            margin: 0 0 0 2.5rem;
        }

        &.sp-os-item {
            margin: .25rem 5rem .25rem 0rem;
            display: flex;
            justify-content: space-evenly;

            span {
                margin-left: .75rem;
            }
        }
    }
}

.sp-memo {
    margin-bottom: .5rem;
    padding: 5px;
}

.sp-address-wrapper {
    .form-row{
        margin-left: .5rem;
    }
    .fields {
        flex-wrap: wrap;
        flex-direction: row;
        display: flex;
    }
    .cc-fields {
        font-size: .75rem;
        display: flex;
        flex-wrap: wrap;
        span {
            color: $error-color;
            margin-right: 1rem;
            margin-left: .5rem;
        }
        div {
            width: 39%;
        }
        div,
        span {
            flex-grow: 2 1 0;
        }
        .cc-sp-zip {
            margin: 0;
            margin-bottom: 1rem;
            // padding-bottom: 0;
            margin-top: 0;
            padding: 8px;
            font-size:1rem;
        }
    }
    .cc-sp-req {
        color: $error-color
    }
}

.multi-user-single-sp-wrapper,
.multi-user-multi-sp-wrapper {
    button {
        border-radius: 8px;
        font-size: 1rem;

        &:focus:not(:focus-visible) {
            border-color: $button-hover-background-color;
            outline: 0;
            box-shadow: $shadow-elevation-1;
        }

        &:active {
            border-color: $button-active-background-color;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
        }
    }

    input,
    select {
        @include basic-input-select;
    }

    .u-typeahead-wrapper {
        width: 50%;
        margin: 1rem auto;
        border-radius: 8px;
        font-size: 1rem;
    }

    .assign-user-div {

        .assign-inputs {
            display: flex;
            align-items: center;
        }

        legend {
            font-size: 1rem;
        }
    }

    .sp-each-payment {

        span {
            margin-right: 1rem;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }
    }

    .order-division-div {

        .btn-group {
            margin-left: .5rem;
            margin-left: .5rem;
        }

        .btn-group>.btn {
            background-color: $neutral-background-color;
            border-radius: 8px;
            margin: .5rem;
        }

        .btn-group>.btn.active {
            background-color: $button-active-background-color;
            font-size: 1.25rem;
            box-shadow: 0 0 1rem 0.05rem rgba(0, 0, 0, 0.374);
        }

        .btn-group>.btn:not(:last-child) {
            border-radius: 8px;
        }
        input[type="radio"]{
            opacity: 0;
            position: fixed;
            width: 0;
        }
    }
}

.split-payment-single-cash {
    margin-left: 5rem;
    margin-right: 5rem;

    .sp-cash-form {
        display: flex;
        margin: 0 4rem 0 4rem;
        justify-content: center;

        button {
            margin: .25rem .25rem .25rem .25rem;
        }

        .sp-cash-curreny {
            width: 200px;
            justify-content: center;
        }
    }

    .sp-cash-sub-btn {
        margin: 0 50% 0 0;
    }
}

.split-payment-single-cc {

    .sp-cc-form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    input,
    select {
        width: 400px;
        margin-bottom: .5rem;
    }

    button {
        margin-top: 1rem;
    }
}

.sp-type {
    display: flex;
    justify-content: center;

    & .card {
        display: flex;
        flex-direction: column;
        width: 30%;
        justify-content: space-around;
        margin: .5rem;
    }
}

.sp-center-text{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

