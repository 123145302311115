@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';

div.popover.sb-popover {
    padding: 10px 10px;
    border: $popover-border;
    border-radius: $popover-border-radius;
    background-color: $popover-background-color;
    box-shadow: $popover-shadow;

    .popover-close-button {
        position: absolute;
        right: 6px;
        border: $popover-close-button-border;
        border-radius: $popover-close-button-border-radius;
        background-color: $popover-close-button-background-color;
        color: $popover-close-button-color;
        padding: $popover-close-button-padding;
        margin: $popover-close-button-margin;
        opacity: $popover-close-button-opacity;
        width: $popover-close-button-size;
        height: $popover-close-button-size;
        box-shadow: $popover-close-button-shadow;
        display: flex;
        align-items: center;
        justify-content: center;
    
        &:hover{
            background-color: $popover-close-button-hover-background-color;
            color: $popover-close-button-hover-color;
            opacity: $popover-close-button-hover-opacity !important;
        }
    
        i {
            font-size: $popover-close-button-font-size;
        }
    
    }

    .popover-body {
        padding: 0px;
        margin: 0px;
    }
}

