@import '../../../../../../../../assets/css/scss/variables';
@import '../../../../../../../../assets/css/scss/themes';

div.wrapper{
    width: 100% !important;
    padding: $card-padding !important;
    text-transform: inherit !important;
    box-shadow: none !important;
    font-weight: inherit !important;

    div {
        display:flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;        
    }
}