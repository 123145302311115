.table td ul{
    list-style: outside;
    list-style-type: circle;
}

.family-toggle-btn{
    /* height: 25px; */
    font-size: 12px;
    vertical-align: baseline;
}
