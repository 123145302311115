@import '../../../../../assets/css/scss/themes.scss';

.wrapper{
    border-right: $divider-border;
    height: 100%;
    width: 100%;

    &.active{
        border-right: 0;
    }
}