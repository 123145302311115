@import '../../../assets/css/scss/mixins';
@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes.scss';

.hours-btn{
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: $card-background-color;
    border-radius: 50%;
    font-size: 1.5rem;
    z-index: 2;
}