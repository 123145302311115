@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';



.order-summary-wrapper{
    display: flex;
    flex-direction: column;
    background-color: $background-color;
    font-family: $secondary-font-family;
    padding: 1.2rem;
    line-height: $big-font-line-height;
    justify-content: flex-start;
    transition: all 0.15s ease-in-out;

    .total{
        span {
            font-weight: $bold-font-weight;
            text-transform: uppercase;
            font-size: calc($modal-body-font-size * 1.2);
        }
        span:nth-of-type(1){
            padding-right: 1.5rem;
        }
        span:nth-of-type(2){
            font-size: calc($modal-body-font-size * 1.6);
        }
    }

    span:nth-of-type(2){
        font-weight: $bold-font-weight;
    }
}


.payment-total-grid {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 6px;
    width: 100%;

    span:nth-of-type(2){
        font-size: calc($modal-body-font-size * 1.1);
    }
}

.grid-row-wrapper {
    display: contents;
}

.break-above {
    & > * {
        margin-top: 15px;
    }
}

.enter-card-wrapper {

    .card-header {
        margin-bottom: 0.75rem;
    }

    p {
        background-color: $secondary-light-color;
        padding: 1rem;
        margin: 0;
        cursor:pointer;
    }

    .card-body {
        padding: 0;
    }
}

button.btn.btn-complete{
    font-weight: $bold-font-weight !important;
    font-size: $big-font-size !important;
    line-height: $big-font-line-height !important;
    padding: 1rem !important;
    width: 100%;
    text-align: center;
    justify-content: center;
}

div.form-group {
    margin-bottom: 0.75rem;
}

.tip-wrapper,
.payment-wrapper,
.cash-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;
    align-items: center;
    gap: 15px;
    
    .btn{
        margin: 0 !important;
    }

    .prepend-arrow {
        margin-top: 12px;
        margin-right: 12px;
    }

    .dollar-input {
        width: 110px;
    }
}

.cash-wrapper {
    flex-wrap: wrap;
    .input-group {
        align-items: stretch;
        width: auto;
    }
}

.tip-wrappper {
    .input-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
}

.payment-wrapper {
    .btn {
        flex: 1 1 auto;
    }
}

.modal-dialog.payment {
    max-width: 600px !important;
}

.total-tile{
    font-family: $secondary-font-family;
    background:$background-color;
    color:$primary-color;
    padding:0;
    
    .card-body{
        padding:1rem;
        text-align:right;
    }
}

.total{
    text-align:right;
}
.totals h1{
    font-size: $big-font-size;
    font-weight: $bold-font-weight;
}

.btn-tip{
    z-index:9999;
}

.btn-tip > button{
    padding: .4rem .75rem !important;
    margin-right: unset !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.no-customer-warning div {
    display: flex;
    justify-content: center;
}

.no-customer-warning .btn {
    margin: 1rem 0 0 0;
}

.CollectJSBounceIn, .CollectJSBounceOut {
    height: 210px !important;
}

.summary-box {
    border: 1px solid #ced4da;
    border-radius: .25rem;
    padding: 5px;
    margin-left: 10px;
    max-height: 118px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    margin-bottom: 1rem;
}

.summary-product-name {
    text-align: flex-start !important;
}

.summary-box > .row {
    border-bottom: 1px solid #ced4da;
}

.summary-box > .row:last-of-type {
    border: none;
}

.summary-product-price {
    text-align: right;
    padding: 0 15px 0 0 !important;
}