@import '../../assets/css/scss/variables';

.orders-modal{
    max-width: 90%;
}
.orders-modal .modal-body{
    /*min-height: 700px;
    height: 91vh;*/
    overflow-x: scroll;
    overflow-x: scroll;
}
.orders-modal.modal-content{
    min-width: 925px;
}

@media only screen and (min-width: 700px) and (max-width: 1000px){
    .orders-modal.modal-dialog{
        min-width: 720px;
        margin: 10px auto 10px auto;
    }   
}
@media only screen and (max-width: 699px){
    .orders-modal .modal-dialog{
        min-width: 500px;
        margin: auto auto;
    }   
}
@media screen and (min-width: 1700px){
    .orders-modal{
        max-width: 1300px;
    }
}