
@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';

//moved the styles for checkin button here that were on profile.scss 
//I think that version of the profile (and it's classes) are ones that aren't used anymore

.user-profile-container {
    margin-top: 1rem;
    @media (max-width: 420px){
        margin-top: .25rem;
        .row{
            margin: 0 -10px 0 -10px;
        }
        .card{
            padding: 10px;
        }
    }

    .profile-card-col{
        max-width: 350px;
        font-size: $card-font-size;

        .card {
            padding: 1.5rem;
        }
    }

    .main-profile-body{
        display: flex;
    }

    .user-profile-img-wallet-card {
        background-color: $card-background-color;
        padding: $card-padding;
        border-radius: $card-border-radius;
        &.card .card-title {
            font-family: $card-title-font-family;
            font-size: $card-title-font-size;
        }
    }

    .profile-each-sub-wrapper{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        max-width: 275px;
    }

    .profile-quick-glance-info{
        display: flex;
        flex-direction: column;
    }

    .profileMenu a, .profile-btn-link {
        color: $profile-card-nav-link-color;
    }

    .profileMenu {
        
        .list-group-item {
            font-size: 0.9rem;
            font-weight: 400;
            padding-left: 0 !important;
            border-left: 4px solid $card-background-color !important;
            display: flex;
            flex-direction: row;
            
        }

        .list-group-item-action {
            cursor: pointer;
            width: auto;
        }

        .list-group-item.active {
            border-left: 4px solid $primary-color !important;
            @media (max-width: 992px){
                border-left: none !important;
                border-bottom: 4px solid $primary-color !important;
            }
        }

        .li-text {
            display: inline-block;
        }

        .li-icon {
            width: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
        }
    }
    h4.section-title{
        font-family: $secondary-font-family;
        @media (max-width: 420px){
            text-align: center;
            margin-top: .5rem;
        }
    }

    .qr-card{
        .qr-code-icon {
            margin-bottom: 0;
        }
    }
}
@media screen and (max-width: 1200px) {
    .user-profile-container {
        .profileMenu{
            .li-text{
                display:none;
            }
        }
    }
}
@media screen and (max-width: 1200px) {
    .user-profile-container {
        .profile-quick-glance-info{
            flex-direction: row;
            justify-content: space-between;
        }
        .profile-each-sub-wrapper{
            flex-direction: column;
        }
    }
    .user-profile-img-wallet-card{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
    }
    .profile-card-col{
        min-width: 100%;
    }
    .profile-checkin-wrapper{
        display: flex;
        flex-direction: column;
        .checked-in-today{
            display: flex;
            flex-direction: column;
            font-size: $small-font-size;
            line-height: $primary-font-size;
        }
    }
}
@media screen and (max-width: 991px) {
    .user-profile-container {
        .main-profile-body{
            flex-direction: column-reverse;
        }
        .profile-quick-glance-info{
            flex-direction: column;
        }
    }
    .user-profile-img-wallet-card{
        margin-top: 1rem;
    }
}
@media screen and (min-width: 992px) and (max-width: 1200px){
    .main-profile-body{
        flex-direction: column;
    }
}