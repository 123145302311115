@import "../../../../assets/css/scss/variables";
@import "../../../../assets/css/scss/themes";


.reg-event-cards {

    &.rel-row {
        overflow-x: hidden;
        overflow-y: hidden;
        display: flex;
        flex-wrap: nowrap;
    }

    @media (min-width: 526px){
        &.rel-row:last-child {
            margin-right: 3rem;
        }
    }

    .reg-events-div{
        display: flex;
        flex-direction: row;
        .card-body, .card-text{
            padding: 5px;
            text-align: center;
        }
    }

    .card#rel-first-card {
        height: 16rem;
        border-radius: $card-border-radius;
        box-shadow: $shadow-elevation-1;
        padding: 0;
        margin:0;
        cursor: pointer;
        margin-right: .5rem;
    }

    .card#rel-first-card:before {
        content: "";
        background-image: var(--backgroundEvents-url);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: absolute;
        background-color: #000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0px;
        border-radius: $card-border-radius;
        z-index: 1;
    }

    #rel-first-card-text {
        position: absolute;
        z-index: 2;
        color: #f5f5f5;
        text-shadow: 0 0 2px $shadow-color, 0 0 2px $shadow-color, 0 0 2px $shadow-color, 0 0 2px $shadow-color;
        font-family: $card-title-font-family;
        font-size: $card-title-font-size;
        font-weight: $card-title-font-weight;
        line-height: $card-title-line-height;
        text-align: center;
    }

    #rel-first-card-footer {
        background-color: $primary-color;
        color: $button-color;
        border-radius: $card-border-radius;
        z-index: 2;
        text-align: center;
        cursor: pointer;
        text-transform: $button-text-transform;
        font-size: $button-font-size;
        font-weight: $button-font-weight;        
        padding: $button-padding;
    }

    .card-footer {
        padding: 0.75rem 0;
        border-radius: $card-border-radius;
    }

    .card.rel-card {
        height: 16rem;
        border-radius: $card-border-radius;
        box-shadow: $shadow-elevation-1;
        padding: 0;
        cursor: pointer;
        margin: 0 .5rem 0 .5rem;
        max-width: 250px;
        min-width: 150px;
    }

    .card.rel-card:before {
        content: "";
        position: absolute;
        filter:$water-mark-filter;
        background-image: var(--logoNoText-url);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        opacity: 0.05;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .rel-card-text {
        font-weight: $card-title-font-weight;
        font-size: 1.1rem;
        line-height: 1.25rem;
        font-family: $card-title-font-family;
        max-height: 6rem;
        margin-bottom: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    .card-footer.rel-card-footer {
        background-color: $primary-color;
        color: $button-color;
        text-transform: $button-text-transform;
        font-size: $button-font-size;
        font-weight: $button-font-weight;
        padding: $button-padding;
    }

    .rel-card-footer {
        border-radius: $card-border-radius !important;
        z-index: 2;
        text-align: center;
        cursor: pointer;
    }

    .rel-card-date {
        justify-content: center;
        text-align: center;
        padding: 1rem;
    }

    .rel-all-arrow {
        position: absolute;
        right: 0;
        color: $primary-color;
        z-index: 5;
        align-items: flex-end;
        width: fit-content;
    }

    .rel-all-arrow::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 65%;
        height: 16.2rem;
        z-index: 5;
        cursor: pointer;
    }

    .rel-arrow {
        margin-top: 65%;
        transform: translateY(65%);
        height: 75px;
    }

    @media only screen and (max-width: 575px) {     
        .rel-all-arrow {
            display: none;
        }
    }

    @media only screen and (max-width: 349px) {
        .rel-title {
            margin-top: 3rem;
        }
    }
    

    @media only screen and (min-width: 768px) and (max-width: 840px) {
        .rel-card-text {
            max-height: 4.5rem;
            -webkit-line-clamp: 3;
        }
    }

    @media only screen and (min-width: 1190px) and (max-width: 1310px) {
        .rel-card-footer .first-word {
            display: none;
        }
    }

    @media only screen and (max-width: 1350px) {
        .rel-card-body.card-body {
            padding: .9rem .9rem .5rem .9rem;
        }

        .rel-card .card-text {
            margin-bottom: 0.8rem;
        }
    }
}