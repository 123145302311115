@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';


.navbar {
    align-content: center;

    a {
        color: inherit;
        text-decoration: $link-text-decoration;

        &:hover {
            color: $header-hover-color;
            text-decoration: none;
        }
    }

    .minimize-btn {
        display: none;
    }

    i.docked {
        transform: rotate(180deg);
    }

    .navbar-brand {
        
        background-position: top center;
        background-repeat: no-repeat;
        background-size: contain;
        display: none;
        box-shadow: none;
        background-color: transparent;
    }

    // overrides to show generic logo
    .siteboss-logo .navbar-brand {
        background-position: $logo-background-position;
        background-repeat: $logo-background-repeat;
        background-size:$logo-background-size;
        filter: $logo-filter;
    }

    .siteboss-logo .mobile-logo a {
        font-size: 1.5rem;
        width: 56px;
        height: 30px;
        color: transparent;
        text-indent: -100%;
        white-space: nowrap;
        overflow: hidden;
    }

    .navbar-toggler,
    .navbar-nav .nav-link {
        color: $company-white;
    }

    .button-bar {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .button-bar-left {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-family: $secondary-font-family;
        font-weight: $bold-font-weight;
        font-size: $big-font-size;
        & h2{
            font-size: $big-font-size;
            font-weight: $bold-font-weight;
            margin:0;
    
            &:before{
                color: $tertiary-color;
                content:'[';
            }
            &:after{
                color: $tertiary-color;
                content:']';
            }
        }
    }

    .button-bar-left > * {
        margin-right: .5rem;
    }

    .menu-btn,
    .navbar-brand {
        margin-left: 0;
    }


    .button-bar-right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        i{
            font-size: 15px;
            &:not(.cart){
                margin-top: 7px;
            }
        }
    }

    .button-bar-right > *:not(:first-child) {
        margin-left: 1.3rem;
    }

    .menu-btn,
    .signout-btn,
    .profile-btn a {
        display: flex;
        align-items: center;
    }

    .menu-btn {
        margin-left: 0.2rem;
    }

    .user-name {
        margin-right: 4px;
        margin-left: 6px;
        font-size: 0.85rem;
    }

    .cart-btn {
        margin-top: 4px;
    }

    .button-bar img {
        cursor: pointer;
    }

    .button-bar > div:before {
        position: absolute;
        top: -10px;
        justify-self: center;
        border-radius: 50%;
        height: 7px;
        width: 7px;
        background: transparent;
        content: '';
    }

    .button-bar {
        display: flex;
    }

    .mobile-logo img {
        max-height: 40px;
        max-width: 40px;
    }

    .user-picture {
        margin-right: .1rem;
        height: $profile-image-small-size;
        width: $profile-image-small-size;
        object-fit: cover;
    }

    .button-bar .user-icon {
        font-size: 1.6rem;
        margin-right: .1rem;
    }

    .menu-btn {
        min-width: 20px;    // fixes the anchor changingn widths if the two icons are of different widths
    }
}


.popover {
    min-width: 276px;
    height: auto;

    .container-fluid {
        height: auto;
    }

    .user-info {
        background-color: #f5f5f5;
        padding: 1rem;
        border-radius: .5rem;
    }

    .list-group-item i {
        font-size: .85rem;
        margin-right: .75rem;
        opacity: 0.55;
    }

    .user-picture {
        margin-top: unset;
        height: 3rem;
        width: 3rem;
    }
}


@media (min-width: 992px) {
    .navbar {
        .hide-full-size {
            display: none;
        }

        // .mobile-logo {
        //     display: none;
        // }
    }

    .popover {
        max-width: 30% !important;
    }
}

@media (max-width:991px) {
    .navbar {
        min-height: $company-navbar-height-small;

        .company-name,
        .env-name,
        .user-name {
            display: none;
        }

        .navbar-brand {
            margin-bottom: 0;
            margin-left: 1rem;
        }
    }
}


@media (min-width: 661px) {
    .navbar-brand {
        display: inline-block !important;
    }
    .siteboss-logo .mobile-logo a{
        display: block;
    }
}
