@import '../../../../assets/css/scss/mixins';
@import '../../../../assets/css/scss/variables';
@import '../../../../assets/css/scss/themes';


.img-div{
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    &.center{
        justify-content: center;
    }
    &.left{
        justify-content: flex-start;
    }
    &.right{
        justify-content: flex-end;
    }
    .pos-img{
        max-width: 100px;
        max-height: 100px;
        &.circle{
            height: 100px;
            width: 100px;
            border-radius: 50%;
            object-fit: cover;
        }
        &.large{
            max-height: 400px;
            max-width: 400px;
            object-fit: scale-down;
        }
        &.medium{
            max-height: 200px;
            max-width: 200px;
            object-fit: scale-down;
        }
    }
}