@import '../../../../../assets/css/scss/variables.scss';
@import '../../../../../assets/css/scss/themes.scss';

.info-pair{
    display: flex;
    flex-direction: row;
    align-items: center;
    
    span:first-child{
        min-width: 110px;
        font-weight: $bold-font-weight;
        font-family: $secondary-font-family;
        line-height: $secondary-line-height;
    }

    div:first-child{
        min-width: 125px;
        font-weight: $regular-font-weight;
        font-family: $primary-font-family;
        line-height: $primary-line-height;
    }

    span:last-child,
    div:last-child{
        min-width: 50px;
        font-family: $primary-font-family;
        line-height: $primary-line-height;

        :global(.form-check){
            margin: 0;
            min-height: $primary-line-height;
        }
    }
}

.colspan{
    max-width:200px;
}
