@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';

$qr-icon-size: 3rem;

.qr-code-icon {
    margin-bottom: $main-padding;

    .btn.btn-primary, .btn-primary:not(:disabled):not(.disabled):active {
        background-color: $neutral-background-color;
        border: $button-border;
        border-radius: $button-border-radius;
        border-width: $button-border-width;
        border-color: $button-border-color;
        text-shadow: none;
    }
    .btn {
        margin: 0;
        padding: 0 !important;
        width: $qr-icon-size;
        height: $qr-icon-size;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn, .btn:focus, .btn-primary:not(:disabled):not(.disabled):active {
        box-shadow: $shadow-elevation-3 !important;
    }
    .btn-primary:hover {
        background-color: $card-background-color;
    }
    .btn-primary:not(:disabled):not(.disabled):active {
        background-color: $card-background-color;
    }
    .btn i.far {
        margin: 0;
        font-size: calc($qr-icon-size / 1.8);
        color: $primary-font-color;
    }
}
.qr-code-display {
    .modal-body {
        display:flex;
        text-align: center;
        justify-content: center;
        padding: 5rem 1rem;
    }
}