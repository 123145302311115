$refund-margin-bot: 8px;

input.refund-custom-input::-webkit-outer-spin-button,
input.refund-custom-input::-webkit-inner-spin-button{
  -webkit-appearance: none;
  margin: 0;
}
input#refund-total-input[type=number], input#refund-amount[type=number] {
    -moz-appearance: textfield;
}
.refund-title{
    width: 24rem;
    display: flex;
    justify-content: space-between;
}
.refund-grid-row{
    display: -ms-grid;
    display: grid;
    grid-template-columns:repeat(2, 1fr);
    gap: 1.5rem;
    width: 90%;
}
.refund-admin-row{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    grid-template-columns: auto auto auto;
    vertical-align: baseline;
}
.refund-grid-row-top{
    display: -ms-grid;
    display: grid;
    grid-template-columns:repeat(3, 1fr);
    gap: 1.5rem;
    width: 90%;
}
/*#refund-accordion-toggle.card-header{
    margin-top: 15px;
}*/

.refund-custom-input{
    width: 10rem;
    margin-bottom: $refund-margin-bot
}
.grid-row-space-evenly{
    width: 10rem;
    display:flex;
    justify-content: space-between;
    margin-bottom: $refund-margin-bot;
}
.ri-small-pad{
    padding-right: 1rem;
}
.refund-label, span.refund-label{
    float: left;
    margin-right: 1rem;
    width: 200px;
}
.refund-input-dd{
    width: 200px;
    height: 30px;
    padding: 5px;
}
.ref-error-msg{
    color: red;
    font-size: 14px;
    margin-bottom: 5px;
}
.refund-checks{
    width: 250px;
}
.check-label{
    margin: 0 5px 5px 1rem;
}

.confirm-refund-card{
    width: 800px;
    margin: 2rem auto 0 auto;
    text-align: center;
    height: 180px;
}
.confirm-refund-card p{
    font-size: 1rem;
    font-weight: 600;
}
.confirm-refund-btns{
    width: 200px;
}

.refund-tools td{
    width: 10rem;
}

.confirm-modal-backdrop.modal-backdrop{
    z-index:8060;
    background-color: rgb(0, 0, 0);
}

@media only screen and (min-width: 400px) and (max-width: 1000px){
    .confirm-refund-card{
        max-width: 300px;
        height: 315px;
    }
}
@media only screen and (max-width: 399px){
    .confirm-refund-card{
        max-width: 250px;
        height: 360px;
    }
}
@media only screen and (min-width: 400px) and (max-width: 550px){
    .refund-title{
        width: 250px;
    }
    .refund-grid-row{
        display: -ms-grid;
        display: grid;
        gap: 1.5rem;
        width: 90%;
    }
    .refund-label{
        width:125px;
    }
    .refund-input-dd{
        width: 125px;
    }
}

@media only screen and (max-width: 370px){
    .refund-title{
        width: 200px;
    }
    .refund-grid-row{
        display: -ms-grid;
        display: grid;
        grid-template-columns:1fr;
        gap: 1.5rem;
        width: 90%;
    }
}