

.modal-content .modal-body.version-prompt {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;

    .buttons {
        margin-top: 2rem;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .now-btn {
            font-size: 1.3rem;
            padding: 1.0rem 2.0rem !important;
        }
    }
}