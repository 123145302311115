@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';
@import '../../../assets/css/scss/mixins';

.multi-step {

    .past,
    .current {
        background: rgb(123, 213, 255);
    }

    .button-row .btn{
        margin: $button-margin;
        margin-top:0;
        margin-bottom:0;

        &:first-child{
            margin-left: 0;
        }

        &:last-child{
            margin-right: 0;
        }
    }

    .button-row .btn i {
        width: 12px;
        height: 12px;
        margin: 0 0 0 5px;
    }

    .button-row .btn .fa-arrow-left {
        width: 12px;
        height: 12px;
        margin: 0 5px 0 0;
    }


    /*  */
    ol.step-bar {
        font-family: $tabs-font-family;
        font-size: $tabs-font-size;
        margin: 0;
        padding: 0 0 1rem 0;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    ol.step-bar li {
        flex: 1 1 0;
        text-align: center;
        line-height: 2.2rem;
    }

    @media (max-width: 3000px) {
        .step-bar li span {
            display: none;
        }
    }

    .step-bar em {
        font-style: normal;
        white-space: nowrap;
        overflow: hidden;
    }

    @media (max-width: 810px) {
        .step-bar em {
            display: none;
        }
        i{
            @include icon-only-title-replacement;
        }

    }

    ol.step-bar li.todo {
        color: $disabled-color;
        border-bottom: 2px solid $divider-border;
        cursor: default;
    }

    ol.step-bar li.visited {
        color: $primary-font-color;
        cursor: pointer;
    }

    ol.step-bar li.doing {
        font-weight: $bold-font-weight;
        border-bottom: 2px solid $primary-color;
    }

    ol.step-bar li.done {
        border-bottom: 2px solid $secondary-color;
    }
}