@import '../../assets/css/scss/variables';
@import '../../assets/css/scss/themes';

.modal-dialog button.pos-button-checkout,
.preview-container #pos-button-checkout,
.preview-container #pos-button-checkout.active {
    font-family: $secondary-font-family;
    display: block !important;
    padding: 1rem !important;
    margin: 0;
    border-radius:0;
    border-color:$primary-color;
    background-color: $primary-color;
    text-shadow: 0 0 3px $button-text-shadow;
    color: $button-color;
    text-align:center;
    font-size: 1.4rem !important;
    letter-spacing: 1px;
    font-weight: 500;
    text-transform: uppercase;
    width: 100%;
    opacity: 1;
}

.pos-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1040;
    background: $background-color;
    overflow: hidden;


    input,
    .preview-container input {
        padding: 1rem !important;
    }

    .pos-header{
        background-color: $pos-header-background-color;
        color: $pos-header-font-color;
        margin-bottom:0;
        height: $header-height;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.7rem;

        li.nav-item:last-child{
            button.btn{
                margin-right:0!important;
            }
        }

        .btn-back {
            line-height: 22px;
            border: 0;
            font-size: 2rem;
            color: $pos-header-font-color;
            background: transparent;
            margin-right:0;
        }

        .btn-back:hover {
            color: $header-hover-color;
        }

        .pos-name{
            margin:0;
            font-size:1.3rem;
            font-weight:300;
            text-transform: uppercase;
            color: $pos-header-font-color;
            font-family: $secondary-font-family;
        }

    }
    
    .pos-content,
    .pos-content > .site-col {
        height:100vh !important;    // this is very important to not mess up the columns internal scrolling!
    }

    .col,
    .row {
        margin-right: 0;
        margin-left: 0;
    }

    .site-col,
    .site-row {
        width: 100%;
        height: 100%;
        flex-wrap: nowrap;
        align-items: stretch;
    }

    // the row below the header
    .pos-main-row {
        height: calc(100vh - $header-height);
    }

    // the row above the recent checkins
    .pos-above-checkins-row {
        height: calc(100vh - $header-height - $company-pos-recent-checkins-height);
    }

    .item-container,
    .user-container,
    .events-container {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        max-height: 100%;
        background-color:$pos-column-color;
    }
    
    .user-container {
        background: $menu-background-color;
        max-width: $pos-menu-width;
        flex: 1 0 $pos-menu-width;
    }

    .item-container {
        flex: 1 1 auto;
    }
    
    .preview-container {
        background: $menu-background-color;
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
        overflow-x:hidden;
        max-width: 460px;
        flex: 1 0 460px;
        padding-left:0;
        padding-right:0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .btn-outline-secondary {
        color: $company-white;
    }
    .preview-container .pos-button-checkout.disabled,
    .preview-container #pos-button-checkout.disabled {
        background-color: $disabled-color !important;
        border-color: $disabled-color !important;
        text-shadow: 0 0 3px $button-text-shadow;
    }

    .pos-denom-btn {
        margin: 2px;
    } 

    .user-container .card.noborder {
        border: none;
        box-shadow: none;
    }

    a.service.card:hover {
        text-decoration: none;
        color: $company-white;
        background: $pos-theme-primary !important;
    }
    
    a.service.card:active {
        transform: translateY(7px);
        background: $pos-theme-primary;
    }

    a.service.card:active {
        transform: translateY(7px);
    }

    a.service.card:not(.single).active:hover {
        text-decoration: none;
        background: $pos-theme-primary !important;
    }

    .btn.btn-secondary {
        background-color: $pos-secondary-color;
    }

    .btn-secondary.focus, .btn-secondary:focus, .btn-secondary:hover {
        color: $button-hover-color;
        background-color: $primary-hover-color;
        border-color: $primary-hover-color;
    }
}

.pos-patron-container {
    position:relative;
    z-index: 999;
    background: $background-color;
    overflow-x: hidden;
    scrollbar-width: thin;

    &.transparent {
        background: transparent;

        .pos-content > div {
            background: transparent;
        }
    }

    .cart-checkout {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1rem 0.75rem 1rem 1rem;

        .preview-container {
            width: $menu-width;
            flex: 1 0 $menu-width;
        }

        .item-container{
            padding: 0 1rem;
        }
    
        .item-container .row{
            display:grid;
            gap: 1rem;
            grid-template-columns: repeat(4, 1fr);

            .product_item{
                padding:0;

                .product-card{
                    margin:0;
                }
            }
        }
        
    }

    .description-banner,
    .cart-checkout {
        flex-direction: column;
    }


    .description-banner h4 + div{
        font-size: $card-subtitle-font-size;
    }

    /* The two blocks below fix a double scroll bar*/
    .events-container.row {
        overflow-y: unset;    
        max-height: unset;
    }

    .events-container {
        h2 {
            font-size: 1.8rem;
        }
        h3 {
            font-size: 1.2rem;
        }
    }

    .pos-content,
    .pos-content > .row {
        height: unset!important;
        max-height: unset;
    }

    .pos-content > .card {
        margin-top: 1rem;
        border: none;
    }
    
    .pos-content > div {
        background-color: $background-color;
        box-shadow: none;

        .item-container.row {
            margin-left: 0 !important;
        }
    }

/*
    a.card {
        border: 1px solid $pos-theme-primary;
    }


    a.service.card:hover {
        text-decoration: none;
        color: $company-white;
        background: $pos-theme-primary !important;
    }
    
    a.service.card:active {
        transform: translateY(7px);
        background: $pos-theme-primary;
    }

    a.service.card:active {
        transform: translateY(7px);
    }

    a.service.card:not(.single).active:hover {
        text-decoration: none;
        background: $pos-theme-primary !important;
    }
*/
    h1 {
        font-size: 2rem;
    }

    .blue-theme {

        .btn.btn-primary {
            background-color: $pos-theme-blue;
        }

        .preview-items-grid .product-buttons .btn-light:hover {
            border: 1px solid $pos-theme-blue !important;
    
            i {
                color: $pos-theme-blue !important;
            }
        }
    
        .product-card {
            border: 1px solid $pos-theme-blue;
        }
    
        .product-card:hover,
        .product-card:not(.single).active:hover {
            color: $company-white !important;
            background: $pos-theme-blue !important;
        }

        .product-card:active,
        .product-card.active {
            color: $company-black;
            background: none;
        }
    
    }

    .green-theme {

        .btn.btn-primary {
            background-color: $pos-theme-green;
        }
    
        .preview-items-grid .product-buttons .btn-light:hover {
            border: 1px solid $pos-theme-green !important;
    
            i {
                color: $pos-theme-green !important;
            }
        }
    
        a.card {
            border: 1px solid $pos-theme-green;
        }
    
        .product-card:hover,
        .product-card:not(.single).active:hover {
            color: $company-white !important;
            background: $pos-theme-green !important;
        }

        .product-card:active,
        .product-card.active {
            color: $company-black;
            background: none;
        }
    
    }

}

.register-7{
    .product_item{
        margin: .5rem;
        border: 1px solid $form-control-border-color;
        border-radius: $card-border-radius;
    }
}


@media screen and (max-width: 991px) {
    .pos-patron-container{
        .cart-checkout{
            .item-container{
                max-width:calc(100vw);
            }
        } 
    }
}

.pos-p-modal{
    z-index:1062 !important;
}
.pos-p-backdrop{
    z-index:1062 !important;
}

.pos-patron-container .no-select-text,
.online-pickup-pos .no-select-text{
    &.services.single.card{
        border: solid 1px $primary-color;
        margin-bottom: 1rem;
    }
}


.pos-patron-container .no-select-text,
.online-pickup-pos .no-select-text{
    &.services.single.card{
        min-height: 75px !important;
        min-width: 100px !important;
    }
    .card-body{
        padding: 0 !important;
        .card-title.h5, .price{
            font-size: 15px !important;
        }
    }
}

.description-banner.content-card.card{
    padding-bottom:0;
    @media screen and (max-width: 450px){
        display: none;
    }
}

.pos-container:has(.online-pickup-pos){
    overflow: auto;
    scrollbar-width: thin;
}

.online-pickup-pos{
    &.cart-checkout{
        overflow-x: auto;
    }
    .online-pickup-row{
        display: flex;
        flex-direction: row;
        margin-right: auto;
        margin-left: auto;
        max-width: unset;
    }
    .item-container{
        margin-left: auto;
        margin-right: auto;
        background-color: transparent;
    }
    .shopping-cart-whole, .empty-cart{
        max-width: 475px;
        min-width: 400px;
        overflow-y: hidden;
        border-radius: 10px;
        padding: 5px;
        background-color: $card-background-color;
        &:has(.hidden){
            max-width: 125px; 
            min-width: 125px;
        }
    }
    .btn-category{
        @media screen and (max-width: 475px) {
            width: 100%;
        }
    }
    @media screen and (max-width: 1000px) {
        .online-pickup-row{
            flex-direction: column-reverse;
        }
        .shopping-cart-whole, .empty-cart{
            position:fixed;
            bottom: 10px;
            right: 10px;
            top: unset;
            border: solid 1px $secondary-color;
            margin-bottom: 1rem;
        }
    }
    @media screen and (max-width: 500px){
        .item-container.pos-products{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
        }
        &.cart-checkout{
            position:relative;
            padding: 5px;
        }
        .shopping-cart-whole, .empty-cart{
            max-width: 400px;
            min-width: unset;
        }
        .online-pickup-row{
            padding: 0;
        }
        .pos-products{
            &.services.single.card{
                padding: 5px;
            }
        }
    }
}

@media screen and (max-width: 1000px){
    .pos-modal{
        .row{
            flex-direction: column-reverse;
            .variant_container{
                background-color: $card-background-color !important;
                background: unset !important;
                .row{
                    grid-template-columns: repeat(2, 1fr);
                    @media screen and (max-width: 350px){
                        grid-template-columns: auto;
                    }
                }
            }
            .no-select-text{
                &.services.single.card{
                    border: solid 1px $primary-color;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}