.total-tile{
    background:#424242;
    color:#fff;
    padding:0;
}
.total-tile .card-body{
    padding:1rem;
    text-align:right;
}

.total{
    text-align:right;
}
.totals h1{
    font-size:1.5rem;
    font-weight: 500;
}

.btn-tip{
    z-index:9999;
}

.btn-tip > button{
    padding: .4rem .75rem !important;
    margin-right: unset !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.lookup-result {
    display: flex;
    padding: .5rem 1rem .5rem 1rem;
    align-items: center;
}

.lookup-result.shaded {
    background-color: #eee;
}

.lookup-clickable:hover {
    cursor: pointer;
}

.customer-info-card {
    background-color: rgba(0, 0, 0, .03);
    max-height: 75vh;
    margin: 0;
}

.customer-info-card .card-header,
.customer-info-card .card-footer {
    background-color: transparent;
}

.customer-info-card .card-footer {
    display: flex;
    justify-content: center;
}

.customer-info-card .card {
    padding: 0;
}

.customer-info-card .card-body {
    overflow-y: scroll;
    padding: .5rem 1rem .5rem 1rem;
}

.customer-info-card .card-body .card-body {
    overflow: hidden;
}

.customer-info-card ul {
    list-style-type: disc;
    list-style-position: inside;
}

.customer-info-card li:last-child {
    margin-bottom: .5rem;
}

.event-fee {
    display: flex;
    justify-content: space-between;
    padding: .5rem 2.5rem .5rem 2.5rem !important;
}