@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';

.pos-nav-tabs {
    background:transparent;
    justify-content: flex-end;
    border-bottom: 0;
    display: flex;
    flex-flow: row;

    li.nav-item {
        text-align: center;
        display: flex;
    }

    .btn {
        font-size: 0.85rem;
        font-weight: 500;
        margin: $pos-header-button-margin;
        padding: 4px 10px !important;
        border: $pos-header-button-border;

        &.btn-light {
            padding: 0.45rem !important;
        }

        &:hover{
            text-decoration: none !important;
            border: $pos-header-button-hover-border;
        }

    }
    
    // .nav-item .nav-link{
    //     font-weight: 500;
    //     margin: 5px 5px 0 0;
    //     background: #fefefe;
    //     border: 2px solid #cccccd;
    //     display: flex;
    //     align-items: center;
    //     font-size:.85rem;
    //     padding: .5rem .7rem;
    //     margin-bottom:-1px;
    // }

    // .nav-item.show .nav-link, 
    // .nav-link.active{
    //     background: #cccccd;
    //     border-width: 2px;
    //     border-color: #F67730 !important;
    //     border-radius: 5px;
    //     color: #555;
    // }

    .white-tab{
        padding-top:1rem !important;
    }
}
