.bg-banner {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 0px;
    box-sizing: border-box;
}

.invitation-card {
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
}
