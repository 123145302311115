@import '../../../../assets/css/scss/variables';
@import '../../../../assets/css/scss/themes';

.pos-modal{
    @extend .pos-container;
}

.pos-patron-container,
.pos-container{
    .variant_container .or-he{
        color: $primary-font-color !important;
        font-family: $secondary-font-family;
        font-weight: $bold-font-weight;
    }

}