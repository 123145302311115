@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';

.dob-banner {

    &.card {
        display: flex;
        flex-direction: column;
        background-color: $error-color;
        color: $error-text-color;
        font-size: 1.1rem;
        font-weight: 400;
        text-align: center;
    }
}

.dob-set-modal {
    .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 400px;
    }

    .dob-success {
        color: $success-color;
    }

}