@import '../../../../assets/css/scss/variables';

.profile-sub-modal-wrapper{
    fieldset{
        margin: 1rem;
        border: 1px solid $company-neutral-light;
        border-radius: 5px;
        text-align: center;
        padding: 10px;
        legend{
            width: auto;
        }
    }
    .btn{
        margin-top: 1rem;
    }
}

.profile-subscriptions-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .button-row {
        justify-content: center;

        .btn {
            margin: 0;
        }
    }
}

.sub-fieldset{
    border-radius: 10px;
    border: solid 1px $company-neutral-light;
    width: 300px;
    padding: 1rem;
    margin: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    legend{
        text-align: center;
        width: auto;
        font-size: 1rem;
        font-weight: 500;
    }
    p{
        padding-left: 1rem;
        display: flex;
        justify-content: space-between;
    }
}