@import '../../../../assets/css/scss/variables';
@import '../../../../assets/css/scss/themes';

.addon-preview {
    font-size: 0.95rem;
    padding: 0.5rem;

    .preview-addons-grid {
        grid-template-columns: auto 40px;
        border-bottom: none;

        .row-wrapper:first-of-type {
            .item-name{
                font-family: $secondary-font-family;
                font-weight: $bold-font-weight;
            }
        }

        .row-wrapper > * {  // every "cell" in the grid
            border-top: none;
        }

        .addon-dot {
            margin: 0 0.5rem 0 0rem;
        }
    }

}