@import '../../../assets/css/scss/mixins';
@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';

.pickup-id{
    font-weight: 700;
}

.status-btn{
    @include outline-button;
}

.large-btn{
    @include pos-spaced-button;
}

.large-text{
    font-size: 1.25rem;
}

.expanded-row{
    width: 100%;
}

.filter-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    
}

input, select{
    @include basic-input-select
}

label{
    @include basic-label;
}

.col-pair{
    @include basic-flex-column;
    padding: .25rem 1rem;
}

.item-name{
    font-weight: 600;
    width: 75%;
    text-align: left;
}
.item-map{
    display: flex;
    justify-content: space-between;
    margin: 0;

    .item-name{
        font-weight: 400;
    }
}

.item-container{
    ul{
        list-style-type: circle;
    }
    border: 1px solid $divider-color;
    margin: 1rem;
    padding: 1rem;
    border-radius: $card-border-radius
}