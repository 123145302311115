@import '../../../../assets/css/scss/variables';
@import '../../../../assets/css/scss/themes';

.container{
    font-family: $secondary-font-family;
    font-size: $primary-font-size;

    hr{
        border-top-color: $primary-font-color !important;
    }

    div{
        display:flex;
        justify-content: space-between;
        margin: calc($main-padding / 4) 0;

        span:first-child{
            margin-right: 0.5rem;
        }
    }
    
    .subtotal{
        font-weight: $bold-font-weight;
    }

    .total{
        font-size: $big-font-size;
        font-weight: $bold-font-weight;
        text-transform: uppercase;
        margin-top: calc($main-padding / 2);
    }

    .aggregate{
        font-size: $small-font-size;
        font-weight: 400;
        margin-left: 1rem;
        opacity: 0.75;
    }

    .paid {
        position: relative;
        box-shadow: 0 0 0 3px $error-color, 0 0 0 2px #eaf5ec inset;  
        border: 2px solid transparent;
        border-radius: 4px;
        padding: 5px 2px;
        line-height: 22px;
        color: $error-color;
        font-size: 2rem;
        font-family: Arial, cursive;
        text-transform: uppercase;
        text-align: center;
        width: auto;
        transform: rotate(-15deg);
    }

    .partial{
        box-shadow: 0 0 0 3px $primary-font-color, 0 0 0 2px #eaf5ec inset;  
        color: $primary-font-color;
        font-size:1rem;
        font-weight: 600;
    }
}


