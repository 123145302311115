@import '../../../../../assets/css/scss/themes.scss';

.wrapper{
    margin:0 !important;
    padding:0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .cancel{
        margin-right: auto;
        margin-left: 0; 
        justify-self: flex-start;   
        width: auto !important;
    }


    .pos-button {
        font-family: $secondary-font-family;
        display: block !important;
        padding: 1rem !important;
        margin: 0 !important;
        border-radius:0;
        text-align:center;
        font-size: 1.25rem !important;
        letter-spacing: 1px;
        font-weight: 500;
        text-transform: uppercase;
        width: auto;
        border-color:$neutral-background-color !important;
        background-color: $neutral-background-color !important;
        text-shadow: 0 0 3px $button-text-shadow;
        flex:1;

        &.primary{
            border-color:$primary-color !important;
            background-color: $primary-color !important;
            color: $button-color;
        }

        &.secondary{
            border-color:$secondary-color !important;
            background-color: $secondary-color !important;
            color: $button-color;
        }
    }
}