.info-container{
    display:flex !important;
    flex-direction:row !important;
    border:1px solid rgba(0,0,0,.125) !important;
    margin-bottom:.5rem;
    padding:.5rem 1rem !important;
}
.info-container > div:nth-child(2){
    width:50%;
    text-align: right;
}