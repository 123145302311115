@import '../../../../../assets/css/scss/variables';
@import '../../../../../assets/css/scss/themes';

$min-size: 120px;

.product_item{    
    margin: 0.20rem!important;
    max-width: 215px;
    width: 100%;
    //width: calc(25% - 0.75rem);
    min-width: $min-size;
    min-height: $min-size;
    padding: 0 !important;
    text-decoration: none;

    :global(.card-title) {
        font-family: $secondary-font-family !important;
        font-size: 1rem !important;
        font-weight: $bold-font-weight !important;
        line-height: 1.2rem;
        margin: 0 !important;
        padding: 0 0 0.5rem 0 !important;
        text-align: center !important;
    }

    :global(.product-description) {
        font-weight: 400;
        text-align: center !important;
        padding-top: 0.3rem;
    }

    .product-card {
        background-color: $card-background-color;
        color: $primary-font-color;
        font-size: $card-font-size;
        font-family: $button-font-family;
        border: $button-border;
        border-radius: $button-border-radius;
        text-decoration: none;
        box-shadow: $button-shadow;
        min-width: $min-size;
        min-height: $min-size;
        width: 100%;
        height: 100%;

        &:not(.disabled):hover{
            text-decoration: none;
            color: $button-hover-color;
            background: $primary-color !important;
            .product-name{
                color: $button-hover-color;
            }
        }

        &.disabled{
            background-color: $button-disabled-background-color;
            color: $button-disabled-color;
        }

        :global(.card-body) {
            padding: calc($main-padding / 2);
            align-items: center;
            text-align: center;
            display: flex;
            justify-content: center;

            .product-container{
                display:flex;
                flex-direction: column;
                width: 100%;
                flex-wrap: nowrap;
                align-items: stretch;                
            }
        }

        :global(.price),
        :global(.activation-fee) {
            font-size: $small-font-size;
            font-weight: $bold-font-weight;
            text-align: center !important;
        }

        :global(.price) {
            font-size: 1rem;
        }

        &:global(.services) {
            .price {
                text-align: right;
            }
            .product-name {
                text-align: left;
            }
        }

        :global(.card-footer) {
            border:0;
            background:transparent;
            text-align:right;
        }

        &:not(.disabled):active{
            transform: translateY(7px);
            background: $primary-color;
        }

        &:global(.single):active{
            transform: translateY(7px);
        }

        &:not(:global(.single)).active:hover{
            text-decoration: none;
            background: $primary-color !important;
        }

        &.active:nth-of-type(1){
            background: $primary-color;
            color: $button-color;
            .product-name{
                color: $button-color;
            }
        }

        :global(.subscription-text){
            margin-top: 0.25rem;
            text-align: center;
            font-size: $small-font-size;
            p{
                margin-bottom: 0;
                margin-top: 3px;
            }
        }
    }
}