@import '../../../../assets/css/scss/variables';
@import '../../../../assets/css/scss/themes';
.button {
    font-family: $secondary-font-family;
    display: block !important;
    padding: 1rem !important;
    margin: 0 !important;
    border-radius:0;
    text-align:center;
    font-size: 1.25rem !important;
    letter-spacing: 1px;
    font-weight: 500;
    text-transform: uppercase;
    width: auto;
    border-color:$neutral-background-color !important;
    background-color: $neutral-background-color !important;
    text-shadow: 0 0 3px $button-text-shadow;
    flex:1;
}