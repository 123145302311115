@import '../../../assets/css/scss/themes.scss';

.tooltip-wrapper{
    display: inline-block;
    position: relative;
}

.tooltip-text{
    position: absolute;
    border-radius: $card-border-radius;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: 6px;
    color: $primary-inverse-color;
    background-color: $primary-color;
    z-index: 1000;
    font-weight: 400;
    font-size: $small-font-size;
    text-align: center;
    line-height: 1;
    box-shadow: $shadow-elevation-1;
}

.tooltip-text::before{
    content: " ";
    left: 50%;
    border: solid transparent;
    position: absolute;
    pointer-events: none;
    border-width: 6px;
    margin-left: calc(6px * -1);
}

.tooltip-text.top{
    top: calc(50px * -1);
}
.tooltip-text.top::before{
    top: 100%;
    border-top-color: $primary-color;
}

.tooltip-text.right{
    left: calc( 100% + 30px);
    top: 50%;
    transform: translateX(0) translateY(-50%)
}
.tooltip-text.right::before{
    left:calc(6px * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: $primary-color;
}

.tooltip-text.bottom{
    bottom: calc(50px * -1);
}
.tooltip-text.bottom::before{
    bottom: 100%;
    border-bottom-color: $primary-color;
}

.tooltip-text.left{
    left: auto;
    right: calc(100% + 30px);
    top: 50%;
    transform: translateX(0) translateY(-50%)
}
.tooltip-text.left::before{
    left: auto;
    right: calc(6px * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: $primary-color;
}

@media screen and (max-width:500px) {
    .tooltip-text.top{
        top: calc(80px * -1);
    }
    .tooltip-text.right{
        left: calc( 100% + 15px);
    }
    .tooltip-text.bottom{
        bottom: calc(80px * -1);
    }
    .tooltip-text.left{
        right: calc(100% + 15px);
    }
}
