@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/mixins';

.recent-checkins-list {
    .btn {
        display: block;
        padding: 12px 10px;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        background-color: transparent;
        text-shadow: none;
        font-size: 1rem;
        text-align: left;
        color: #000;
        font-weight: 500;
    }
    .btn:hover {
        background-color: #b3d0f0;
    }
    i.fas {
        margin-right: 12px;
        transform: scale(1.3);
        color: #76a0cd;
    }
    i.fa-check-circle {
        color: #34eb34;
    }
    i.fa-times-circle {
        color: #f4c000;
    }
    .pagination {
        margin-top: 0.7rem;
    }
    .basic-button{
        @include basic-button;
    }
}

// for the POS display we need to override the regular list display
.pos-container .recent-checkins-list {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    padding: 0 10px;
    padding-left: 80px;
    width: 100%;
    min-height: 2.1rem;
    background: $company-pos-checkins-bar;
    color: $company-white;
    overflow-y: auto;
    overflow-x: hidden;

    .btn {
        flex: 1 1 100%;
        padding: 6px 10px !important;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        border-radius: 0;
        background-color: transparent;
        text-shadow: none;
        font-size: 0.85rem;
        text-align: left;
        max-height: 2rem;
        color: $company-white;
    }

    .btn.view-all {
        flex: 0 0 auto;

        i {
            color: $company-white;
            transform: scale(1.6);
            margin-top: 4px;
        }
    }

    i.fas {
        margin-right: 6px;
        transform: scale(1.3);
        color: $blue-light;
    }

    i.fa-check-circle {
        color: $green-bright;
    }

    i.fa-times-circle {
        color: $yellow-dark;
    }
}

@media screen and (max-width: 1570px) {
    .recent-checkins-list {
        .btn {
            display: none;
        }
    }
}

@media screen and (min-width: 1221px) and (max-width: 1570px) {
    .recent-checkins-list {
        .btn:nth-child(-n+5) {  // show the first 4 checkins on smaller screens
            display: block;
        }
    }
}

@media screen and (min-width: 1041px) and (max-width: 1220px) {
    .recent-checkins-list {
        .btn:nth-child(-n+4) {  // show the first 3 checkins on smaller screens
            display: block;
        }
    }
}

@media screen and (max-width: 1040px) {
    .recent-checkins-list {
        .btn:nth-child(-n+3) {  // show the first 2 checkins on smaller screens
            display: block;
        }
    }
}