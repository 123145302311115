@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';

.cart-popover {
    
    .hidden-collapse-buttons,
    .order-summary-hidden {
        display: none;
    }

    .sc-checkout-btn {
        margin-top: 0.5rem;
    }
}


.navbar .pos-patron-container {
    width: 50px;
    overflow: visible;
}

.pos-patron-container {

    .cart-checkout {

        .pc-preview-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .preview-container {
            margin-top: 0;
            padding: 0;
            max-height: inherit;

            .preview-items-grid-wrapper {
                padding: 0;
            }
        }

        .preview-columns {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-self: flex-start;
        }

        // .preview-columns > * {
        //     flex: 1 0 auto;
        // }

        // .preview-columns > *:first-of-type {
        //     flex: 1 1 auto;
        // }

        .order-summary-hidden {
            display: none;
            font-size: 1rem;
            font-weight: 500;
            margin-top: 1rem;
        }

        .collapse-arrow-button.btn {
            margin: 0 0.75rem 0 0;
            padding: 0 !important;
            width: 34px;
            height: 34px;
            border: 1px solid #c5c5c5;

            i {
                margin: 0 !important;
                font-size: 1.3rem;
                padding-top: 4px;
                padding-left: 2px;
                color: $link-active-color;
            }
        }

        .collapse-arrow-button.btn:hover {
            border: 1px solid $link-hover-color;
        }

        .collapse-arrow-button.btn:focus {
            box-shadow: none !important;
        }

        .btn.sc-checkout-btn {
            margin: 1rem 0 0 0;
        }

        .preview-items-grid {
            grid-template-columns: auto 80px 40px;
        }

        .footer-grid {
            display: grid;
            grid-template-columns: auto 100px 40px;
            padding-left: 0;
            padding-right: 0;

            .row-wrapper > *:nth-of-type(2) {
                text-align: right;
                width: 100%;
                padding-right: 10px;
            }
        }
    }


    // patron cart in nav bar
    
    .cart-num-items {
        position: absolute;
        transform: translate(15px, -9px);
        font-family: $badge-font-family;
        font-size: $badge-font-size;
        font-weight: $badge-font-weight;
        width: $badge-size;
        height: $badge-size;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $badge-border-radius;
        border: $badge-border;
        background-color: $badge-background-color;
        color: $badge-color;
    }

    .cart-num-items[animated="1"] {
        animation: pulse 2s 1 linear;
    }

    .cart-btn {
        cursor: pointer;
        display: flex;
    }

    .cart-btn>div {
        padding: 0;
    }

    i.cart {
        display: flex;
        height: 30px;
        width: 40px;
        justify-content: center;
        align-items: center;
        font-size: $shopping-cart-icon-size;
    }

    .sc-checkout-btn {
        color: white;
    }

    .sc-checkout-btn:active,
    .sc-checkout-btn:focus,
    .sc-checkout-btn:hover {
        text-decoration: none;
        color: $company-white;
    }

    @keyframes pulse {
        0% {
            transform: scale(1) translate(15px, -9px);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0), 0 0 0 0 rgba(255, 165, 47, 0);
            color: $badge-color;
        }

        5% {
            transform: scale(1.9) translate(13px, -5px);
            box-shadow: 0 0 1px 0px rgba(255, 255, 255, 1), 0 0 3px 3px $badge-color, 0 0 2px 2px $badge-border-flash-color;
            color: $badge-color;
        }

        10% {
            box-shadow: 0 0 5px 0px rgba(255, 255, 255, 0), 0 0 5px 0px rgba(255, 255, 155, 0), 0 0 3px 3px $badge-border-flash-color;
        }

        25% {
            box-shadow: 0 0 0px 0px rgba(255, 255, 255, 0), 0 0 0px 0px rgba(255, 255, 155, 0), 0 0 5px 5px $badge-border-flash-color;
        }

        75% {
            transform: scale(1.9) translate(13px, -5px);
        }

        100% {
            transform: scale(1) translate(15px, -9px);
            color: $badge-color;
        }
    }

}

@media screen and (min-width: 1121px) {
    .cart-checkout {

        .pc-preview-container.collapsable-sidebar.collapsed_full {
            flex: 0 1 30px;

            #patron-cart-collapsible-preview {
                display: none;
            }

            .order-summary-hidden {
                display:block;
            }
        }

        .collapse-arrow-button.small_size {
            display: none;
        }
    }
}

@media screen and (max-width: 1120px) {
    .cart-checkout {

        .pc-preview-container.collapsable-sidebar.collapsed_small {
            flex: 0 1 30px;

            #patron-cart-collapsible-preview {
                display: none;
            }

            .order-summary-hidden {
                display: flex;
                flex-direction: column;
            }
        }

        .collapse-arrow-button.full_size {
            display: none;
        }
    }
}

// below 640px display cart total and checkout button above the order items
@media screen and (max-width: 640px) {
    .pos-patron-container {
        h1 {
            font-size: 1.2rem !important;
        }

        .cart-checkout {
            flex-direction: column-reverse !important;
            align-items: flex-start;

            .pc-preview-container {
                width: 100%;
                flex-direction: row;
                align-items: center;
                margin-bottom: 0.75rem;

                .collapse-arrow-button.btn {
                    display: none;
                }

                .order-summary-hidden {
                    flex: 1 1 auto;
                    margin-top: 0;
                    flex-direction: row !important;
                    padding-right: 1rem;

                    & > * {
                        padding: 0 0.5rem;
                        white-space: nowrap;
                    }
                }
                
                .btn.sc-checkout-btn {
                    margin: 0;
                }
            }

            .item-container {
                width: 100%;

                & > * {
                    flex-wrap: wrap;
                }
            }
        }
    }
}

@media screen and (max-width: 340px) {
    .pos-patron-container {
        .cart-checkout {
            .pc-preview-container {
                flex-direction: column;
                align-items: center;
            }
        }
    }
}