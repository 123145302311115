@import '../../assets/css/scss/variables';
@import '../../assets/css/scss/themes';
@import '../../assets/css/scss/mixins';

$minWidthFormItems: 200px;

.user-create-edit-form {

    .form-control.is-invalid, .was-validated .form-control:invalid,
    .custom-select.is-invalid, .was-validated .custom-select:invalid {
        border-color: $error-color;
        background-image: none;
    }

    .was-validated .invalid-feedback,
    .is-invalid~.invalid-feedback {
        font-size: 0.9rem;
    }

    .form-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .form-group {
        padding: 0 5px;
    }

    .input-width-2 {
        flex: 2 2 50%;
        min-width: $minWidthFormItems;
    }

    .input-width-3a {
        flex: 3 3 35%;
        min-width: 150px;
    }

    .input-width-3b {
        flex: 1 1 30%;
        min-width: 110px;
    }

    label {
        @include basic-label;
        margin-bottom: 0.2rem;
    }
    select{
        @include basic-input-select;
    }
    .role-col{
        display: flex;
        flex-direction: column;
        max-width: 265px;
    }
}