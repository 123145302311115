@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';

.pos-container .item-container,
.pos-modal .item-container {
    padding: 0.75rem 1rem;
}

.pos-patron-container .btn.btn-secondary {
    background-color: $pos-secondary-color;

    &.focus, &:focus, &:hover {
        color: $primary-inverse-color;
        background-color: $primary-hover-color;
        border-color: $primary-hover-color;
    }
}

.pos-products {
    padding-top: 0.4rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.pos-container .variant_container,
.pos-modal .variant_container {
    position:relative;
    padding:1rem;
    //padding-right:3.5rem;
    background:$background-color;
    min-height: 400px;
    //margin-bottom:1rem;
    box-shadow: 0 1px 3px rgba(189,189,189,0.12), 0 1px 2px rgba(189,189,189,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.pos-modal .variant_container .row{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
}

.pos-container .variant_container div.ali-img-cen,
.pos-modal .variant_container div.ali-img-cen {
    display: none;
}
.pos-container .or-he,
.pos-modal .or-he {
    color: $secondary-color !important;
    font-weight: 500;
    text-align: center;
    padding: 5px !important;
}
.pos-container .m-b-5,
.pos-modal .m-b-5 {
    margin-bottom: 5px;
}

.pos-container .variant_container h1,
.pos-modal .variant_container h1{
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #c0c0c0;
    margin: -11px 0 0 0;
    padding: 0 0 10px 0;
}

.pos-container .variant_container:nth-of-type(odd) h1,
.pos-modal .variant_container:nth-of-type(odd) h1{
    right:-.65rem;
}



// .pos-modal .modal-title{
//     font-size:1rem;
// }

// .pos-modal .modal-body{
//     background:$modal-body-background-color;
// }

/*
.pos-container .product_item,
.pos-modal .product_item {
    margin: 0.25rem!important;
    max-width: 215px;
    width: calc(25% - 0.75rem);
    min-width: 150px;
    padding: 0 !important;
    text-decoration: none;
}

.pos-container .product_item .product-card{
    margin: 0 !important;
}
*/

.pos-container .item-container.row,
.pos-modal .item-container.row {
    margin-left: 30px !important;
    margin-right: 0 !important;
    padding: 15px;
    flex-grow: 1;
}

@media screen and (max-width: 991px) {
    .pos-patron-container .category-buttons,
    .pos-modal .category-buttons {
        grid-template-columns: repeat(2, 1fr) !important;
        gap: 0.25rem !important;

        a{
            width:100% !important;
            margin:0 !important;
        }
    }
}