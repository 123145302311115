@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';

.coupon-modal .row {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.coupon-row {
    margin: 0.5rem 0 !important;
    padding: 0 !important;

    &:last-child{
        margin-bottom: $main-padding !important;
    }
}
.coupon-row:not(.disabled):not(.saved-total):not(.coupon-input) {
    cursor: pointer;
    background: $card-background-color;
}
.coupon-row:not(.disabled):not(.saved-total):not(.coupon-input):hover {
    background: $primary-light-color;
}
.coupon-item {
    border: $divider-border;
    border-left: 8px solid $disabled-color;
    padding: 2rem;
}
.selected .coupon-item {
    border-left: 8px solid $primary-color;
}
.coupon-item .name {
    font-weight: $bold-font-weight;
}
.coupon-item .no-combine {
    font-style: italic;
}

.disabled.coupon-item {
    border-left: 8px solid $disabled-color;
}
.disabled.coupon-row {
    background-color: $disabled-color;
}

.saved-total {
    justify-content: center;
    padding: 0 !important;
    font-family: $secondary-font-family;
    font-size: 1rem;
    font-weight: $bold-font-weight;
    color: $neutral-color;
}
.saved-total.highlight-price {
    color: $primary-color;
}

.coupon-row.coupon-input {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.coupon-row.coupon-input input {
    max-width: 50%;
}
.coupon-row.hidden {
    display: none;
}