@import '../../../assets/css/scss/variables.scss';
@import '../../../assets/css/scss/themes.scss';

tr.clickable:hover {
    background-color: $primary-hover-color;
    color: #fff;
    cursor: pointer;
}


.unpd-e-tex {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.eve-he {
    font-weight: normal;
    font-size: .85rem;
    font-weight: 500;
    line-height: 1rem;
    text-transform: uppercase;
}

.tr-p-10 {
    padding: 10px;
}

.tr-light-bg {
    background: transparent;
    border-bottom: 1px solid #eee;
}

.bd-bt {
    border-bottom: 2px solid #F0F0F0;
}

.background-logo-blue {
    background-color: $company-tertiary;
}

.background-logo-blue-50 {
    background-color: $company-tertiary;
}