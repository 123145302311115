@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';


.sidebar-min-width{
    min-width: 250px;
    width: 250px;
}
.collapsed-min-width{
    min-width: 80px;
    width: 80px;
}
.sidebar-wrapper-thing {

    // positioning of the menu on the page, so it is sticky and can scroll within itself
        position: sticky;
        left: 0;
        z-index: 1006;
        top: $header-height;
        background-color: $menu-background-color;
        max-height: calc(100vh - $header-height);
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 40px;
        scroll-behavior: smooth;
        flex: 0 0 auto;
        transition: all 250ms ease-in-out;

    ul, li{
        font-family: $menu-font-family;
    }

    //regular first level navigations
    .css-16jkw2k >.ps-menu-button:hover{
        background-color: $menu-active-hover-background-color;
        color: $menu-hover-color;
    }
    .active{
        background-color: $menu-active-background-color;
        color: $menu-active-color;
    }
    //background color the whole menu, half opacity by default
    .css-dip3t8{
        background-color: $menu-background-color !important;
    }
    //subnavigation headers
    .css-16jesut >.ps-menu-button:hover{
        background-color: $menu-active-hover-background-color;
        color: $menu-hover-color;
    }
    //subnavigation content
    .ps-submenu-content{ 
        background-color: $menu-item-background-color;
        color: $menu-item-color;
    }
    //when the menu is collapsed and subnavigation pops out (also still maintains the ps-submenu-content)
    .css-1l5fxv3{
        border-radius: $menu-item-border-radius;
        box-shadow: $menu-box-shadow;
    }
    //the little dot that indicates there's a submenu when it's collapsed
    .css-1097eso{
        padding-left: 3px;
    }

    //the grey backdrop thing when collapsed
    .css-8i0j0q{
        background-color: transparent;
    }
}
//by default, there was a border on the right side of the menu, expanded menu
.css-1wvake5{
    border: none !important;
    transition: all 250ms ease-in-out;
}
//when the menu is "broken" (mobile sized), it snaps to the very top, had to offset it so that it isn't covered by the top menu bar
.css-1wvake5.ps-broken{
    left: -300px;
    z-index: 1000 !important; //have to have because the pages like event-register and /shop have z-index of 999
    &.ps-toggled{
        top: $header-height !important;
    }
}
.ps-sidebar-container{
    &::-webkit-scrollbar-track{
        background: $menu-scrollbar-background-color;
    }
    &::-webkit-scrollbar-thumb{
        background: $menu-scrollbar-color;
        border-radius: $menu-scrollbar-border-radius;
    }
}