@import '../../../../assets/css/scss/variables';
@import '../../../../assets/css/scss/themes';

.buttons{
    display: flex;
    flex-direction: row;
    align-items: center;

    :global(.btn){
        flex-grow: 0 !important;
        flex-shrink: 0 !important;
        flex-basis: auto !important;
    }

    :global(.input-group){
        max-width: 200px;

        i{
            align-self: center;
            margin: 0 1rem;
        }
    }
}