@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';

.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 1040;
    margin:0;
    padding:0;
    background: #fff;
    box-sizing: border-box;
    overflow: hidden;
    background: $background-color;
}