@import '../../../assets/css/scss/variables.scss';
@import '../../../assets/css/scss/themes.scss';

.family-home-skele-wrapper {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;

    div {
        margin: .5rem;
        display: flex;
        flex-direction: column;
    }
}