.event-card{
    margin-bottom:2rem;
}

.event-card .card-body{
    padding-left:1.5rem;
}

.event-card .card-header{
    background:transparent;
    padding:2rem 1rem 0 1.5rem;
    border-bottom:0;
}

.event-card .card-footer{
    background:transparent;
    border-top:0;
    padding:1rem 1rem 2rem 1.5rem;
}

.event-card .card-footer a{
    font-weight:400;
    letter-spacing: .1rem;
    color: #F67730;
}

.event-card .card-footer a:hover{
    color: #062142;
}

.event-card ul{
    list-style-type: none;
    padding-left:0;
}
.event-card ul li{
    line-height:1.7rem;
}

.event-card ul li i{
    margin-right:.5rem;
}