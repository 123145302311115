@import '../../../assets/css/scss/mixins';
@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes';

.bottombanner-wrapper{
    position: fixed;
    bottom: 0;
    background: $secondary-color;
    height: 40px;
    width: 100%;
    padding-left: 15px;
    padding-top: 10px;
    color: $secondary-inverse-color;
    .horizontal-list{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    i{
        padding-right: 15px;
    }
    .each-item{
        padding-right: 25px;
    }
}
