@import '../../../../assets/css/scss/variables.scss';
@import '../../../../assets/css/scss/themes.scss';

$font-size: 8pt;
$big-font-size: 1.25rem;
$kitchen-font-size: 1.1rem;
$kitchen-line-height: 1.2rem;
$kitchen-big-font-size: 1.6rem;

.fp-receipt-wrapper,
.fp-kitchen-wrapper {
    padding: 2rem;
    margin: 0;
    background-color: #fff;
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
    font-size: $font-size;


    > * {
        background-color: #fff;
        font-family: Arial, Helvetica, sans-serif;
        font-size: $font-size;    
    }

    .divider {
        width: 100%;
        border:0;
        border-bottom: 1px solid #f5f5f5;
        margin: 4px 0 0.5rem 0;
    }

    .fp-larger {
        font-size: $big-font-size;
    }

    .company-logo{
        max-width: 100px;
    }

    .bold{
        font-weight: 600 !important;
    }

    .strike{
        text-decoration: line-through;
    }

    .amount{
        text-align: end;
        width: 100px;
    }

    .normalize{
        text-transform: capitalize;
    }

    .highlight{
        background-color: #000;
        color: #fff;
        padding: 0.5rem;
        font-size: inherit;
        text-transform: uppercase;
    }

    .flex-wrapper-company {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .right-address {
            text-align: center;

            p {
                margin-top: none;
                padding-top: none;
            }
        }
    }

    .flex-wrapper-customer-order {
        display: flex;
        justify-content: space-between;

        .fp-customer {
            display: flex;
            align-items: baseline;
            margin-top: 1rem;
        }

        .fp-order-details {
            display: flex;
            flex-direction: column;
            margin-top: 1rem;

            td{
                vertical-align: top;
            }
        }

        .fp-order-last {
            font-size: $font-size;
            font-weight: 600;
            td {
                padding: 0.25rem 0.5rem !important;
                &:nth-of-type(2) {
                    padding-left: 11px !important;
                }
            }

        }
    }

    .flex-wrapper-items {
        margin: 0;
        padding: 0;
        
        .main-item {
            width: 75%;
        }        

        .fp-item-headers {
            display: flex;
            justify-content: space-between;
            background-color: #000;
            color: #fff;
            width: 100%;
            font-size: $font-size;
            font-weight: 600;
            padding: 0.5rem;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
            text-transform: uppercase;

            &.ticket{
                background-color: transparent;
                color: #000;
                border-bottom: 1px solid #000;
            }
        }

        .fp-items {
            margin: 0;
            display: flex;
            flex-direction: column;

            .item-wrapper{
                padding: 0 0.5rem;

                .item-name{
                    font-weight: 600;
                    width: 75%;
                    text-align: left;
                }

                .fp-desc {
                    width: 75%;
                    text-align: justify;
                    margin-left: 0.5rem;
                }

                .item-map,
                .item-map-ticket {
                    display: flex;
                    justify-content: space-between;
                    margin: 0;

                    .item-name{
                        font-weight: 400;
                    }
                }

                .item-map-ticket{
                    //flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start 
                }
            }

            .divider:last-of-type {
                border:0;
            }
        }
    }

    .totals{
        .span-left {
            text-align: end;
            padding-right: 10px;
            font-size: $font-size;
            font-weight: 600;
            border:0;
        }

        .span-right {
            padding-left: 10px;
            text-align: flex-start;
            font-size: $font-size;
            border-left: 1px solid #000;
        }

        table{
            border: 0;
            background-color: #fff;
            margin: 0;

            td,
            tr {
                border: 0;
                padding: 0;
            }

            td{
                &:last-of-type{
                    min-width: 70px;
                }
            }
        }

        .split-payment-icon{
            position: relative;
            display: flex;
            align-items: center;
        
            &::before {
                content: "\f148";
                display:inline-block;
                margin: 0 3px 3px 8px;
                font-family: 'Font Awesome 5 Pro';
                font-style: normal;
                font-weight: normal;
                text-decoration: inherit;
                color: #000;
                font-size: inherit;
                transform: scaleX(-1);
            }
        }
    }

    .flex-wrapper-totals {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        font-size: $font-size;

        .memo{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            text-align: left;
            width: 100%;
        }

        > div {
            display: flex;
            flex-direction: column;
            border-top: 1px solid #000;
            padding-top: 10px;
            margin-right: 2rem;
            text-align: left;
        }

        ul{
            list-style: inside;
            list-style-type: circle;
            padding: 0;
            margin: 0;
            font-size: $font-size;
            text-align: left;

            li{
                margin: 0;
                padding: 0;
                font-size: $font-size;
                text-align: left;
            }
        }


        table {
            font-size: $font-size;
            margin: 0;
            padding: 0;
            margin-left: auto;

            td,
            tr {
                border: 0;
                padding: 0;
                text-align: left;
                font-size: $font-size;
                font-weight: 600;
            }

            tr{
                &:first-of-type{
                    td{
                        border-top: 1px solid #000;
                        padding-top: 10px;
                    }
                }

                &:last-of-type{
                    td{
                        padding: 0.25rem 0.5rem;
                        padding-bottom: 10px;
                        border-bottom: 1px solid #000 !important;
                    }
                }

                td{
                    &:last-of-type{
                        text-align: right;
                        border-left:0;
                        padding-right: 0.5rem;
                    }
                }
            }
        }
    }
    .indent{
        margin-left: 10px;
    }
}

.a4{
    min-width: 794px; // a4 paper at 72dpi

    .flex-wrapper-company{
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        .right-address {
            text-align: left;
        }

    }
    
    .flex-wrapper-totals {
        flex-direction: row;

        .memo{
            width: 40%;
        }
    }
}

.fp-kitchen-wrapper{
    font-size: $kitchen-font-size;
    width: 320px;
    max-width: 320px;

    > * {
        font-size: $kitchen-font-size;
        line-height: $kitchen-line-height;
    }

    h1{
        font-size: $kitchen-big-font-size;
    }
    h2{
        font-size: $big-font-size;
    }
    .flex-wrapper-customer-order {
        .fp-customer {
            margin: 0;
        }
        .fp-order-details {
            margin: 0;
        }
    }
    .flex-wrapper-items {
        font-weight: 600;
        .fp-item-headers {
            font-size: $kitchen-font-size;
            line-height: $kitchen-line-height;
            margin: 0;
            &.ticket{
                border: 0;
            }
        }
        .fp-items {
            .item-wrapper{
                font-weight: 600;
                .item-name{
                    font-weight: 600;
                    width: 100%;
                }
                .fp-desc {
                    width: 100%;
                }
                .item-map,
                .item-map-ticket {
                    .item-name{
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .divider {
        border: 0;
        margin: 2px 0;
    }
}
