@import '../../../../assets/css/scss/variables';


.location-printer-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1040;
    background: #E5E5E5;
    overflow: hidden;
    padding: 20px;

    .select-printer-location {
        display: flex;
        flex-direction: row;
        align-items: center;

        label.form-label {
            margin-bottom: 0;
            margin-right: 1rem;
            font-weight: 500;
            font-size: 1rem;
        }
    }
}

