@import '../../../../../../assets/css/scss/variables';
@import '../../../../../../assets/css/scss/themes';

.variant{
    border: $divider-border;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background-color: $neutral-background-color !important;
    color: $primary-font-color;
    box-shadow: $button-shadow !important;
    margin: $button-margin;
    padding: $button-padding !important;
    transition: all 0.2s ease-in-out;
    font-family: $button-font-family;
    font-size: $button-font-size;

    &:hover{
        background-color: $button-hover-background-color !important;
        color: $button-hover-color;
    }

    &.selected{
        background-color: $primary-color !important;
        color: $primary-inverse-color;
    }
}
