@import "../../assets/css/scss/variables";
@import "../../assets/css/scss/themes";

.general-typeahead {

    // Align the X close all button to the center of the input at the top
    .rbt-aux {
        align-items: flex-start;

        .rbt-close {
            margin-top: 9px;
        }
    }

    .tokens-list {
        display: flex;
        flex-direction: column;
        margin-top: 3px;
        // padding-left: 12px;
        // padding-right: 12px;

        .rbt-token {

            background-color: $chip-background-color;
            padding: $chip-padding;
            margin: $chip-margin;
            font-size: $chip-font-size;
            font-weight: $chip-font-weight;
            line-height: $chip-line-height;
            border-radius: $chip-border-radius;
            border: $chip-border;
            color: $chip-color;
            
            &:hover {
                background-color: $chip-hover-background-color;
                color: $chip-hover-color;
            }

            &.rbt-token-active {
                background: $grey-3;
            }
        }

        .rbt-token-remove-button {
            font-size: 1.5rem;
            color: $grey-9;
        }
    }

}