@import '../../../../../assets/css/scss/themes.scss';

button.button{
        display:inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        font-family: $button-font-family;
        font-size: $button-large-font-size !important;
        font-weight: $button-font-weight !important;
        line-height: $button-line-height !important;
        border-radius: $button-border-radius;
        padding: $button-large-padding !important;
        margin: $button-margin;
        border: $neutral-border;
        color: $neutral-color;
        background-color: $neutral-background-color;
        text-transform: $button-text-transform;
        text-shadow: $button-text-shadow;
        box-shadow: $button-shadow;
        text-decoration: none;
        align-items: center;
        width: fit-content;
        max-width: 30%;


        &:disabled,
        .disabled,
        &:disabled:hover {
            color: $button-disabled-color;
            background-color: $button-disabled-background-color;
        }

        .small{
            font-family: $primary-font-family;
            font-size: $small-font-size;
            line-height: $small-font-line-height;
            font-weight: 400 !important;
            margin-top: 0.5rem;
            margin-bottom:0;
            text-transform: none;
        }
    
}