.react-transform-wrapper {
    border: 1px rgb(138, 138, 138) solid;
    border-radius: 3px;
    background-color: #fff;
    overflow: hidden;
    width: calc(100vw - 380px) !important;
    height: 450px;
}

.tools {
    position: absolute;
    display: flex;
    flex-direction: row;
    padding: 4px;
    z-index: 999;
}

.tools .btn {
    padding: 4px;
    min-width: 30px;
    margin-right: 4px;
}

.tools .btn i {
    margin: 0;
}

.tools .btn:active, .tools .btn:focus {
    box-shadow: none;
}

@media (max-width: 991px) {
    .react-transform-wrapper {
        width: calc(100vw - 100px) !important;
    }
}