@import '../../../assets/css/scss/mixins';
@import '../../../assets/css/scss/variables';
@import '../../../assets/css/scss/themes.scss';

.table-wrapper{
    border-collapse: collapse;
    table-layout: auto;
    max-width: 100%;
    width:100%;
    text-align: center;
    overflow: auto;
    table{
        display: table;
    }
    &.ellipsis{
        td, th{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .top-heading{
        td{
            padding-left: 15px;
            background-color: $table-header-background-color;
            color: $table-header-color;
            &:first-child{
                border-radius: $table-header-border-radius 0 0 0;
            }
            &.last-child{
                border-radius: 0 $table-header-border-radius 0 0;
            }
        }
    }
    td{
        padding: $table-row-padding;
        padding-left: 15px;
        overflow: hidden;
        color: $table-row-color;
        background-color: $table-row-background-color;
    }
    .headings{
        padding: 3px;
        font-weight: $header-font-weight;
        @include basic-label;
    }
    tr{
        &.hidden{
            display: none;
        }
        .cp{
            cursor: pointer;
        }
        &:nth-child(odd){
            background-color: $table-row-background-color-odd;
        }
    }
    @media screen and (max-width: "700px"){
        display: block;
    }
}